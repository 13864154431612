import React from "react";
import styled from "styled-components/macro";
import { Typography, Semantic } from "components/SemanticComponents";

const Content = styled.div`
  a {
    color: ${(props) => props.theme.colors.heroBlue};
    text-decoration: underline;
    font-style: italic;
    text-decoration-color: ${(props) => props.theme.colors.heroBlue};
  }
`;

export default function CEOMessageComponent({ ceo_message }) {
  return (
    <Semantic.Article width="100%">
      <Typography.Title level="h2">
        A message from your leadership
      </Typography.Title>
      <Content dangerouslySetInnerHTML={{ __html: ceo_message }} />
    </Semantic.Article>
  );
}

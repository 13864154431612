import PropTypes from "prop-types";
import styled from "styled-components/macro";
import { Link } from "react-router-dom";

const StyledAttribute = styled(Link)`
  color: ${(props) => props.theme.colors.heroBlue};
  text-align: right;
  margin: 0;
  text-decoration: ${(props) => (props.underlined ? "underlined" : "none")};
`;

export default function EmphasisLink({ children, ...props }) {
  return (
    <StyledAttribute
      {...props}
      to={props.to}
      underlined={
        `${props.underlined}` !== `true` ? undefined : `${props.underlined}`
      }
      component={props.component}
    >
      {children}
    </StyledAttribute>
  );
}

EmphasisLink.propTypes = {
  children: PropTypes.any.isRequired,
  underlined: PropTypes.bool,
  to: PropTypes.string,
};

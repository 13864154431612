import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";

const Structure = styled.div`
  display: flex;
  flex-direction: column;
  width: 250px;
  height: 300px;
  margin-bottom: 1em;
  text-align: center;
  border: ${(props) =>
    props.selectedCard
      ? `1px solid ${props.theme.colors.heroBlue}`
      : `1px solid hsla(38, 100%, 48%, 0)`};
  box-shadow: 0 1px 6px rgba(61, 65, 84, 0.15);
  border-radius: 12px;
  overflow: hidden;
  transition: all 0.2s ease-in-out;
  &:hover {
    box-shadow: 0 10px 40px rgba(61, 65, 84, 0.15);
    top: -2px;
  }
`;

const ArticleWrapper = styled(Structure)`
  &::before {
    content: "";
    display: block;
    height: 5px;
    width: 0;
    top: 0;
    left: 0;
    background-color: ${(props) => props.theme.colors.heroBlue};
    transition: all 0.2s ease-in-out;
  }

  &:hover {
    &::before {
      width: 100%;
    }
  }
`;

const Tick = styled.div`
  position: absolute;
  display: ${(props) => {
    if (props.selectedCard) {
      return "inline-block";
    } else {
      return "none";
    }
  }};

  &::before {
    content: "✓";
    width: 30px;
    height: 30px;
    padding-top: 5px;
    display: inline-block;
    position: relative;
    top: -8px;
    right: -230px;
    background: ${(props) => props.theme.colors.heroBlue};
    color: white;
    border: 1px solid ${(props) => props.theme.colors.heroBlue};
    border-radius: 50%;
    z-index: 10;
    transition: all 0.5s ease-in-out;
  }
`;

const Image = styled.div`
  margin: 0 auto;
  height: 200px;
  width: 200px;
  background-image: url(${(props) => props.image});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% 50%;
`;

const Title = styled.h1`
  font-size: 1em;
`;

export default function OnboardingPersonaCard({ children, onClick, ...props }) {
  return (
    <React.Fragment>
      <ArticleWrapper {...props} onClick={onClick}>
        <Tick {...props} tabIndex="-1" />

        <Image {...props} tabIndex="-1" />

        <Title>{children}</Title>
      </ArticleWrapper>
    </React.Fragment>
  );
}

OnboardingPersonaCard.propTypes = {
  children: PropTypes.string.isRequired,
  onSelect: PropTypes.func,
  image: PropTypes.string,
};

import styled from "styled-components/macro";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

const StyledNavLink = styled(NavLink)`
  &:hover,
  &:focus-within {
    outline: none; //=> we can do this because we are setting the colours below
    background: ${(props) =>
      props.branding ? "" : props.theme.colors.altBlue};
    color: ${(props) => (props.branding ? "" : props.theme.colors.heroBlue)};
  }
  /* background: ${(props) => props.theme.colors.altBlue};
    color: ${(props) => props.theme.colors.heroBlue}; */
  &.${(props) => props.activeClassName} {
    background: ${(props) =>
      props.branding ? "" : props.theme.colors.heroBlue};
    color: ${(props) =>
      props.branding ? "" : props.theme.colors.primaryBackground};
  }
`;

export default function NavigationLink({ branding, children, ...props }) {
  return (
    <StyledNavLink
      {...props}
      aria-haspopup="true"
      activeClassName="active"
      to={props.to}
      branding={branding}
      exact
    >
      {children}
    </StyledNavLink>
  );
}

NavigationLink.propTypes = {
  children: PropTypes.any.isRequired,
  to: PropTypes.string.isRequired,
  branding: PropTypes.string,
};

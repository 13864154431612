import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";

const StyledOL = styled.ol`
  line-height: ${(props) => props.theme.lineHeight.xxl};
  li {
    font-size: ${(props) => props.theme.fontSize.paragraph};
  }
`;

const StyledUL = styled.ul`
  line-height: ${(props) => props.theme.lineHeight.md};
  li {
    font-size: ${(props) => props.theme.fontSize.paragraph};
  }

  & .task-list-item {
    list-style-type: disc;
    margin-bottom: ${(props) => props.theme.spacing.md};
    font-size: ${(props) => props.theme.fontSize.paragraph};
    input {
      margin-right: ${(props) => props.theme.spacing.md};
    }
  }
`;

const OL = ({ children, ...props }) => {
  return <StyledOL {...props}>{children}</StyledOL>;
};

const UL = ({ children, ...props }) => {
  return <StyledUL {...props}>{children}</StyledUL>;
};

const List = { UL, OL };

List.propTypes = {
  children: PropTypes.string.isRequired,
};

export default List;

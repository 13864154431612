import React from "react";
import PropTypes from "prop-types";
import { theme } from "theme";
import { Semantic } from "./SemanticComponents";

export default function EmpowermentTip({ children }) {
  return (
    <Semantic.Div
      borderLeft={`2px solid ${theme.colors.heroBlue}`}
      background={`${theme.colors.background}`}
      padding={`${theme.spacing.sm}`}
      margin={`0 ${theme.spacing.xl} 0 ${theme.spacing.xl}`}
    >
      <Semantic.Div margin={`0 auto ${theme.spacing.sm} 0`}>
        <Semantic.Span fontWeight="bold">Empowerment tip!</Semantic.Span>
      </Semantic.Div>
      <Semantic.Span>{children}</Semantic.Span>
    </Semantic.Div>
  );
}

EmpowermentTip.propTypes = {
  children: PropTypes.any,
};

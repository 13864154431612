import React from "react";
import { AllyCard, ResourceCard, PaginationButtons } from "components";

export default function Pagination({
  data,
  dataLimit,
  select,
  type,
  goToPreviousPage,
  goToNextPage,
  currentPage,
  pages,
  pageLimit,
  toggle,
  filteredPosts,
}) {
  const getPaginatedData = () => {
    const startIndex = currentPage * dataLimit - dataLimit;
    const endIndex = startIndex + dataLimit;
    return data?.slice(startIndex, endIndex);
  };
  return (
    <React.Fragment>
      <ResourceCard>
        {type === "secondary"
          ? getPaginatedData().map((data, index) => (
              <AllyCard
                key={index}
                data={data}
                select={select}
                link={`/blogpost/${data.slug}`}
              />
            ))
          : getPaginatedData().map((data, index) => (
              <AllyCard
                key={index}
                data={data}
                select={select}
                link={`/lesson/${data.first_step_slug}`}
              />
            ))}
      </ResourceCard>

      {toggle ? (
        <PaginationButtons
          filteredPosts={filteredPosts}
          pageLimit={pageLimit}
          dataLimit={dataLimit}
          select={select}
          goToPreviousPage={goToPreviousPage}
          goToNextPage={goToNextPage}
          currentPage={currentPage}
          pages={pages}
        />
      ) : null}
    </React.Fragment>
  );
}

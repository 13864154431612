import { useAPI } from "hooks/api";
import styled from "styled-components/macro";

const ImageAlignment = styled.div`
  display: flex;
  justify-content: center;
`;

const CustomImage = styled.img`
  object-fit: cover;
  border-radius: 50%;
  border: ${(props) => `0.5px solid ${props.theme.colors.primaryBackground}`};
  height: 50px;
  width: 50px;
  margin-left: -15px;
`;

export default function CoachImageIcons() {
  const { statefulGet } = useAPI();
  const { data: coaches, status: coachStatus } =
    statefulGet("/coaching/coaches");

  if (coachStatus !== "fetched") {
    return null;
  }

  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  return (
    <ImageAlignment>
      {shuffleArray(coaches)
        .slice(0, 5)
        .map((coach, index) => {
          return (
            <CustomImage
              key={index}
              src={coach.image}
              alt={`Avatar for coach ${coach.first_name}`}
            />
          );
        })}
    </ImageAlignment>
  );
}

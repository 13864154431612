import React from "react";
import styled from "styled-components/macro";
import PropTypes from "prop-types";
import { Semantic } from "components/SemanticComponents";

const StyledInput = styled.input`
  background: ${(props) =>
    props.disabled === true
      ? props.theme.colors.formDisabled
      : props.theme.colors.transparent};
  border-radius: ${(props) => props.theme.borderRadius.sm};
  border: ${(props) => props.theme.border.formBorder};
  display: inline-block;
  height: 40px;
  margin-top: ${(props) => props.theme.spacing.md};
  padding: 1rem;
  position: relative;
  width: 100%;
  z-index: 10;

  &:invalid {
    border: ${(props) => (props.pattern ? "solid red 3px" : null)};
  }
`;

const StyledLabel = styled.label`
  color: ${(props) =>
    props.value !== ""
      ? props.theme.colors.heroBlue
      : props.theme.colors.inactive};
  display: inline-block;
  font-size: ${(props) => (props.value !== "" ? "0.8rem" : "1rem")};
  padding-left: ${(props) => (props.value !== "" ? 0 : "1rem")};
  position: relative;
  top: ${(props) => (props.value !== "" ? "-84px" : "-54px")};
  transition: all 0.5s ease-in-out;
  width: 100%;
  z-index: 1;

  ${StyledInput}:focus ~& {
    color: ${(props) => props.theme.colors.heroBlue};
    font-size: 0.8rem;
    padding-left: 0;
    top: -84px;
  }
`;

const ValidationLabel = styled.div`
  color: ${(props) => props.theme.colors.heading};
  border-bottom-left-radius: ${(props) => props.theme.border.formInputRadius};
  border-bottom-right-radius: ${(props) => props.theme.border.formInputRadius};
  padding-left: 5px;
  background: ${(props) =>
    props.validate
      ? `${props.theme.colors.heroGreen}`
      : `${props.theme.colors.textWarningRed}`};
`;

export default function TextInputValidation({
  type,
  name,
  label,
  onChange,
  onBlur,
  value,
  disabled,
  tabIndex,
  pattern,
  required,
  autocomplete,
  validate,
  beforeText,
  afterText,
}) {
  return (
    <Semantic.Div flexGrow="1">
      <StyledInput
        id={name}
        name={name}
        type={type}
        label={label}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        disabled={disabled}
        tabIndex={tabIndex}
        pattern={pattern}
        required={required}
        autocomplete={autocomplete}
        validate={validate}
        beforeText={beforeText}
        afterText={afterText}
      />
      <ValidationLabel validate={validate}>
        {validate ? afterText : beforeText}
      </ValidationLabel>
      <StyledLabel validate={validate} value={value} htmlFor={name}>
        {label}
      </StyledLabel>
    </Semantic.Div>
  );
}

TextInputValidation.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  tabIndex: PropTypes.string,
  pattern: PropTypes.string,
  inputValidation: PropTypes.bool,
};

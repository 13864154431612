const theme = {
  colors: {
    heroPink: "hsl(331.06, 90.87%, 42.94%)",
    heroYellow: "hsl(39.8, 99.1%, 58.2%)",
    heroBlue: "hsl(216, 99%, 47.81%)",
    heroGreen: "hsl(140, 100%, 26.47%)",
    heroOrange: "hsl(23, 98.1%, 58.8%)",
    heroPurple: "hsl(272.6, 32.3%, 47.38%)",
    altPink: "hsl(332, 100%, 97.06%)",
    altYellow: "hsl(40, 100%, 85.9%)",
    altBlue: "hsl(215, 85.71%, 97.25%)",
    altGreen: "hsl(120, 100%, 98.04%)",
    altOrange: "hsl(15.6, 93.1%, 94.3%)",
    altPurple: "hsl(296, 30.6%, 90.4%)",
    inactive: "hsl(0, 0%, 62%)", // Medium-dark Grey
    errorRed: "hsl(0, 100%, 38%)",
    primaryBackground: "hsl(0, 0%, 100%)", // White
    heading: "hsl(0, 0%, 24%)", // Dark Grey
    body: "hsl(0, 0%, 38%)", // Medium Grey
    lightGrey: "hsl(0, 0%, 86%)",
    background: "hsl(0, 0%, 98%)", // Off-white
    altBackground: "hsl(216, 17.24%, 94.31%)", // slightly darker than 'background'
    formDisabled: "hsl(0, 0%, 90%)", // light-grey
    gradientMedGrey: "hsl(0, 1%, 80%)",
    transparent: "transparent",
    mask: "hsla(0, 100%, 100%, 0.8)", // 80% opaque white, used to cover the backing of the modal
    likertRed: "hsl(352.9,100%,48.2%)",
    likertOrange: "hsl(27,98%,55%)",
    likertYellow: "hsl(46,98%,55%)",
    likertLGreen: "hsl(112,100%,41%)",
    likertDGreen: "hsl(112,83%,32%)",
    messageBackground: "hsl(17.4, 100%, 92.5%)", //=> very light orange colour
    toastErrorBackground: "hsl(5, 85%, 95%)",
    toastErrorFontColour: "hsl(4, 61%, 21%)",
    toastWarningBackground: "hsl(34, 100%, 94%)",
    toastWarningFontColour: "hsl(33, 100%, 18%)",
    toastInfoBackground: "hsl(206, 56%, 92%)",
    toastInfoFontColour: "hsl(208, 63%, 22%)",
    toastSuccessBackground: "hsl(120, 40%, 94%)",
    toastSuccessFontColour: "hsl(122, 27%, 17%)",
    textWarningRed: "hsl(0deg 87% 76%)",
    glassGrey: "hsla(45, 16%, 96%, 0.4)",
    glassBlue: "rgba(114, 142, 228, 0.2)",
    formGroupingBackground: "hsl(216, 100%, 98%)",
    colesHeroRed: "#E01A22",
  },
  border: {
    formBorder: "1px solid hsl(0, 0%, 38%)", //=> uses the hsl value from body
    formInputRadius: "8px",
    inputValidateBorderThin: "1px",
    inputValidateBorderThick: "3px",
  },
  borderRadius: {
    sm: "3px",
    md: "8px",
    lg: "12px",
    xl: "17px",
  },
  boxShadow: {
    md: "0px 0px 17px 0px #00000020",
    quote: "0px 0px 20px 0px #00000047",
    cardHover: "0 0 0.5em rgba(0, 0, 0, 0.25)",
    toast: "0 0 18px rgb(6 6 6 / 60%)",
    accountCard: "4px 4px 15px 0 #00000010",
  },
  breakPoint: {
    large: "820px",
    tablet: "768px",
    mobile: "500px",
  },
  fontSize: {
    // font sizes use Fluid Typography, they grown and shrink within a set size based on the width of the viewport
    // -- the standard calculation --
    // font-size: calc([minimum size] + ([maximum size] - [minimum size]) * ((100vw - [minimum viewport width]) / ([maximum viewport width] - [minimum viewport width])));
    // https://css-tricks.com/snippets/css/fluid-typography/
    // the first number is the smaller font size, and after the plus is what it increases by up to the larger screen size.
    // The second calculations balance the font growth off the width of the viewport, so 100vw - 20rem (320px) means that at the font size will be the smaller (first number), and the 60 width (960px) the font will increase by the second number (+ 0.125) which is an increase of 2px per font.
    xxs: "calc(0.625rem + 0.125 * ((100vw - 20rem) / 60))",
    xs: "calc(0.75rem + 0.125 * ((100vw - 20rem) / 60))",
    sm: "calc(0.875rem + 0.125 * ((100vw - 20rem) / 60))",
    md: "calc(1rem + 0.125 * ((100vw - 20rem) / 60))",
    lg: "calc(1.125rem + 0.125 * ((100vw - 20rem) / 60))",
    xl: "calc(1.25rem + 0.125 * ((100vw - 20rem) / 60))",
    xxl: "calc(1.5rem + 0.125 * ((100vw - 20rem) / 60))",
    xxxl: "calc(2.1875rem + 0.125 * ((100vw - 20rem) / 60))", //35px
    paragraph: "calc(1rem + 0.125 * ((100vw - 20rem) / 60))",
    button: "0.875rem",
  },
  lineHeight: {
    xxs: "calc(0.625rem + 0.125 * ((100vw - 20rem) / 60))",
    xs: "calc(0.75rem + 0.125 * ((100vw - 20rem) / 60))",
    sm: "calc(0.875rem + 0.125 * ((100vw - 20rem) / 60))",
    md: "calc(1rem + 0.125 * ((100vw - 20rem) / 60))",
    lg: "calc(1.125rem + 0.125 * ((100vw - 20rem) / 60))",
    xl: "calc(1.25rem + 0.125 * ((100vw - 20rem) / 60))",
    xxl: "calc(1.5rem + 0.125 * ((100vw - 20rem) / 60))",
    xxxl: "calc(2.1875rem + 0.125 * ((100vw - 20rem) / 60))",
  },
  width: {
    customized: "250px",
  },
  height: {
    lg: "60px",
  },
  paddingLeft: {
    small: "5px",
  },
  // used for padding and margin
  spacing: {
    auto: "auto",
    xxs: "6px",
    xs: "8px",
    s: "10px",
    sm: "12px",
    md: "16px",
    lg: "24px",
    xl: "32px",
    xxl: "40px",
  },
  sizes: {
    xsmall: "9px",
    small: "18px",
    smallPadding: "18px",
    profileImageSquare: "300px",
  },
  fontFamily: {
    main: `'Mulish', sans-serif`,
    decorative: `'Lora', serif`,
  },
  backgroundImage: {
    main: "#F4F8FF",
  },
};
export default theme;

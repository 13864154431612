import React from "react";
import styled from "styled-components/macro";
import { theme } from "theme";
import { Semantic, Typography } from "components/SemanticComponents";

const Pillar = styled.div`
  width: fit-content;
  p {
    margin: 0;
  }
  ${(props) => {
    switch (props.data) {
      case 1:
        return {
          background: `${props.theme.colors.altBlue}`,
        };
      case 2:
        return {
          background: `${props.theme.colors.altPink}`,
        };
      case 3:
        return {
          background: `${props.theme.colors.altGreen}`,
        };
      case 4:
        return {
          background: `${props.theme.colors.altPurple}`,
        };
      default:
        return {
          background: `${props.theme.colors.altBlue}`,
        };
    }
  }}
`;

export default function LessonWrapperHeader({ data, attribute, title }) {
  return (
    <Semantic.Header
      width="100%"
      maxWidth="1000px"
      margin={`${theme.spacing.md} auto 0 auto`}
      padding={`${theme.spacing.xl}`}
      data={data.lesson?.pillar?.id}
    >
      <Pillar data={data.lesson?.pillar?.id}>
        <Typography.Paragraph level="p2">{attribute}</Typography.Paragraph>
      </Pillar>

      <Typography.Title level="h1-body">{title}</Typography.Title>
    </Semantic.Header>
  );
}

import PropTypes from "prop-types";
import styled from "styled-components/macro";

// probably useless just a fallback
const StyledStart = styled.div`
  display: flex;
  justify-content: flex-start;
  min-height: 20px;
  padding: 10px;
`;

const StyledCenter = styled.div`
  display: flex;
  justify-content: center;
`;

const StyledEnd = styled.div`
  display: flex;
  justify-content: flex-end;
  color: ${(props) => props.theme.colors.heading};
  min-height: 20px;
  gap: 10px;
  margin-top: ${(props) => props.theme.spacing.sm};
  a {
    color: ${(props) => props.theme.colors.heroBlue};
  }
`;
export default function ContentAlignment({ children, ...props }) {
  switch (props.align) {
    case "center":
      return <StyledCenter>{children}</StyledCenter>;
    case "end":
      return <StyledEnd>{children}</StyledEnd>;
    default:
      return <StyledStart>{children}</StyledStart>;
  }
}

ContentAlignment.propTypes = {
  children: PropTypes.any.isRequired,
  align: PropTypes.oneOf(["start", "center", "end"]),
};

import React from "react";
import styled from "styled-components/macro";
import { Branding, ContentAlignment } from "components";
import {
  Button,
  Semantic,
  EmphasisLink,
  TextInput,
  Typography,
  RegistrationCardDiv,
} from "components/SemanticComponents";

const ResetPasswordFormStyles = styled.form``;

export default function ForgotPasswordForm({
  email,
  message,
  loading,
  handleSubmit,
  handleEmailChange,
}) {
  return (
    <Semantic.Section display="grid" height="100vh" placeItems="center">
      <RegistrationCardDiv>
        <Branding />
        <Typography.Title level="h2">Reset your password</Typography.Title>
        {!message ? (
          <ResetPasswordFormStyles onSubmit={handleSubmit}>
            <TextInput
              disabled={loading}
              name="email"
              label="Email"
              value={email}
              onChange={handleEmailChange}
            />
            <Button block onClick={handleSubmit} disabled={loading}>
              Request Reset
            </Button>
            <ContentAlignment align="center">
              <EmphasisLink to="/login">Back to login</EmphasisLink>
            </ContentAlignment>
          </ResetPasswordFormStyles>
        ) : (
          <React.Fragment>
            <Typography.Paragraph level="p2">{message}</Typography.Paragraph>
            <ContentAlignment align="center">
              <EmphasisLink to="/login">Back to login</EmphasisLink>
            </ContentAlignment>
          </React.Fragment>
        )}
      </RegistrationCardDiv>
    </Semantic.Section>
  );
}

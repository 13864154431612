import React from "react";
import { useAPI } from "hooks/api";
import PropTypes from "prop-types";

/**
 * Wrapper for statefulGet that allows it to be called conditionally
 * @param {func} setState setter function from parent
 * @param {string} url url api endpoint for the statefulGet to call
 *
 * ```
 * const [pillarState, setPillarState] = React.useState({});
 * const { status, data } = pillarState;
 *
 * {statusOtherStatefulGet === "fetched" && (
 * <StatefulGetWrapper
 *   setState={setPillarState}
 *   url={`/empowerment/next-question/${pillarId}`}
 * />
 * )}
 * ```
 *
 * @returns {null}
 */
export default function StatefulGetWrapper({ setState, url }) {
  const { statefulGet } = useAPI();
  const state = statefulGet(url);

  React.useEffect(() => {
    setState(state);
  }, [setState, state]);

  return null;
}

StatefulGetWrapper.propTypes = {
  url: PropTypes.string.isRequired,
  setState: PropTypes.func.isRequired,
};

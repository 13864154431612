import React from "react";
import styled from "styled-components/macro";
import { Semantic } from "components/SemanticComponents";

const Label = styled.label`
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: ${(props) => props.theme.fontSize.sm};
  color: ${(props) => props.theme.colors.body};
`;

const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  clip: rect(0 0 0 0);
  position: absolute;
`;

const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 3px;
`;

const StyledCheckbox = styled.div`
  display: flex;
  width: 20px;
  height: 20px;
  background: ${(props) =>
    props.checked
      ? props.theme.colors.heroBlue
      : props.theme.colors.primaryBackground};
  border-radius: ${(props) => props.theme.borderRadius.sm};
  transition: all 150ms;
  margin-right: ${(props) => props.theme.spacing.sm};

  border: ${(props) =>
    props.checked
      ? `1px solid ${props.theme.colors.transparent}`
      : `1px solid #9d9d9d`};

  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 2px #2461c5;
  }

  ${Icon} {
    visibility: ${(props) => (props.checked ? "visible" : "hidden")};
  }
`;

export default function Checkbox({
  id,
  name,
  checked,
  children,
  onChange,
  ...props
}) {
  return (
    <Semantic.Div width="100%">
      <Label htmlFor={id}>
        <HiddenCheckbox
          id={id}
          name={name}
          checked={checked}
          onChange={onChange}
          {...props}
        />
        <StyledCheckbox
          name={name}
          checked={checked}
          onChange={onChange}
          {...props}
        >
          <Icon name={name} onChange={onChange} viewBox="0 0 24 24">
            <polyline points="20 6 9 17 4 12" />
          </Icon>
        </StyledCheckbox>
        {children}
      </Label>
    </Semantic.Div>
  );
}

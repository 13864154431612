import React from "react";
import styled from "styled-components/macro";
import { theme } from "theme";
import {
  ArticlesActivitiesTab,
  ExploreTabsComponent,
  SearchInput,
  Pagination,
  AssignedLessonsComponent,
  Loader,
  GlassBackground,
} from "components";
import { Typography, Semantic, Button } from "components/SemanticComponents";

const SearchHeader = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  h1 {
    margin: 0;
  }
  margin-bottom: ${(props) => props.theme.spacing.sm};
  @media (max-width: ${(props) => props.theme.breakPoint.mobile}) {
    flex-direction: column;
  }
`;

const FormWrapper = styled.form`
  display: flex;
  height: 50%;
  width: 100%;
  justify-content: flex-end;
  align-items: flex-end;

  /* Hide scrollbar for Chrome, Safari and Opera */
  .scrollbar-hidden::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge add Firefox */
  .scrollbar-hidden {
    -ms-overflow-style: none;
    scrollbar-width: none; /* Firefox */
  }

  @media (max-width: ${(props) => props.theme.breakPoint.tablet}) {
    flex-direction: row;
    align-items: center;
    margin-top: 5px;
  }
  @media (max-width: ${(props) => props.theme.breakPoint.mobile}) {
    align-items: center;
    margin-top: 5px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: inherit;
  box-sizing: border-box;
`;

export default function ExploreContainer({
  handleInputChange,
  handleSubmit,
  clearSearch,
  pillToggle,
  articleStatus,
  articles,
  select,
  filter_categories_options,
  filteredPosts,
  setFilter,
  inputValue,
  toggle,
  filter,
  currentPage,
  setCurrentPage,
  goToPreviousPage,
  goToNextPage,
  dataLimit,
  pageLimit,
  pages,
}) {
  const urlParams = new URLSearchParams(window.location.search);
  const [queryCategory, setQueryCategory] = React.useState(null);

  /** Sets the filter going on initial visit to url */
  React.useEffect(() => {
    const initialUrlParams = new URLSearchParams(window.location.search);
    setQueryCategory(initialUrlParams.get("category"));
  }, []);

  /** Update the current url with the set params */
  const updateURL = () => {
    window.history.pushState({}, "", `?${urlParams.toString()}`);
  };

  /** Handler for setting the category filter */
  const setCategoryQueryParamsFilter = (category) => {
    if (category !== "") {
      urlParams.delete("search");
      clearSearch();
      urlParams.set("category", category);
    } else {
      urlParams.delete("category");
    }
    updateURL();
    setQueryCategory(urlParams.get("category"));
  };

  /** Changes the filter when category query params changes */
  React.useEffect(() => {
    if (queryCategory !== null) {
      setFilter(queryCategory);
    } else {
      setFilter("");
    }
  }, [setFilter, queryCategory]);

  return (
    <Semantic.Section padding={`${theme.spacing.lg}`}>
      <GlassBackground>
        <SearchHeader>
          <Typography.Title level="h1-body">Explore</Typography.Title>
          {toggle ? (
            <FormWrapper onSubmit={handleSubmit}>
              <SearchInput
                value={inputValue}
                handleInputChange={handleInputChange}
                clearSearch={clearSearch}
                name={"search"}
              />
              <ButtonWrapper>
                <Button
                  type="submit"
                  alternate
                  search
                  width="100%"
                  aria-label="search blogs"
                >
                  Search
                </Button>
              </ButtonWrapper>
            </FormWrapper>
          ) : null}
        </SearchHeader>
        <ExploreTabsComponent
          filter_categories_options={filter_categories_options}
          articles={articles}
          setFilter={setCategoryQueryParamsFilter}
          toggle={toggle}
          filter={filter}
          setCurrentPage={setCurrentPage}
          clearSearch={clearSearch}
        />

        <Typography.Paragraph level="p2">
          Read empowering articles or explore all of our activities.
        </Typography.Paragraph>
        <Typography.Paragraph level="p1">
          Learn from our community of parents, carers, career coaches, legal
          experts, and thought leaders through our articles. Or, explore our
          activities and personalise your empowerment journey.
        </Typography.Paragraph>

        <ArticlesActivitiesTab
          toggle={toggle}
          pillToggle={pillToggle}
          setCurrentPage={setCurrentPage}
          data={filteredPosts}
          filteredPosts={filteredPosts}
          goToPreviousPage={goToPreviousPage}
          goToNextPage={goToNextPage}
          currentPage={currentPage}
          dataLimit={dataLimit}
          pageLimit={pageLimit}
          pages={pages}
        />
      </GlassBackground>

      {articleStatus === "idle" || articleStatus === "fetching" ? (
        <Loader />
      ) : null}

      {articleStatus === "fetched" && articles ? (
        <React.Fragment>
          {toggle ? (
            <Pagination
              data={filteredPosts}
              filteredPosts={filteredPosts}
              pageLimit={pageLimit}
              dataLimit={dataLimit}
              select={select}
              toggle={toggle}
              goToPreviousPage={goToPreviousPage}
              goToNextPage={goToNextPage}
              currentPage={currentPage}
              pages={pages}
              type="secondary"
            />
          ) : (
            <AssignedLessonsComponent editCard={false} />
          )}
        </React.Fragment>
      ) : null}
    </Semantic.Section>
  );
}

import React from "react";
import styled from "styled-components/macro";
import { animateTab } from "helpers/animation";
import { useToast } from "context/toast";
import { Loader } from "components";
import {
  RadioButton,
  Semantic,
  Typography,
} from "components/SemanticComponents";

const FadingSummary = styled.div`
  transition: opacity 0.3s;
  opacity: ${(props) => {
    if (props.animateOut) {
      return 0;
    }
    if (props.animateIn) {
      return 1;
    }
  }};
`;

export default function RadioSelect({ item, updateAnswer, fetchAnswer }) {
  const { showToast } = useToast();
  const [value, setValue] = React.useState(null);
  const [loaded, setLoaded] = React.useState(false);
  const [statement, setStatement] = React.useState("");
  const { id: questionId } = item;
  const { options, title } = JSON.parse(item.data);

  const [animateOut, setAnimateOut] = React.useState(false);
  const [animateIn, setAnimateIn] = React.useState(false);

  const refresh = React.useCallback(() => {
    fetchAnswer(questionId).then((response) => {
      if (statement === "") {
        setStatement(response?.result?.data?.text);
      }
      setValue(response?.result?.data?.text ?? null);
      setLoaded(true);
    });
  }, [fetchAnswer, questionId, statement]);

  React.useEffect(() => {
    refresh();
  }, [questionId, refresh]);

  const handleSelectChange = (e) => {
    const select = options.find((i) => i.id === e.target.id);
    animateTab(select, setAnimateIn, setStatement, setAnimateOut);
    updateAnswer(questionId, { text: select })
      .then(() => {
        refresh();
      })
      .catch((error) => {
        console.log(error);
        showToast({
          type: "warning",
          title: "unsuccessful",
          message:
            "Oops something went wrong, and your selection has not been updated.",
          timer: 4000,
        });
      });
  };

  return (
    <React.Fragment>
      {loaded ? (
        <React.Fragment>
          <Semantic.Fieldset
            border="none"
            display="flex"
            flexDirection="column"
            justifyContent="flex-start"
            onChange={(e) => handleSelectChange(e)}
          >
            {title ? (
              <Typography.Title level="h2">{title}</Typography.Title>
            ) : null}

            <Semantic.Div margin="0 15px">
              {options.map((opt, index) => {
                return (
                  <RadioButton
                    key={`fieldset-${item.id}-index${index}`}
                    id={opt.id}
                    name={`fieldset-${item.id}`}
                    defaultChecked={opt.id === value?.id ? true : false}
                    layout="portrait"
                  >
                    {opt.value}
                  </RadioButton>
                );
              })}
            </Semantic.Div>
          </Semantic.Fieldset>
          <FadingSummary animateOut={animateOut} animateIn={animateIn}>
            <Typography.Paragraph level="p3">
              {statement?.summary}
            </Typography.Paragraph>
          </FadingSummary>
        </React.Fragment>
      ) : (
        <Loader />
      )}
    </React.Fragment>
  );
}

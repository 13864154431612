import PropTypes from "prop-types";
import styled from "styled-components/macro";

const StyledButton = styled.button`
  background: ${(props) => {
    if (
      props.outlined ||
      props.alternate ||
      props.ghost ||
      props.ghostLink ||
      props.round
    ) {
      return props.theme.colors.primaryBackground;
    } else if (props.disabled) {
      return props.theme.colors.inactive;
    } else {
      return props.theme.colors.heroBlue;
    }
  }};
  border: ${(props) => {
    if (props.alternate) {
      return `1px solid black`;
    }
    if (props.outlined || props.round) {
      return `1px solid ${props.theme.colors.heroBlue}`;
    }
    if (props.ghost || props.ghostLink) {
      return "none";
    }
    if (props.search) {
      return `1px solid ${props.theme.colors.body}`;
    } else {
      return "none";
    }
  }};
  color: ${(props) => {
    if (props.alternate || props.ghost) {
      return `black`;
    }
    if (props.ghostLink) {
      return `${props.theme.colors.heroBlue}`;
    }
    if (props.outlined || props.round) {
      return props.theme.colors.heroBlue;
    } else {
      return props.theme.colors.primaryBackground;
    }
  }};

  border-radius: ${(props) => {
    if (props.round) {
      return `50%`;
    } else if (props.search) {
      return props.theme.borderRadius.md;
    } else {
      return `16px`;
    }
  }};

  font-size: ${(props) =>
    props.round
      ? props.theme.fontSize.buttonRound
      : props.theme.fontSize.button};
  height: ${(props) => (props.round ? `30px` : `40px`)};

  margin-bottom: ${(props) =>
    props.block || props.marginTopBlock ? props.theme.spacing.md : "0"};
  margin-top: ${(props) =>
    props.marginTopBlock ? props.theme.spacing.md : "0"};

  font-family: ${(props) =>
    props.round
      ? props.theme.fontFamily.decorative
      : props.theme.fontFamily.main};

  font-weight: bold;

  width: ${(props) => {
    if (props.width) {
      return props.width;
    }
    if (props.block || props.marginTopBlock) {
      return "100%";
    }
    if (props.ghost || props.ghostLink) {
      return "auto";
    }
    if (props.round) {
      return `30px`;
    }
    return "240px";
  }};

  &:hover {
    outline: none;
    background: ${(props) => {
      if (props.alternate) {
        return props.theme.colors.altBlue;
      }
      if (props.ghost || props.ghostLink) {
        return props.theme.colors.primaryBackground;
      }
      if (props.disabled) {
        return props.theme.colors.inactive;
      } else {
        return props.theme.colors.altBlue;
      }
    }};

    text-decoration: ${(props) => {
      if (props.ghost || props.ghostLink) {
        return "underline";
      }
    }};

    border: ${(props) => {
      if (props.disabled || props.ghost || props.ghostLink) {
        return props.theme.colors.primaryBackground;
      } else {
        return `1px solid ${props.theme.colors.heroBlue}`;
      }
    }};

    box-shadow: ${(props) =>
      props.disabled || props.ghost || props.ghostLink
        ? "none"
        : "4px 4px 10px 0 #00000010"};

    color: ${(props) => {
      if (props.ghost || props.ghostLink) {
        return props.theme.colors.heroBlue;
      }
      if (props.disabled) {
        return props.theme.colors.primaryBackground;
      }
      return props.theme.colors.heroBlue;
    }};
    cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  }
`;

export default function Button({ children, ...props }) {
  return <StyledButton {...props}>{children}</StyledButton>;
}

Button.propTypes = {
  children: PropTypes.string.isRequired,
  block: PropTypes.bool,
  ghost: PropTypes.bool,
  ghostLink: PropTypes.bool,
  outlined: PropTypes.bool,
  disabled: PropTypes.bool,
  left: PropTypes.bool,
  alternate: PropTypes.bool,
  marginTopBlock: PropTypes.bool,
  round: PropTypes.bool,
  active: PropTypes.bool,
};

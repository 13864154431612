import React from "react";
import styled from "styled-components";
import { useAPI } from "hooks/api";
import { Loader, GlassBackground, PDFColour, PDFPlain } from "components";
import { PDFViewer, PDFDownloadLink, StyleSheet } from "@react-pdf/renderer";
import { EmphasisLink, Typography } from "components/SemanticComponents";

const PDFContainer = styled.section`
  height: 80vh;
  width: 80%;
  margin: auto auto 10px auto;
  @media (max-width: ${(props) => props.theme.breakPoint.mobile}) {
    margin: 0;
    width: 100%;
  }
`;
const DownloadLink = styled.div`
  display: flex;
  padding-right: 10%;
  justify-content: flex-end;
  padding-bottom: 5px;
  @media (max-width: ${(props) => props.theme.breakPoint.mobile}) {
    margin: 0;
  }
`;

const styles = StyleSheet.create({
  container: {
    height: "100%",
    width: "100%",
  },
});

export default function ProfessionalVisionPDF() {
  const { statefulGet } = useAPI();
  const { status, data } = statefulGet("/professional-vision/show");

  return (
    <React.Fragment>
      {status === "idle" || status === "fetching" ? <Loader /> : null}
      {status === "fetched" &&
      data.message !== "Professional Vision not completed" ? (
        <React.Fragment>
          <GlassBackground>
            <DownloadLink>
              <PDFDownloadLink
                document={<PDFColour data={data} />}
                fileName="ProfessionalVision.pdf"
              >
                {({ blob, url, loading, error }) => {
                  console.log(error);
                  return loading
                    ? "Loading document..."
                    : "Download your colour Professional Vision on this page as a PDF";
                }}
              </PDFDownloadLink>
            </DownloadLink>

            <DownloadLink>
              <PDFDownloadLink
                document={<PDFPlain data={data} />}
                fileName="ProfessionalVision.pdf"
              >
                {({ blob, url, loading, error }) => {
                  console.log(error);
                  return loading
                    ? "Loading document..."
                    : "Download your non colour Professional Vision on this page as a PDF";
                }}
              </PDFDownloadLink>
            </DownloadLink>
          </GlassBackground>

          <PDFContainer>
            <PDFViewer style={styles.container}>
              <PDFColour data={data} />
            </PDFViewer>
          </PDFContainer>
        </React.Fragment>
      ) : (
        <GlassBackground>
          <Typography.Title level="h1-decorative">
            Your Professional Vision board has not been started or completed,
            please return to the{" "}
            <EmphasisLink to="/my-insights" underlined>
              Insights page
            </EmphasisLink>{" "}
            to complete.
          </Typography.Title>
        </GlassBackground>
      )}
    </React.Fragment>
  );
}

import React from "react";
import styled from "styled-components/macro";

const PillarStyle = styled.p`
  width: fit-content;
  font-weight: bold;
  font-size: ${(props) => props.theme.fontSize.xxs};

  ${(props) => {
    switch (props?.pillar?.id) {
      case 1:
        return {
          color: `${props.theme.colors.heroBlue}`,
          backgroundColor: `${props.theme.colors.altBlue}`,
          border: `1px solid ${props.theme.colors.heroBlue}`,
          borderRadius: `${props.theme.borderRadius.lg}`,
        };
      case 2:
        return {
          color: `${props.theme.colors.heroPink}`,
          backgroundColor: `${props.theme.colors.altPink}`,
          border: `1px solid ${props.theme.colors.heroPink}`,
          borderRadius: `${props.theme.borderRadius.lg}`,
        };
      case 3:
        return {
          color: `${props.theme.colors.heroGreen}`,
          backgroundColor: `${props.theme.colors.altGreen}`,
          border: `1px solid ${props.theme.colors.heroGreen}`,
          borderRadius: `${props.theme.borderRadius.lg}`,
        };
      case 4:
        return {
          color: `${props.theme.colors.heroPurple}`,
          backgroundColor: `${props.theme.colors.altPurple}`,
          border: `1px solid ${props.theme.colors.heroPurple}`,
          borderRadius: `${props.theme.borderRadius.lg}`,
        };
      default:
        return {
          color: `${props.theme.colors.heroBlue}`,
          backgroundColor: `${props.theme.colors.altBlue}`,
          border: `1px solid ${props.theme.colors.heroBlue}`,
          borderRadius: `${props.theme.borderRadius.lg}`,
        };
    }
  }}
`;

const PillarBoarder = styled.span`
  padding-left: ${(props) => props.theme.spacing.md};
  p {
    font-size: ${(props) => props.theme.fontSize.xxs};
  }
`;

export default function AllyCardPillar({ pillar }) {
  function SwitchReturn(props) {
    switch (props?.pillar?.id) {
      case 1:
        return (
          <PillarBoarder>
            <PillarStyle pillar={props.pillar}>
              {props.pillar.title}
            </PillarStyle>
          </PillarBoarder>
        );
      case 2:
        return (
          <PillarBoarder>
            <PillarStyle pillar={props.pillar}>
              {props.pillar.title}
            </PillarStyle>
          </PillarBoarder>
        );
      case 3:
        return (
          <PillarBoarder>
            <PillarStyle pillar={props.pillar}>
              {props.pillar.title}
            </PillarStyle>
          </PillarBoarder>
        );
      case 4:
        return (
          <PillarBoarder>
            <PillarStyle pillar={props.pillar}>
              {props.pillar.title}
            </PillarStyle>
          </PillarBoarder>
        );
      default:
        return null;
    }
  }
  return <SwitchReturn pillar={pillar} />;
}

import React from "react";
import { useToast } from "context/toast";
import { SimpleRadioButton, Loader } from "components";
import { Semantic, Typography } from "components/SemanticComponents";

export default function RadioLandscape({ item, updateAnswer, fetchAnswer }) {
  const { showToast } = useToast();
  const [value, setValue] = React.useState(null);
  const [loaded, setLoaded] = React.useState(false);
  const { id: questionId } = item;
  const { options, title } = JSON.parse(item.data);

  const refresh = React.useCallback(() => {
    fetchAnswer(questionId).then((response) => {
      setValue(response?.result?.data?.value ?? null);
      setLoaded(true);
    });
  }, [fetchAnswer, questionId]);

  React.useEffect(() => {
    refresh();
  }, [questionId, refresh]);

  const handleSelectChange = (e) => {
    const radioIdNumber = e.target.id.match(/\d+$/)[0];
    const select = options.find(
      (option) => Number(option.id) === Number(radioIdNumber)
    );
    updateAnswer(questionId, { value: select })
      .then(() => {
        refresh();
      })
      .catch((error) => {
        console.log(error);
        showToast({
          type: "warning",
          title: "unsuccessful",
          message:
            "Oops something went wrong, and your selection has not been updated.",
          timer: 4000,
        });
      });
  };

  return (
    <React.Fragment>
      {loaded ? (
        <Semantic.Fieldset
          border="none"
          display="flex"
          flexDirection="column"
          padding="0"
          onChange={(e) => handleSelectChange(e)}
        >
          {title ? (
            <Typography.Title level="h4">{title}</Typography.Title>
          ) : null}
          <Semantic.Div margin="0 15px" display="flex" justifyContent="center">
            {options.map((opt) => {
              return (
                <SimpleRadioButton
                  key={`fieldset-${item.id}-radio-${opt.id}`}
                  id={`fieldset-${item.id}-radio-${opt.id}`}
                  name={`fieldset-${item.id}`}
                  defaultChecked={opt.id === value?.id ? true : false}
                >
                  {opt.value}
                </SimpleRadioButton>
              );
            })}
          </Semantic.Div>
        </Semantic.Fieldset>
      ) : (
        <Loader />
      )}
    </React.Fragment>
  );
}

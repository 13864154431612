import React from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import { markdownStyle } from "theme";

export default function Markdown({ item, children }) {
  const text = item ? JSON.parse(item?.data).text : children;

  return (
    <ReactMarkdown
      remarkPlugins={[remarkGfm]}
      rehypePlugins={[rehypeRaw]}
      components={markdownStyle}
    >
      {text}
    </ReactMarkdown>
  );
}

import React from "react";
import styled from "styled-components/macro";
import { bookmarkedPage } from "helpers/bookmarkedPage";
import { AllyCard, ResourceCard, Loader } from "components";
import { EmphasisLink, Typography } from "components/SemanticComponents";

const ViewAllContainer = styled.div`
  width: fit-content;
  a {
    color: ${(props) => props.theme.colors.heroBlue};
  }
  @media (max-width: ${(props) => props.theme.breakPoint.mobile}) {
    display: flex;
    width: 100%;
    a {
      margin: ${(props) => `0 0 ${props.theme.spacing.xs} auto`};
    }
  }
`;

const Title = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${(props) => props.theme.spacing.sm};
  h3 {
    margin: 0;
    font-size: ${(props) => props.theme.fontSize.md};
    color: black;
    font-weight: normal;
  }
  a {
    color: black;
    background-color: white;
    border: black 1px solid;
    border-radius: 20px;
    padding: 5px 10px;
    font-size: ${(props) => props.theme.fontSize.button};
    &:hover,
    &:focus-within {
      color: ${(props) => props.theme.colors.heroBlue};
      border: ${(props) => `solid 1px ${props.theme.colors.heroBlue}`};
    }
  }
  @media (max-width: ${(props) => props.theme.breakPoint.mobile}) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const Container = styled.div`
  border-radius: 10px;
  margin-bottom: ${(props) => props.theme.spacing.sm};
  padding: ${(props) => props.theme.spacing.sm};
  width: 100%;
  h1 {
    margin: 0;
  }
  span {
    a {
      margin-left: auto;
    }
  }
`;

const ButtonAlignment = styled.div`
  display: flex;
  justify-content: flex-end;
  a {
    color: black;
    background-color: white;
    border: black 1px solid;
    border-radius: 20px;
    padding: 5px 10px;
    font-size: ${(props) => props.theme.fontSize.button};
    &:hover,
    &:focus-within {
      color: ${(props) => props.theme.colors.heroBlue};
      border: ${(props) => `solid 1px ${props.theme.colors.heroBlue}`};
    }
  }
`;

const ErrorComponent = () => {
  return (
    <section>
      <Typography.Title level="h2">
        There has been a problem loading your activities
      </Typography.Title>
      Or there are none in your to-do,{` `}
      <EmphasisLink to="/program/personalise">add more here</EmphasisLink> or
      contact your administrator if this has been an error.
    </section>
  );
};

export default function ProgramOverview({ data, cardStatus }) {
  const s = () => {
    return;
  };

  function getActiveCards(data) {
    return Object.values(data)
      .sort((a, b) => a.raw_order - b.raw_order)
      .filter((card) => !card.completed)
      .slice(0, 6);
  }

  let activeCards = getActiveCards(data);

  return (
    <React.Fragment>
      {cardStatus === "fetching" || cardStatus === "idle" ? <Loader /> : null}

      {cardStatus === "fetched" ? (
        <Container>
          <Title>
            <Typography.Title level="h2">
              Your personalised program
            </Typography.Title>
            <ViewAllContainer>
              <EmphasisLink to="/program/personalise">
                Edit your program
              </EmphasisLink>
            </ViewAllContainer>
          </Title>

          <ResourceCard>
            {activeCards.map((card) => {
              return (
                <AllyCard
                  key={card.id}
                  data={card}
                  select={s}
                  link={`/lesson/${bookmarkedPage(card)}`}
                />
              );
            })}
          </ResourceCard>

          {activeCards.length === 0 ? <ErrorComponent /> : null}

          <ButtonAlignment>
            <EmphasisLink to="/program">View all</EmphasisLink>
          </ButtonAlignment>
        </Container>
      ) : null}
    </React.Fragment>
  );
}

import React from "react";
import { AllyCard } from "components";

export default function MyLessons({ data, select }) {
  return (
    <React.Fragment>
      {data
        .sort((a, b) => a.raw_order - b.raw_order)
        .map((card) => {
          return (
            <AllyCard
              key={card.id}
              data={card}
              select={select}
              link={`/lesson/${card.first_step_slug}`}
            />
          );
        })}
    </React.Fragment>
  );
}

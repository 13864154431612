import { useAuth } from "context/auth";
import { API, GET } from "helpers/api";

export function useAPI() {
  const { authToken } = useAuth();

  return {
    statefulGet: (url) => GET(url, authToken),
    get: (endpoint = "") => API({ endpoint }, authToken),
    post: (endpoint = "", data = {}) =>
      API({ endpoint, method: "POST", data }, authToken),
    postReset: (endpoint = "", data = {}, rawToken) =>
      API({ endpoint, method: "POST", data }, rawToken),
    put: (endpoint = "", data = {}) =>
      API({ endpoint, method: "PUT", data }, authToken),
  };
}

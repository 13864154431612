export function optimizeImageCardDesktop(src) {
  if (!src) return;
  return `${src.replace(
    /gracepapers-content-prod.s3.ap-southeast-2.amazonaws.com/,
    "grace-papers.imgix.net"
  )}?w=318&h=150&fit=crop`;
}

export function optimizeMiniAvatar(src) {
  return `${src.replace(
    /gracepapers-content-prod.s3.ap-southeast-2.amazonaws.com/,
    "grace-papers.imgix.net"
  )}?w=40&h=40&fit=crop`;
}

export function optimizeAvatar(src) {
  return `${src.replace(
    /gracepapers-content-prod.s3.ap-southeast-2.amazonaws.com/,
    "grace-papers.imgix.net"
  )}?w=300&h=300&fit=crop`;
}

export function optimizeImageBlogDesktop(src) {
  return `${src.replace(
    /gracepapers-content-prod.s3.ap-southeast-2.amazonaws.com/,
    "grace-papers.imgix.net"
  )}?w=950&h=500&fit=crop&crop=entropy`;
}

import React from "react";
import styled from "styled-components/macro";
import { Typography } from "components/SemanticComponents";

const Status = styled.span`
  ::after {
    content: "${(props) => props.saveStatus}";
    text-align: right;
    display: block;
  }
`;

const StyledInput = styled.input`
  display: inline-block;
  height: ${(props) => props.theme.spacing.xxl};
  width: 100%;
  padding-left: ${(props) => props.theme.spacing.xs};
  font-family: inherit;
  font-size: ${(props) => props.theme.fontSize.md};
  margin-top: ${(props) => props.theme.spacing.xs};
`;

const StyledLabel = styled.label`
  display: inline-block;
  padding-bottom: ${(props) => props.theme.spacing.lg};
  padding-right: ${(props) => props.theme.spacing.lg};
  font-size: ${(props) => props.theme.fontSize.md};
  width: 100%;
`;

const NOT_SAVED = "\u26A0 not saved";
const SAVED = "\u2713 saved";
const SAVING = "saving...";
const LOADING = "\u2026 loading, please wait";

const ShortQuestionFormStyles = styled.form`
  width: 100%;
`;

export default function ShortQuestion({ item, fetchAnswer, updateAnswer }) {
  const [loaded, setLoaded] = React.useState(false);
  const [value, setValue] = React.useState(null);
  const [saveStatus, setSaveStatus] = React.useState(null);
  const { id: questionId } = item;
  const { title } = JSON.parse(item.data);

  const refresh = React.useCallback(() => {
    fetchAnswer(questionId).then((response) => {
      setValue(response?.result?.data?.text);
      setLoaded(true);
    });
  }, [fetchAnswer, questionId]);

  React.useEffect(() => {
    refresh();
  }, [questionId, refresh]);

  React.useEffect(() => {
    if (loaded) {
      setSaveStatus(SAVING);
      const delayDebounceFn = setTimeout(() => {
        updateAnswer(questionId, { text: value })
          .then(() => {
            setSaveStatus(SAVED);
          })
          .catch((e) => {
            setSaveStatus(NOT_SAVED);
            console.log(e);
          });
      }, 500);

      return () => clearTimeout(delayDebounceFn);
    } else {
      setSaveStatus(LOADING);
    }
  }, [value, updateAnswer, questionId, refresh, loaded]);

  return (
    <ShortQuestionFormStyles>
      <StyledLabel>
        <Typography.Title level="h4">{title}</Typography.Title>
        <Status saveStatus={saveStatus}>
          <StyledInput
            value={value ? value : ""}
            onChange={(e) => setValue(e.target.value)}
            disabled={!loaded}
          />
        </Status>
      </StyledLabel>
    </ShortQuestionFormStyles>
  );
}

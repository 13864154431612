import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import { theme } from "theme";
import { Semantic } from "./SemanticComponents";

const StyledInput = styled.input`
  background: ${(props) =>
    props.disabled === true
      ? props.theme.colors.formDisabled
      : props.theme.colors.primaryBackground};
  border-radius: ${(props) => props.theme.borderRadius.sm};
  border: ${(props) => props.theme.border.formBorder};
  display: inline-block;
  height: 40px;
  padding: 1rem;
  width: 100%;
  z-index: 10;

  &:valid {
    border: ${(props) =>
      props.validate === false && props.value !== ""
        ? `solid ${props.theme.colors.heroGreen} 1px`
        : null};
  }

  &:focus-within {
    outline: none;
    //=> you would think that the false statement of invalid would be the same as valid, but you'd be wrong
    &:invalid {
      border: ${(props) =>
        props.validate === false && props.value !== ""
          ? null
          : `solid ${props.theme.colors.errorRed} 3px`};
    }
    &:valid {
      border: ${(props) =>
        props.validate === false && props.value !== ""
          ? `solid ${props.theme.colors.heroGreen} 3px`
          : `solid ${props.theme.colors.errorRed} 3px`};
    }
  }
`;

const StyledLabel = styled.label`
  margin-top: ${(props) => props.theme.sizes.smallPadding};
  color: ${(props) => props.theme.colors.heroBlue};
  display: inline-block;
  font-size: ${(props) => props.theme.fontSize.md};
  top: 18px;
  width: 100%;
  z-index: 1;
`;

const ValidationLabel = styled.div`
  color: ${(props) =>
    props.validate === false && props.value !== ""
      ? props.theme.colors.likertDGreen
      : props.theme.colors.body};
  border-bottom-left-radius: ${(props) => props.theme.borderRadius.sm};
  border-bottom-right-radius: ${(props) => props.theme.borderRadius.sm};
  padding-left: 5px;
`;

export default function TextInputValidation({
  type,
  name,
  label,
  onChange,
  onBlur,
  value,
  disabled,
  tabIndex,
  pattern,
  required,
  autoComplete,
  validate,
  beforeText,
  afterText,
  placeholder,
  title,
  onInvalid,
}) {
  return (
    <Semantic.Div width="inherit">
      <StyledLabel
        validate={validate}
        value={value}
        htmlFor={name}
        required={required}
      >
        {label}
        {required ? (
          <Semantic.Span color={`${theme.colors.errorRed}`}>
            {" "}
            (required)
          </Semantic.Span>
        ) : null}
      </StyledLabel>
      <StyledInput
        autoComplete={autoComplete}
        id={name}
        name={name}
        type={type}
        label={label}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        disabled={disabled}
        tabIndex={tabIndex}
        pattern={pattern}
        required={required}
        validate={validate}
        beforeText={beforeText}
        afterText={afterText}
        placeholder={placeholder}
        title={title}
        onInvalid={onInvalid}
        valid={validate}
      />

      <ValidationLabel
        onInvalid={onInvalid}
        validate={validate}
        value={value}
        aria-live="polite"
        aria-label={
          validate === false && value !== ""
            ? "input field validation completed"
            : null
        }
      >
        {validate === false && value !== "" ? afterText : beforeText}
      </ValidationLabel>
    </Semantic.Div>
  );
}

TextInputValidation.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  tabIndex: PropTypes.string,
  pattern: PropTypes.string,
  inputValidation: PropTypes.bool,
  title: PropTypes.string,
  onInvalid: PropTypes.func,
  autoComplete: PropTypes.string,
};

import React from "react";
import styled from "styled-components/macro";
import { Typography, Semantic } from "components/SemanticComponents";

const Button = styled.button`
  width: 140px;
  height: 140px;
  border-radius: 100%;
  border-style: none;
  color: white;
  margin-right: ${(props) => props.theme.spacing.sm};
  font-size: ${(props) => props.theme.fontSize.lg};
  background-image: linear-gradient(
      rgba(94, 94, 94, 0.47),
      rgba(94, 94, 94, 0.47)
    ),
    url("https://gracepapers.com.au/wp-content/uploads/2021/03/oleg-laptev-QRKJwE6yfJo-unsplash.jpg");
  background-size: cover;

  &:hover {
    cursor: pointer;
  }
  @media (max-width: ${(props) => props.theme.breakPoint.mobile}) {
    font-size: ${(props) => props.theme.fontSize.xxs};
    width: 70px;
    height: 70px;
  }
`;

export default function Topics({ handleSortArticles }) {
  return (
    <React.Fragment>
      <Typography.Title level="h2">Topics</Typography.Title>
      <Semantic.Div
        width="100%"
        display="flex"
        justifyContent="flex-start"
        flexWrap="wrap"
      >
        <Button onClick={() => handleSortArticles("featured")}>Featured</Button>
        <Button onClick={() => handleSortArticles("all")}>All</Button>
      </Semantic.Div>
    </React.Fragment>
  );
}

import React from "react";
import styled from "styled-components/macro";
import { theme } from "theme";
import { optimizeImageCardDesktop } from "helpers/imgix";
import { Loader, AllyCardPillar } from "components";
import { Button, Semantic, Typography } from "components/SemanticComponents";
import { useHistory } from "react-router-dom";
import { bookmarkedPage } from "helpers/bookmarkedPage";

const Header = styled.div`
  margin-bottom: ${(props) => props.theme.spacing.sm};
  h1 {
    margin: 0;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  height: fit-content;
  @media (max-width: ${(props) => props.theme.breakPoint.tablet}) {
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    height: 100%;
  }
`;

const ImageWrapper = styled.div`
  width: 50%;
  padding-right: ${(props) => props.theme.spacing.md};
  @media (max-width: ${(props) => props.theme.breakPoint.tablet}) {
    display: none;
  }
`;

const Img = styled.img`
  width: 100%;
  height: 150px;
  object-fit: cover;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: fit-content;
  width: 100%;

  span {
    p {
      border-radius: 20px;
      text-align: center;
      padding: 5px 20px;
    }
  }
  ol {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
  }
  h2 {
    margin: ${(props) => `0 0 ${props.theme.spacing.sm} 0`};
  }
`;

const Box = styled.li`
  list-style: none;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 16px;
  padding: ${(props) => props.theme.spacing.md};
  margin-bottom: ${(props) => props.theme.spacing.md};
  width: 250px;
  height: 200px;
  h3 {
    color: ${(props) => props.theme.colors.heroBlue};
  }
  p {
    margin: 0;
  }
  a {
    display: block;
    width: 100%;
    height: 100%;
    text-decoration: none;
    outline: none;
  }

  &:hover,
  &:focus-within {
    border: 1px solid ${(props) => props.theme.colors.heroBlue};
    background: ${(props) => props.theme.colors.altBlue};
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: ${(props) => `0 0 0 ${props.theme.spacing.auto}`};

  @media (max-width: ${(props) => props.theme.breakPoint.mobile}) {
    flex-direction: column;
  }
`;

export default function NextLesson({ data }) {
  const history = useHistory();

  const handleButton = () => {
    history.push(`/lesson/${bookmarkedPage(data)}`);
  };

  return (
    <React.Fragment>
      {data === null || data === undefined ? (
        <Loader />
      ) : data !== false ? (
        <Semantic.Section
          background={`${theme.colors.primaryBackground}`}
          padding={`${theme.spacing.lg}`}
          flex="2 1 0"
        >
          <Header>
            <Typography.Title level="h1-body">Up next</Typography.Title>
          </Header>
          <ContentContainer>
            <ImageWrapper>
              <Img
                src={optimizeImageCardDesktop(data?.image_url)}
                alt={data?.title}
                height="300"
                width="300"
              />
            </ImageWrapper>
            <ContentWrapper>
              <Typography.Title level="h2">{data?.title}</Typography.Title>
              <AllyCardPillar pillar={data?.pillar}>
                {data?.pillar?.title}
              </AllyCardPillar>
              <Typography.Paragraph level="p1">
                {data?.external_description}
              </Typography.Paragraph>
              <ButtonWrapper>
                <Button onClick={handleButton} aria-label="start">
                  Start
                </Button>
              </ButtonWrapper>
            </ContentWrapper>
          </ContentContainer>
        </Semantic.Section>
      ) : (
        <Semantic.Section display="flex" flexDirection="column" width="100%">
          <Header>
            <Typography.Title level="h1-body">Up next</Typography.Title>
          </Header>
          <ContentWrapper>
            <Typography.Title level="h2">
              There is nothing in your next steps right now but let's help you
              find something.
            </Typography.Title>
            <ol>
              <Box>
                <a href="/program" tabIndex="0">
                  <Typography.Title level="h3">View cards</Typography.Title>
                  <Typography.Paragraph level="p1">
                    Here you can view all the Cards we have, select the ones
                    that you like, and add them to your program.
                  </Typography.Paragraph>
                </a>
              </Box>
              <Box>
                <a href="/explore" tabIndex="0">
                  <Typography.Title level="h3">Read articles</Typography.Title>
                  <Typography.Paragraph level="p1">
                    Want to read some articles? They can be found here, along
                    with all our Card content too.
                  </Typography.Paragraph>
                </a>
              </Box>
            </ol>
            <ol>
              <Box>
                <a href="/company-policy">
                  <Typography.Title level="h3">Your workplace</Typography.Title>
                  <Typography.Paragraph level="p1">
                    Curious about your Companies Policies or want to see your
                    Company Announcements, they can be found here.
                  </Typography.Paragraph>
                </a>
              </Box>
              <Box>
                <a href="/account">
                  <Typography.Title level="h3">
                    View and edit your account settings
                  </Typography.Title>
                  <Typography.Paragraph level="p1">
                    Need to check your details are up to date, change your
                    profile picture?
                  </Typography.Paragraph>
                </a>
              </Box>
            </ol>
          </ContentWrapper>
        </Semantic.Section>
      )}
    </React.Fragment>
  );
}

import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";

const Container = styled.div`
  position: fixed;
  top: 50px;
  right: 0;
  margin-right: ${(props) => props.theme.spacing.sm};
  display: flex;
  flex-direction: column-reverse;
`;

export default function ToastsContainer({ children, ...props }) {
  return <Container {...props}>{children}</Container>;
}

ToastsContainer.propTypes = {
  children: PropTypes.any,
};

import React from "react";
import styled from "styled-components/macro";
import { useWindowDimensions } from "hooks/windowDimensions";
import { SelectInput } from "./SemanticComponents";
import { TabButton } from "components";

const DesktopContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  height: fit-content;
  gap: ${(props) => props.theme.spacing.s};
`;

export default function ExploreTabsComponent({
  articles,
  filter_categories_options,
  setFilter,
  toggle,
  filter,
  setCurrentPage,
  clearSearch,
}) {
  const { width } = useWindowDimensions();

  const mapArrayToObjectWithLabel = (arr) => {
    const result = [];
    for (let i = 0; i < arr.length; i++) {
      let element = arr[i];
      let res = { label: element };
      result.push(res);
    }
    return result;
  };

  const selectOptions = !filter_categories_options
    ? ""
    : mapArrayToObjectWithLabel(filter_categories_options);

  return (
    <React.Fragment>
      {toggle ? (
        <React.Fragment>
          {width <= 428 ? (
            <SelectInput
              name="tags"
              label="Search posts by categories"
              placeholder="All articles"
              options={selectOptions}
              value={!filter ? "" : filter}
              onChange={(e) => {
                setFilter(e.target.value);
                setCurrentPage(1);
              }}
            />
          ) : (
            <DesktopContainer>
              {articles.categories ? (
                <React.Fragment>
                  {articles.categories.map((category, index) => {
                    let color = category.css_color_value;
                    return (
                      <TabButton
                        key={index}
                        tabColour={color}
                        onClick={() => {
                          setFilter(category.name);
                          setCurrentPage(1);
                        }}
                      >
                        {category.name}
                      </TabButton>
                    );
                  })}
                  <TabButton
                    tabColour="Gainsboro"
                    onClick={() => {
                      setFilter("");
                      clearSearch();
                    }}
                  >
                    All articles
                  </TabButton>
                </React.Fragment>
              ) : null}
            </DesktopContainer>
          )}
        </React.Fragment>
      ) : null}
    </React.Fragment>
  );
}

import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";

const StyledH1Body = styled.h1`
  font-size: ${(props) => props.theme.fontSize.xxl};
  font-weight: normal;
  line-height: ${(props) => props.theme.lineHeight.xxxl};
  font-family: ${(props) => props.theme.fontFamily.decorative};
`;

const StyledH1Fancy = styled.h1`
  font-size: ${(props) => props.theme.fontSize.xxl};
  font-weight: normal;
  line-height: ${(props) => props.theme.lineHeight.xxl};
  font-family: ${(props) => props.theme.fontFamily.main};
`;

const StyledH2 = styled.h2`
  font-size: ${(props) => props.theme.fontSize.xl};
  font-weight: normal;
  line-height: ${(props) => props.theme.lineHeight.xl};
`;

const StyledH3 = styled.h3`
  font-size: ${(props) => props.theme.fontSize.lg};
  font-weight: bold;
  line-height: ${(props) => props.theme.lineHeight.lg};
  color: ${(props) => props.theme.colors.body};
  a {
  }
`;

const StyledH4 = styled.h4`
  font-size: ${(props) => props.theme.fontSize.md};
  font-weight: bold;
  line-height: ${(props) => props.theme.lineHeight.lg};
`;

const StyledH5 = styled.h5`
  font-size: ${(props) => props.theme.fontSize.sm};
  font-weight: bold;
  line-height: ${(props) => props.theme.lineHeight.lg};
`;

const StyledH6 = styled.h6`
  font-size: ${(props) => props.theme.fontSize.xs};
  font-weight: bold;
  line-height: ${(props) => props.theme.lineHeight.lg};
`;

const StyledP1 = styled.p`
  font-size: ${(props) => props.theme.fontSize.paragraph};
  font-weight: normal;
  line-height: ${(props) => props.theme.lineHeight.md};
  color: ${(props) => props.theme.colors.body};
`;

const StyledP1Bold = styled.p`
  font-size: ${(props) => props.theme.fontSize.paragraph};
  font-weight: bold;
  line-height: ${(props) => props.theme.lineHeight.md};
  text-decoration: none;
  white-space: pre-wrap;
  color: ${(props) => props.theme.colors.body};
  a {
    color: ${(props) => props.theme.colors.body};
  }
`;

const StyledP2 = styled.p`
  font-size: ${(props) => props.theme.fontSize.paragraph};
  font-weight: normal;
  line-height: ${(props) => props.theme.lineHeight.md};
  white-space: pre-wrap;
  a {
    color: ${(props) => props.theme.colors.heroBlue};
  }
`;

const Title = ({ children, ...props }) => {
  switch (props.level) {
    case "h1-body":
      return <StyledH1Body>{children}</StyledH1Body>;
    case "h1-decorative":
      return <StyledH1Fancy>{children}</StyledH1Fancy>;
    case "h2":
      return <StyledH2>{children}</StyledH2>;
    case "h3":
      return <StyledH3>{children}</StyledH3>;
    case "h4":
      return <StyledH4>{children}</StyledH4>;
    case "h5":
      return <StyledH5>{children}</StyledH5>;
    case "h6":
      return <StyledH6>{children}</StyledH6>;
    default:
      return <StyledH1Body>{children}</StyledH1Body>;
  }
};

const Paragraph = ({ children, ...props }) => {
  switch (props.level) {
    case "p1":
      return <StyledP1>{children}</StyledP1>;
    case "p2":
      return <StyledP2>{children}</StyledP2>;
    case "p3":
      return <StyledP1Bold>{children}</StyledP1Bold>;
    default:
      return <StyledP1>{children}</StyledP1>;
  }
};

const Typography = { Title, Paragraph };

Typography.propTypes = {
  children: PropTypes.string.isRequired,
  level: PropTypes.oneOf([
    "h1-body",
    "h1-decorative",
    "h2",
    "h3",
    "p1",
    "p2",
    "p3",
  ]),
  fontFamily: PropTypes.string,
};

export default Typography;

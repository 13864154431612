import { PopupModal } from "react-calendly";
import { Semantic } from "components/SemanticComponents";

export default function CalendlyModal(props) {
  return (
    <Semantic.Div id={props.div}>
      <PopupModal
        url="https://calendly.com/on-demand-coaching-grace-papers/on-demand-coaching"
        onModalClose={() => props.setIsCalendlyWidgetOpen(false)}
        open={props.isCalendlyWidgetOpen}
        rootElement={document.getElementById(props.div)}
      />
    </Semantic.Div>
  );
}

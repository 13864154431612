import React from "react";
import styled from "styled-components/macro";
import { Button } from "components/SemanticComponents";

const SurveyContainer = styled.div`
  z-index: 999;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.colors.mask};
  font-size: ${(props) => props.theme.fontSize.md};
  font-weight: bold;
  color: ${(props) => props.theme.colors.heading};
`;

const ModalContent = styled.div`
  position: relative;
  margin: 20px;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  border-radius: ${(props) => props.theme.borderRadius.sm};
  box-shadow: ${(props) => props.theme.boxShadow.md};
  background-color: #f7f8fa;
`;

const ModalHeader = styled.div`
  padding: ${(props) => `${props.theme.spacing.sm} ${props.theme.spacing.lg}`};
  font-size: ${(props) => props.theme.fontSize.lg};
  position: relative;
`;

const ModalBody = styled.div`
  flex-grow: 1;
  padding: ${(props) => `${props.theme.spacing.sm} ${props.theme.spacing.lg}`};
`;

const ModalFooter = styled.div`
  padding: ${(props) => `${props.theme.spacing.sm} ${props.theme.spacing.lg}`};
  display: flex;
  justify-content: flex-end;
`;

const CrossButton = styled.button`
  position: absolute;
  padding: 0;
  margin: 0;
  background: ${(props) => props.theme.colors.transparent};
  border: 0;
  top: 10px;
  right: 10px;
  font-weight: bold;
  cursor: pointer;
`;

const Container = ({ children, ...props }) => {
  const ESC_KEY = 27;
  const TAB_KEY = 9;

  React.useEffect(() => {
    function keyListener(e) {
      const listener = keyListenerMap.get(e.keyCode);
      return listener && listener(e);
    }
    document.addEventListener("keydown", keyListener);

    return () => document.removeEventListener("keydown", keyListener);
  });

  const modalRef = React.createRef();
  const handleTabKey = (e) => {
    const focusableModalElements = modalRef.current.querySelectorAll(
      'a[href], button, textarea, input[type="text"], input[type="radio"], input[type="checkbox"], input, label, select, form'
    );
    const firstElement = focusableModalElements[0];
    const lastElement =
      focusableModalElements[focusableModalElements.length - 1];

    if (!e.shiftKey && document.activeElement !== firstElement) {
      firstElement.focus();
      return e.preventDefault();
    }

    if (e.shiftKey && document.activeElement !== lastElement) {
      lastElement.focus();
      e.preventDefault();
    }
  };

  const keyListenerMap = new Map([
    [ESC_KEY, props.closeModal],
    [TAB_KEY, handleTabKey],
  ]);

  return (
    <SurveyContainer role="dialog" aria-label="modal" aria-modal="true">
      <ModalContent ref={modalRef}>{children}</ModalContent>
    </SurveyContainer>
  );
};

const Header = ({ ...props }) => {
  return (
    <ModalHeader>
      {props.children}
      <CrossButton onClick={props.closeModal}>✕</CrossButton>
    </ModalHeader>
  );
};

const Body = ({ ...props }) => {
  return <ModalBody>{props.children}</ModalBody>;
};

const Footer = ({ ...props }) => {
  return (
    <ModalFooter>
      <Button {...props} onClick={props.closeModal} />
    </ModalFooter>
  );
};

const Modal = { Container, Header, Body, Footer };

export default Modal;

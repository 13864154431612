import React from "react";
import { AllyCard } from "components";
import { exploreBookmarkedPage } from "helpers/bookmarkedPage";

export default function AssignedLessons({ data, select, editCard }) {
  return (
    <React.Fragment>
      {data
        .sort((a, b) => a.raw_order - b.raw_order)
        .filter((card) => card.raw_order !== 1)
        .map((card) => {
          return (
            <AllyCard
              key={card.id}
              data={card}
              select={select}
              editCard={editCard}
              link={`/lesson/${exploreBookmarkedPage(card)}`}
            />
          );
        })}
    </React.Fragment>
  );
}

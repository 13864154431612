import React from "react";
import styled, { keyframes } from "styled-components/macro";

const fade = keyframes`
	0% {
		filter: brightness(100%);
	} 
	100% {
		filter: brightness(102%);
	}
`;

const Button = styled.button`
  border: none;
  margin: 5px;
  width: 220px;
  height: 50px;
  padding: 0;
  cursor: pointer;
  border-radius: ${(props) => props.theme.borderRadius.xl};
  user-select: none;
  transition: transform 600ms cubic-bezier(0.3, 0.7, 0.4, 1);

  @media (max-width: ${(props) => props.theme.breakPoint.large}) {
    width: 150px;
    height: 40px;
    margin: 0;
  }
`;

const Span = styled.span`
  display: flex;
  width: 220px;
  height: 50px;
  justify-content: center;
  align-items: center;
  border-radius: ${(props) => props.theme.borderRadius.xl};
  background-color: ${(props) => props.tabColour};
  color: ${(props) => props.theme.colors.heading};
  font-weight: 700;
  transform: translate(0px, 0px);
  transition: transform 600ms cubic-bezier(0.3, 0.7, 0.4, 1);

  &:focus-visible {
    outline: revert;
  }
  &:hover,
  &:focus-visible {
    transform: translate(6px, -6px);
    transition: transform 250ms cubic-bezier(0.3, 0.7, 0.4, 1.5);
    animation: ${fade};
    animation-duration: 250ms;
    animation-fill-mode: forwards;
  }
  &:active {
    transform: translate(0px, 0px);
    transition: transform 50ms;
    filter: brightness(100%);
  }

  @media (max-width: ${(props) => props.theme.breakPoint.large}) {
    width: 150px;
    height: 40px;
  }
`;

export default function TabButton({ children, ...props }) {
  return (
    <Button {...props}>
      <Span {...props}>{children}</Span>
    </Button>
  );
}

import React from "react";
import styled from "styled-components/macro";
import {
  Item,
  LessonWrapperHeader,
  LessonWrapperContainer,
} from "components/Lesson";
import { Button, Semantic } from "components/SemanticComponents";

const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  align-self: end;
  justify-content: space-between;
  padding: ${(props) => props.theme.spacing.sm};
  @media (max-width: ${(props) => props.theme.breakPoint.tablet}) {
    padding: 0;
    width: 100%;
    margin-top: ${(props) => props.theme.spacing.lg};
    button {
      width: 40%;
      font-size: 14px;
    }
  }
`;

const Message = styled.div`
  margin-left: auto;
  border: ${(props) => `1px solid ${props.theme.colors.heroBlue}`};
  width: 100%;
  padding: 0.5rem;
  h5 {
    margin: 0;
    font-size: ${(props) => props.theme.fontSize.xs};
  }
  p {
    font-size: ${(props) => props.theme.fontSize.xxs};
    margin: 0;
  }
`;

const ProgressButtons = styled.div`
  button {
    margin-left: ${(props) => props.theme.spacing.sm};
  }
  @media (max-width: ${(props) => props.theme.breakPoint.tablet}) {
    display: flex;
    width: 100%;
    button {
      width: 45%;
      margin-left: auto;
      font-size: 14px;
    }
  }

  @media (max-width: ${(props) => props.theme.breakPoint.mobile}) {
    display: flex;
    flex-direction: column;
    button {
      margin: 0 0 5px 0;
    }
  }
`;

export default function LessonStepContainer({
  data,
  sortedContent,
  handleButton,
  updateAnswer,
  fetchAnswer,
  lastItem,
}) {
  return (
    <Semantic.Article>
      <LessonWrapperHeader
        data={data}
        attribute={data?.lesson?.pillar?.title}
        title={data?.lesson?.title}
      />

      <LessonWrapperContainer>
        {sortedContent.map((item, index) => (
          <Item
            fetchAnswer={fetchAnswer}
            updateAnswer={updateAnswer}
            data={item}
            key={index}
          />
        ))}
        <Semantic.HR />
        {lastItem.id === data.id ? (
          <Message>
            <h5>You have reached the end of this activity</h5>
            <p>
              Mark the activity as complete to remove it from your queue. If
              you'd like to return to this activity later, leave without
              completing (leaving it in your active queue). You can always
              access all activities on your My Program page
            </p>
          </Message>
        ) : null}
        <ButtonWrapper>
          <Button
            alternate
            aria-label="prev"
            onClick={() => handleButton("prev")}
          >
            Previous
          </Button>
          <ProgressButtons>
            <Button alternate onClick={() => handleButton("exit")}>
              {lastItem.id === data.id
                ? "Leave without completing"
                : "Save here and return home"}
            </Button>

            <Button
              onClick={() =>
                handleButton(lastItem.id === data.id ? "complete" : "next")
              }
            >
              {lastItem.id === data.id ? `Mark activity as complete` : `Next`}
            </Button>
          </ProgressButtons>
        </ButtonWrapper>
      </LessonWrapperContainer>
    </Semantic.Article>
  );
}

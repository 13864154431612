import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  html, body, #root {
	min-height: 100vh;
	height: 100vh;
	display: flex; 
	flex-direction: column;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
	background-color: #F4F8FF;
    font-size: 1rem;
    font-weight: normal;
    font-family: ${(props) => props.theme.fontFamily.main};
	input, select { 
		font-size: 100%; 
	}
  }
  
  *, *:before, *:after {
    box-sizing: inherit;
  }
  
  h1 {
	font-family: ${(props) => props.theme.fontFamily.decorative}; 
  }
`;

export default GlobalStyle;

/**
 * To return back to an image for the background, simply return the 4 lines of code below into the main codeblock above
 * 
 	background-attachment: fixed;
	background-size: contain;
	background: linear-gradient(hsla(44, 100%, 95%, 0.5), hsla(44, 100%, 95%, 0.5)), url(${(
	props
  ) => props.theme.backgroundImage.main}) center center;
 */

import React from "react";
import styled from "styled-components/macro";
import PropTypes from "prop-types";

const Container = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${(props) => props.theme.spacing.lg};
`;

export default function PillContainer({ children, ...props }) {
  return <Container {...props}>{children}</Container>;
}

PillContainer.propTypes = {
  children: PropTypes.any.isRequired,
};

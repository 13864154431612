import { useAuth } from "context/auth";
import { useStoreContext } from "utils/GlobalState";
import { NavLink } from "react-router-dom";
import { GET_USEROBJECT } from "utils/actions";
import { Redirect } from "react-router-dom";
import { useWindowDimensions } from "hooks/windowDimensions";
import {
  faHome,
  faRocket,
  faSquarePen,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components/macro";

const Nav = styled.nav`
  background-color: ${(props) => props.theme.colors.primaryBackground};
  padding: 0 3rem;
  position: sticky;
  top: 0;
  z-index: 100;
  box-shadow: ${(props) => props.theme.boxShadow.md};

  .brand {
    display: flex;
    align-items: center;
    flex-direction: row;
    font-size: large;
    font-weight: bold;
    margin-right: auto;
    span {
      color: ${(props) => props.theme.colors.heroBlue};
    }
  }

  > ul {
    grid-auto-flow: column;
    width: 100%;
    justify-items: center;

    .dropdown__title {
      text-align: center;
      display: inline-block;
      margin: 0;
      padding: 0.5rem 1rem;
    }

    > li {
      margin: 0 0.5rem;

      a,
      .dropdown__title {
        text-decoration: none;
        text-align: center;
        display: inline-block;
        font-size: ${(props) => props.theme.fontSize.md};

        &:focus {
          outline: none;
        }
      }

      > a,
      .dropdown__title {
        border-top: 3px solid transparent;
        transition: 280ms all 120ms ease-out;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    width: 100%;
    justify-items: center;
    li {
      padding: 0;
    }
  }
`;

const StyledNavLink = styled(NavLink).attrs({
  activeClassName: "active",
})`
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-decoration: none;
  color: ${(props) => props.theme.colors.heading};
  font-size: ${(props) => props.theme.fontSize.md};
  padding: 0.5rem 1.5rem;
  transition: 280ms all 120ms ease-out;

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }

  &.active {
    border-bottom: ${(props) => `2px solid ${props.theme.colors.heroBlue}`};
  }
`;

const Dropdown = styled.div`
  position: relative;

  > ul {
    display: grid;
    button {
      background-color: transparent;
      font-size: ${(props) => props.theme.fontSize.md};
      cursor: pointer;
    }

    > a,
    button {
      color: ${(props) => props.theme.colors.heading};
      font-family: ${(props) => props.theme.fontFamily.main};
      border: none;
      text-align: center;
      font-size: ${(props) => props.theme.fontSize.md};
      width: 100%;
      padding: 0.5em;
    }
  }

  .dropdown__title {
    cursor: pointer;
    display: inline-flex;
    height: 100%;
    align-items: center;
    border: none;
    font-size: ${(props) => props.theme.fontSize.md};
    font-family: ${(props) => props.theme.fontFamily.main};
    background-color: transparent;
    color: ${(props) => props.theme.colors.heading};

    &:after {
      content: "";
      border: 0.35rem solid transparent;
      border-top-color: blue;
      margin-left: 0.25em;
      position: relative;
      top: 1em;
    }
  }

  .dropdown__menu {
    background-color: white;
    position: absolute;
    min-width: 15ch;
    left: 50%;
    top: calc(100% + 0.25rem);
    transition: 280ms all 120ms ease-out;
    transform: rotateX(-90deg) translateX(-50%);
    transform-origin: top center;
    visibility: hidden;
    opacity: 0.3;
    padding: 0.5em 0;
    border-radius: 4px;
    box-shadow: 0 0.15em 0.25em rgba(0, 0, 0, 0.25);
    z-index: 100;

    a {
      color: ${(props) => props.theme.colors.heading};
      display: block;
      padding: 0.5em;
      opacity: 0;
      transition: 280ms all 120ms ease-out;

      &:hover {
        background-color: rgba(0, 0, 0, 0.25);
      }

      &:focus {
        outline: none;
        background-color: rgba(0, 0, 0, 0.25);
      }
    }
  }

  &:after {
    content: "";
    border: 0.5rem solid transparent;
    border-bottom-color: white;
    position: absolute;
    top: calc(100% - 0.75rem);
    left: 50%;
    transform: translateX(-50%);
    transition: 280ms all 120ms ease-out;
    opacity: 0;
    will-change: opacity;
  }

  &:hover,
  &:focus-within {
    .dropdown__title {
      border-top-color: pink;
    }

    .dropdown__menu {
      opacity: 1;
      transform: rotateX(0) translateX(-50%);
      visibility: visible;

      a {
        opacity: 1;
      }
    }

    &:after {
      opacity: 1;
    }
  }
`;

export default function Navbar() {
  const { width } = useWindowDimensions();
  const { dispatch } = useStoreContext();
  const { setAuthToken } = useAuth();

  const logout = (e) => {
    e.preventDefault();
    dispatch({
      type: GET_USEROBJECT,
      userObject: {},
    });
    //-> clear AuthContext
    setAuthToken("");

    //-> clear tokens
    window.localStorage.clear();

    //-> route user back to '/login'
    <Redirect to="/login" />;
  };

  return (
    <Nav>
      <ul>
        {width > 800 ? (
          <>
            <StyledNavLink
              to="/"
              activeClassName="active"
              exact
              className="brand"
            >
              <span>Grace</span> Papers
            </StyledNavLink>

            <StyledNavLink to="/program" activeClassName="active">
              <FontAwesomeIcon icon={faHome} />
              My Program
            </StyledNavLink>
            <StyledNavLink to="/explore" activeClassName="active">
              <FontAwesomeIcon icon={faRocket} />
              Explore
            </StyledNavLink>
            <StyledNavLink to="/my-insights" activeClassName="active">
              <FontAwesomeIcon icon={faSquarePen} />
              Professional Vision
            </StyledNavLink>
            <Dropdown>
              <button
                className="dropdown__title"
                aria-expanded="false"
                aria-controls="options-dropdown"
              >
                More
              </button>
              <ul className="dropdown__menu" id="options-dropdown">
                <StyledNavLink to="/company-policy" activeClassName="active">
                  My Workplace
                </StyledNavLink>
                <StyledNavLink to="/account" activeClassName="active">
                  Account
                </StyledNavLink>
                <StyledNavLink
                  to="/terms-and-conditions"
                  activeClassName="active"
                >
                  Terms
                </StyledNavLink>
                <StyledNavLink to="/privacy-statement" activeClassName="active">
                  Privacy
                </StyledNavLink>
                <button onClick={logout}>Logout</button>
              </ul>
            </Dropdown>
          </>
        ) : (
          <>
            <StyledNavLink
              to="/"
              activeClassName="active"
              exact
              className="brand"
            >
              <span>Grace</span> Papers
            </StyledNavLink>

            <Dropdown>
              <button
                className="dropdown__title"
                aria-expanded="false"
                aria-controls="options-dropdown"
              >
                Pages
              </button>
              <ul className="dropdown__menu" id="options-dropdown">
                <StyledNavLink to="/program" activeClassName="active">
                  My Program
                </StyledNavLink>
                <StyledNavLink to="/explore" activeClassName="active">
                  Explore
                </StyledNavLink>
                <StyledNavLink to="/my-insights" activeClassName="active">
                  Professional Vision
                </StyledNavLink>
                <StyledNavLink to="/company-policy" activeClassName="active">
                  My Workplace
                </StyledNavLink>
                <StyledNavLink to="/account" activeClassName="active">
                  Account
                </StyledNavLink>
                <StyledNavLink
                  to="/terms-and-conditions"
                  activeClassName="active"
                >
                  Terms
                </StyledNavLink>
                <StyledNavLink to="/privacy-statement" activeClassName="active">
                  Privacy
                </StyledNavLink>
                <button onClick={logout}>Logout</button>
              </ul>
            </Dropdown>
          </>
        )}
      </ul>
    </Nav>
  );
}

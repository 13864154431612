import React from "react";
import styled from "styled-components/macro";

const Container = styled.div`
  height: 100%;
  margin: ${(props) => `0 auto ${props.theme.spacing.md} auto`};
  padding: ${(props) => props.theme.spacing.xl};
  max-width: 1000px;
  width: 100%;
  background: ${(props) => props.theme.colors.primaryBackground};
  @media (max-width: ${(props) => props.theme.breakPoint.mobile}) {
    margin: none;
    padding: ${(props) => props.theme.spacing.md};
    box-shadow: none;
  }
  div::-webkit-scrollbar {
    width: 1em;
  }

  div::-webkit-scrollbar-track {
    box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.5);
    border-radius: 50px;
  }

  div::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.colors.heroBlue};
    border-radius: 50px;
  }
  div {
    scrollbar-color: ${(props) =>
      `${props.theme.colors.heroBlue} ${props.theme.colors.formDisabled}`};
    scrollbar-width: auto;
  }

  div::scrollbar-track {
    box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.5);
    border-radius: 50px;
  }

  div::scrollbar-thumb {
    background-color: ${(props) => props.theme.colors.heroBlue};
    border-radius: 50px;
  }
`;

export default function LessonWrapperContainer({ children }) {
  return <Container>{children}</Container>;
}

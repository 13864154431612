import React from "react";
import styled from "styled-components/macro";
import { Loader } from "components";
import { Typography } from "components/SemanticComponents";
import { useToast } from "context/toast";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const grid = 10;

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const DragItem = styled.div`
  width: 100%;
  text-align: center;
  margin: ${(props) => `0 auto ${grid}px auto`};
  border-style: ${(props) => (props.isDragging ? "none" : "solid")};
  border-color: ${(props) =>
    props.isDragging ? "none" : props.theme.colors.heroBlue};
  background: ${(props) =>
    props.isDragging ? props.theme.colors.heroBlue : "white"};
  color: ${(props) =>
    props.isDragging ? "white" : props.theme.colors.heroBlue};
  padding: ${grid}px;
`;

function DragItemWrapper({ dragItem, index }) {
  return (
    <Draggable draggableId={dragItem.id} index={index}>
      {(provided, snapshot) => (
        <DragItem
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          isDragging={snapshot.isDragging}
        >
          {dragItem.value}
        </DragItem>
      )}
    </Draggable>
  );
}

const DragItemList = React.memo(function DragItemList({ dragItems }) {
  return dragItems.map((dragItem, index) => (
    <DragItemWrapper
      dragItem={dragItem}
      index={index}
      key={Number(dragItem.id)}
    />
  ));
});

const DragContainer = styled.div`
  margin: 0 auto;
  width: 60%;
`;

export default function DragNDrop({ item, updateAnswer, fetchAnswer }) {
  const { showToast } = useToast();
  const { title, options } = JSON.parse(item.data);
  const { id: questionId } = item;
  const [dragList, setDragList] = React.useState(null);

  React.useEffect(() => {
    let mounted = true;
    const updateList = (dragItems) => {
      updateAnswer(questionId, { text: dragItems })
        .then((response) => {
          setDragList(response?.result?.data?.text);
        })
        .catch((error) => console.log(error));
    };

    if (dragList === null) {
      fetchAnswer(questionId).then((response) => {
        if (mounted) {
          //=> Step ONE
          //=> if on first load the user has no answers to this questions, send the list as answers to the user, then display that list. this only happens once
          if (
            response === undefined ||
            JSON.stringify(response.result) === "{}"
          ) {
            updateList(
              options.map((option) => ({ ...option, id: option.id.toString() }))
            );
          } else {
            setDragList(response?.result?.data?.text);
          }
        }
      });
    }

    return () => (mounted = false);
  }, [dragList, fetchAnswer, questionId, options, updateAnswer]);

  //=> Step TWO
  //=> When dragging ends and the list is rearranged, the new list is updated in the user DB, and updated in state, these calls are always assumed to resolve and feedback is only returned if there is an error, as a Toast to the customer
  function onDragEnd(result) {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const dragItems = reorder(
      dragList,
      result.source.index,
      result.destination.index
    );

    //=> send an updated answer after every drag, but no feedback to user is required unless it fails
    updateAnswer(questionId, { text: dragItems }).catch((error) => {
      console.log(error);
      showToast({
        type: "warning",
        title: "unsuccessful",
        message:
          "Oops something went wrong, and your selection has not been updated.",
        timer: 4000,
      });
    });

    setDragList(dragItems);
  }

  return (
    <React.Fragment>
      {dragList ? (
        <React.Fragment>
          <Typography.Title level="h2">{title}</Typography.Title>

          <DragDropContext styles={{ display: "flex" }} onDragEnd={onDragEnd}>
            <Droppable
              style={{ height: "90%", overflowY: "auto" }}
              droppableId="list"
            >
              {(provided) => (
                <DragContainer
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  <DragItemList dragItems={dragList} />
                  {provided.placeholder}
                </DragContainer>
              )}
            </Droppable>
          </DragDropContext>
        </React.Fragment>
      ) : (
        <Loader />
      )}
    </React.Fragment>
  );
}

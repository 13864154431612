import React from "react";
import styled from "styled-components/macro";

const QuoteContainer = styled.figure`
  height: 100%;
  max-width: 1000px;
  background: ${(props) => props.theme.colors.primaryBackground};
  padding: ${(props) =>
    `${props.theme.spacing.md} ${props.theme.spacing.md} ${props.theme.spacing.xxl} ${props.theme.spacing.md}`};
  color: ${(props) => props.theme.colors.heading};
  box-shadow: ${(props) => props.theme.boxShadow.quote};
  @media (max-width: ${(props) => props.theme.breakPoint.large}) {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 0;
    height: 80%;
    margin: 0;
    box-shadow: none;
  }
`;

const Span = styled.span`
  display: flex;
  justify-content: center;
  height: 50px;
  div {
    color: ${(props) => props.theme.colors.heroBlue};
    font-size: 6rem;
  }
`;

const TextQuote = styled.blockquote`
  text-align: center;
  font-weight: bold;
  font-size: ${(props) => props.theme.fontSize.xl};
`;

const Attribution = styled.figcaption`
  text-align: center;
  font-weight: bold;
  font-size: ${(props) => props.theme.fontSize.md};
`;

export default function Quote({ item }) {
  const { quote, attribution } = JSON.parse(item.data);
  return (
    <QuoteContainer>
      <Span>
        <div>&#8220;</div>
      </Span>

      <TextQuote>{quote}</TextQuote>

      <Attribution>{attribution}</Attribution>
    </QuoteContainer>
  );
}

import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import { Semantic } from "components/SemanticComponents";
import { ContentAlignment } from "components";

const StyledInput = styled.input`
  background: ${(props) =>
    props.disabled === true
      ? props.theme.colors.formDisabled
      : props.theme.colors.transparent};
  border-radius: ${(props) => props.theme.borderRadius.sm};
  border: 1px solid ${(props) => props.theme.colors.inactive};
  display: inline-block;
  height: 40px;
  margin-top: ${(props) => props.theme.spacing.md};
  padding: 1rem;
  position: relative;
  width: 100%;
  z-index: 10;

  &:invalid {
    border: ${(props) => (props.pattern ? "solid red 3px" : null)};
  }
`;

const StyledLabel = styled.label`
  color: ${(props) => props.theme.colors.heroBlue};
  display: inline-block;
  font-size: ${(props) => props.theme.fontSize.md};
  top: 18px;
  position: relative;
  width: 100%;
  z-index: 1;
`;

const StyledMessage = styled.span`
  display: block;
  user-select: none;
  color: #f22f46;
  width: 100%;

  margin-bottom: 1rem;
  bottom: 9px;
`;

export default function TextInput({
  type,
  name,
  label,
  onChange,
  value,
  disabled,
  tabIndex,
  pattern,
  required,
  autocomplete,
  message,
  readOnly,
}) {
  return (
    <Semantic.Div flexGrow="1">
      <StyledLabel value={value} htmlFor={name}>
        {label}
      </StyledLabel>
      <StyledInput
        id={name}
        name={name}
        type={type}
        label={label}
        onChange={onChange}
        value={value}
        disabled={disabled}
        tabIndex={tabIndex}
        pattern={pattern}
        required={required}
        autocomplete={autocomplete}
        readOnly={readOnly}
      />
      <ContentAlignment>
        {message ? (
          <StyledMessage htmlFor={name}>{message}</StyledMessage>
        ) : (
          " "
        )}
      </ContentAlignment>
    </Semantic.Div>
  );
}

TextInput.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  tabIndex: PropTypes.string,
  pattern: PropTypes.string,
  autocomplete: PropTypes.string,
};

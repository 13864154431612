export function animateTab(lab, setAnimateIn, setCurrentLab, setAnimateOut) {
  setAnimateOut(true);

  setTimeout(() => {
    setCurrentLab(lab);
  }, 200);

  setTimeout(() => {
    setAnimateIn(true);
    setAnimateOut(false);
  }, 600);
}

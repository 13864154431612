import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import { Semantic } from "components/SemanticComponents";

const StyledInput = styled.input`
  border-radius: ${(props) => props.theme.borderRadius.sm};
  border: 1px solid ${(props) => props.theme.colors.inactive};
  display: inline-block;
  height: 40px;
  margin-top: ${(props) => props.theme.spacing.md};
  padding: 1rem;
  position: relative;
  width: 100%;
  z-index: 10;
  font-family: inherit;
`;

const StyledLabel = styled.label`
  color: ${(props) => props.theme.colors.heroBlue};
  display: inline-block;
  font-size: ${(props) => props.theme.fontSize.md};
  padding-left: 0;
  position: relative;
  top: -60px;
  transition: all 0.5s ease-in-out;
  width: 100%;
  z-index: 1;
`;

const StyledRequired = styled.span`
  color: ${(props) => props.theme.colors.errorRed};
`;

export default function DateInput({
  name,
  label,
  onChange,
  value,
  tabIndex,
  required,
}) {
  return (
    <Semantic.Div flexGrow="1">
      <StyledInput
        id={name}
        name={name}
        type="date"
        label={label}
        onChange={onChange}
        value={value}
        tabIndex={tabIndex}
        required={required}
      />
      <StyledLabel value={value} htmlFor={name}>
        {label} {required ? <StyledRequired> (required)</StyledRequired> : ""}
      </StyledLabel>
    </Semantic.Div>
  );
}

DateInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  value: PropTypes.string,
  tabIndex: PropTypes.string,
};

import React from "react";
import styled from "styled-components/macro";

// This handles some aspect ratio calculation
// should suffice for most images
const Container = styled.div`
  display: block;
  width: 100%;
  position: relative;
  height: 0;
  padding: 56.25% 0 0 0;
  overflow: hidden;
`;
const StyledImage = styled.img`
  position: absolute;
  display: block;
  max-width: 100%;
  max-height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
`;

export default function ContentImage({ item }) {
  const { src, alt } = JSON.parse(item.data);

  return (
    <Container>
      <StyledImage src={src} alt={alt} />
    </Container>
  );
}

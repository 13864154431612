import React from "react";
import styled from "styled-components/macro";

const DivWrapper = styled.div`
  @keyframes inAnimation {
    0% {
      opacity: 0;
      max-height: 0px;
    }

    100% {
      opacity: 1;
      max-height: 600px;
    }
  }

  @keyframes outAnimation {
    0% {
      opacity: 1;
      max-height: 600px;
    }

    100% {
      opacity: 0;
      max-height: 0px;
    }
  }
`;

const useDelayUnmount = (isMounted, delayTime) => {
  const [showDiv, setShowDiv] = React.useState(false);
  React.useEffect(() => {
    let timeoutId;
    if (isMounted && !showDiv) {
      setShowDiv(true);
    } else if (!isMounted && showDiv) {
      timeoutId = setTimeout(() => setShowDiv(false), delayTime); //delay our unmount
    }
    return () => clearTimeout(timeoutId); // cleanup mechanism for effects , the use of setTimeout generate a sideEffect
  }, [isMounted, delayTime, showDiv]);
  return showDiv;
};

const AnimateContainer = ({ children, isMounted }) => {
  const showDiv = useDelayUnmount(isMounted, 450);
  const mountedStyle = { animation: "inAnimation 450ms ease-in" };
  const unmountedStyle = {
    animation: "outAnimation 470ms ease-out",
    animationFillMode: "forwards",
  };

  return (
    <React.Fragment>
      {showDiv && (
        <DivWrapper style={isMounted ? mountedStyle : unmountedStyle}>
          {children}
        </DivWrapper>
      )}
    </React.Fragment>
  );
};

export default AnimateContainer;

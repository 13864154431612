import React from "react";
import styled from "styled-components/macro";

const StyledContainer = styled.div`
  display: flex;
  background: ${(props) => props.theme.colors.altBackground};
  margin-top: ${(props) => props.theme.spacing.xs};
`;

const Button = styled.button`
  border: none;
  cursor: pointer;
  background: ${(props) => props.theme.colors.altBackground};
  border-color: transparent;
  box-shadow: ${(props) =>
    props.selected ? `0px 2px 0 0 ${props.theme.colors.heroBlue}` : "none"};
  transition: ${(props) => (props.selected ? ` 0.2s linear` : "none")};
  z-index: 10;
  margin: ${(props) =>
    `${props.theme.spacing.xs} ${props.theme.spacing.xs} 0 ${props.theme.spacing.xs}`};
  padding-bottom: ${(props) => props.theme.spacing.xs};

  &:hover {
    box-shadow: ${(props) => `0px 2px 0 0 ${props.theme.colors.heroBlue}`};
  }
`;

export default function LabsTabBar({ availableLabs, currentLab, changeTab }) {
  return (
    <StyledContainer>
      {availableLabs?.length > 1 &&
        availableLabs?.map((lab, index) => {
          return (
            <Button
              key={index}
              selected={lab === currentLab ? true : false}
              onClick={() => changeTab(lab)}
            >
              {lab}
            </Button>
          );
        })}
    </StyledContainer>
  );
}

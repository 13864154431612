import React from "react";
import styled from "styled-components/macro";
import { Typography } from "components/SemanticComponents";

const FormGroupingContainer = styled.div`
  margin: ${(props) => props.theme.spacing.lg};

  h3 {
    margin-bottom: 0;
  }

  @media (max-width: ${(props) => props.theme.breakPoint.mobile}) {
    margin: ${(props) => `0 ${props.theme.spacing.xs}`};
    h3 {
      margin: ${(props) => `${props.theme.spacing.md} 0 0 0`};
    }
  }
`;

const FormGroupingStyles = styled.div`
  background: ${(props) => props.theme.colors.formGroupingBackground};
  border-radius: ${(props) => props.theme.borderRadius.md};
  box-shadow: 5px 5px 5px 0px #00000010;
  padding: ${(props) => `${props.theme.spacing.md} ${props.theme.spacing.lg}`};

  @media (max-width: ${(props) => props.theme.breakPoint.mobile}) {
    background: ${(props) => props.theme.colors.primaryBackground};
    box-shadow: none;
    padding: ${(props) => `0 ${props.theme.spacing.xs}`};
  }
`;

export default function FormGrouping({ title, children }) {
  return (
    <FormGroupingContainer>
      <Typography.Title level="h3">{title}</Typography.Title>
      <FormGroupingStyles>{children}</FormGroupingStyles>
    </FormGroupingContainer>
  );
}

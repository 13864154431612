import React from "react";
import styled from "styled-components/macro";
import { ReactComponent as EyeOpen } from "assets/icon-view-visible.svg";
import { ReactComponent as EyeClosed } from "assets/icon-view-hidden.svg";

const StyledContainer = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
`;

const StyledInput = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  min-width: 100px;
  padding: 5px;
  height: 40px;
  border-radius: ${(props) => props.theme.borderRadius.sm};
  background: ${(props) => props.theme.colors.primaryBackground};
  z-index: 5;

  ${(props) => {
    switch (props.passwordStrengthColour) {
      case "green":
        return {
          border: `1px solid ${props.theme.colors.heroGreen}`,
        };
      default:
        return {
          border: `1px solid  ${props.theme.colors.body}`,
        };
    }
  }}

  &:focus-within {
    ${(props) => {
      switch (props.passwordStrengthColour) {
        case "red":
          return {
            border: `3px solid ${props.theme.colors.textWarningRed}`,
          };
        case "orange":
          return {
            border: `3px solid ${props.theme.colors.likertOrange}`,
          };
        case "green":
          return {
            border: `3px solid ${props.theme.colors.heroGreen}`,
          };
        default:
          return {
            border: `3px solid  ${props.theme.colors.body}`,
          };
      }
    }}
  }
`;

const StyledRequired = styled.span`
  color: ${(props) => props.theme.colors.errorRed};
`;

const Input = styled.input.attrs((props) => ({}))`
  background: ${(props) =>
    props.disabled === true
      ? props.theme.colors.formDisabled
      : props.theme.colors.primaryBackground};
  flex: 1 0;
  min-width: 100px;
  min-height: 25px;
  font-size: inherit;
  background-color: transparent;
  padding-left: 5px;
  border: 0;
  z-index: 10;
  &:focus {
    outline: none;
    /* ${StyledInput} & {
      border: ${(props) => `2px solid ${props.theme.colors.heroBlue}`};
    } */
  }
`;

const StyledLabel = styled.label`
  margin-top: ${(props) => props.theme.spacing.md};
  color: ${(props) => props.theme.colors.heroBlue};
  display: inline-block;
  font-size: ${(props) => props.theme.fontSize.md};
  top: 18px;
  width: 100%;
`;

const PasswordMeter = styled.span`
  display: inline-block;
  width: 100%;
  vertical-align: middle;
  border-bottom-left-radius: ${(props) => props.theme.border.formInputRadius};
  border-bottom-right-radius: ${(props) => props.theme.border.formInputRadius};
  margin-top: -12px;
  z-index: 1;
  border: none;
`;

const Span = styled.span`
  color: black;
  display: inline-block;
  padding-left: 7px;
  padding-top: 10px;
  padding-bottom: 3px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;

  ${(props) => {
    switch (props.passwordStrengthColour) {
      case "red":
        return {
          background: `${props.theme.colors.textWarningRed}`,
          borderBottomRightRadius: "0px",
          width: "33%",
        };
      case "orange":
        return {
          background: `${props.theme.colors.likertOrange}`,
          borderBottomRightRadius: "0px",
          width: "66%",
        };
      case "green":
        return {
          background: `${props.theme.colors.heroGreen}`,
          width: "100%",
        };
      default:
        return {
          borderBottomRightRadius: "0px",
        };
    }
  }}
`;

const PasswordMessage = styled.span`
  p {
    margin: 0 0 0 2%;
  }
`;

const Icon = styled.button`
  flex: 0 0;
  border: none;
  background: transparent;
`;

const PasswordCheckerStyles = styled.button`
  display: block;
  border: none;
  background: transparent;
  text-decoration: underline;
  color: ${(props) => props.theme.colors.heroBlue};
  margin-left: auto;
  cursor: pointer;
`;

export default function PasswordInputField({
  name = "password",
  label = "Password",
  onChange,
  value,
  tabIndex,
  required,
  autocomplete,
  validation,
  passwordStrengthMessage,
  passwordStrengthColour,
  showPassword,
  showPasswordToggle,
  handlePasswordChecker,
  passwordChecker,
}) {
  return (
    <StyledContainer>
      <StyledLabel value={value} htmlFor={name}>
        {label}
        <StyledRequired> (required)</StyledRequired>
      </StyledLabel>
      <StyledInput passwordStrengthColour={passwordStrengthColour}>
        <Input
          id={name}
          name={name}
          type={showPasswordToggle}
          label={label}
          onChange={onChange}
          value={value}
          tabIndex={tabIndex}
          required={required}
          autocomplete={autocomplete}
          passwordStrengthColour={passwordStrengthColour}
        />

        {showPasswordToggle === "text" ? (
          <Icon onClick={showPassword}>
            <EyeOpen
              aria-live="polite"
              aria-label="Password field displayed as plain text, select me to hide password."
              width="20px"
              height="20px"
              stroke="black"
            />
          </Icon>
        ) : (
          <Icon onClick={showPassword}>
            <EyeClosed
              aria-live="polite"
              aria-label="Password field displays as asterisks, select me to display password as plain text"
              width="20px"
              height="20px"
              stroke="black"
            />
          </Icon>
        )}
      </StyledInput>
      <PasswordMeter passwordStrengthColour={passwordStrengthColour}>
        <Span passwordStrengthColour={passwordStrengthColour}>
          {passwordStrengthMessage}
        </Span>
      </PasswordMeter>
      <PasswordCheckerStyles onClick={handlePasswordChecker}>
        {passwordChecker
          ? "Hide password helper"
          : "Get help creating your password"}
      </PasswordCheckerStyles>

      {passwordChecker ? (
        <PasswordMessage aria-live="polite">
          {validation.map((item, index) => {
            return <p key={index}>{item}</p>;
          })}
        </PasswordMessage>
      ) : null}
    </StyledContainer>
  );
}

import { useReducer } from "react";
import {
  CREATE_USEROBJECT,
  GET_USEROBJECT,
  UPDATE_USEROBJECT,
} from "./actions";

export const reducer = (state, action) => {
  switch (action.type) {
    case CREATE_USEROBJECT:
      return {
        formValues: action.formValues,
      };
    case UPDATE_USEROBJECT:
      const { payload } = action;
      return {
        ...state,
        formValues: {
          ...state.formValues,
          ...payload,
        },
      };
    case GET_USEROBJECT:
      return {
        userObject: action.userObject,
      };
    default:
      return state;
  }
};

export function useUserObjectReducer(initialState) {
  return useReducer(reducer, initialState);
}

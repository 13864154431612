import { useEffect, useRef, useReducer } from "react";
const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;

export const GET = (url, authToken) => {
  const cache = useRef({});

  const initialState = {
    status: "idle",
    error: null,
    data: [],
    statusCode: null,
  };

  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case "FETCHING":
        return { ...initialState, status: "fetching" };
      case "FETCHED":
        return {
          ...initialState,
          status: "fetched",
          data: action.payload,
          statusCode: action.code,
        };
      case "FETCH_ERROR":
        return {
          ...initialState,
          status: "error",
          error: action.payload,
        };
      default:
        return state;
    }
  }, initialState);

  useEffect(() => {
    let cancelRequest = false;
    if (!url || !url.trim()) return;

    const config = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };

    let expiredToken = 401;

    const fetchData = async () => {
      dispatch({ type: "FETCHING" });
      if (cache.current[url]) {
        const data = cache.current[url];
        dispatch({ type: "FETCHED", payload: data });
      } else {
        try {
          const response = await fetch(`${apiEndpoint}${url}`, config);
          if (response.status === expiredToken) return refreshToken(authToken); //=> refreshing token if token has expired
          const data = await response.json();
          cache.current[url] = data;
          if (cancelRequest) return;
          dispatch({ type: "FETCHED", payload: data, code: response.status });
        } catch (error) {
          console.log({ error });
          if (cancelRequest) return;
          dispatch({
            type: "FETCH_ERROR",
            payload: error.message,
          });
        }
      }
    };

    fetchData();

    return function cleanup() {
      cancelRequest = true;
    };
  }, [url, authToken]);

  return state;
};

export function API({ endpoint = "", method = "GET", data = {} }, authToken) {
  const config = {
    method,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
  };
  if (method !== "GET") {
    config.body = JSON.stringify(data);
  }
  return fetch(`${apiEndpoint}${endpoint}`, config)
    .then(checkHttpStatus)
    .then(parseAndPreserveDetails)
    .catch((error) => {
      if (error?.response?.status === 401) {
        console.log("refreshing token");
        refreshToken(authToken);
      }
    });
}

export function updateAvatarPost({ formData, authToken, history }) {
  return fetch(`${apiEndpoint}/user/image`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
    body: formData,
  })
    .then(() => history.push("/account"))
    .catch((error) => {
      console.log(error);
    });
}

export function login({ email = "", password = "" }) {
  const config = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email, password }),
  };

  return fetch(`${apiEndpoint}/auth/authenticate`, config)
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      console.log(error);
    });
}

export function samlAuthorise(key = "") {
  const config = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ key }),
  };

  return fetch(`${apiEndpoint}/saml`, config)
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      console.log(error);
    });
}

export function register({
  first_name = "",
  last_name = "",
  email = "",
  gender = "",
  private_email = "",
  password = "",
  password_confirm = "",
  company_id = "",
  voucher = "",
  use_private_email = false,
  mobile = "",
  persona_id = null,
  leave_date = null,
  rtw_date = null,
  leave_type = "",
  user_agent = window?.navigator?.userAgent ?? "unknown",
  complete = 0,
  position = "",
  social = "",
  social_id = "",
  manager_name = "",
  manager_phone = "",
  manager_email = "",
  voucherAccepted = false,
  termsAndConditions = false,
  saml_token = "",
}) {
  const config = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      first_name,
      last_name,
      email,
      gender,
      private_email,
      password,
      password_confirm,
      company_id,
      mobile,
      voucher,
      use_private_email,
      persona_id,
      leave_date,
      rtw_date,
      leave_type,
      user_agent,
      complete,
      position,
      social,
      social_id,
      manager_name,
      manager_phone,
      manager_email,
      voucherAccepted,
      termsAndConditions,
      saml_token,
    }),
  };

  return fetch(`${apiEndpoint}/auth/register-v2`, config)
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      console.error(error);
    });
}

export function customRegister({
  id = "",
  first_name = "",
  last_name = "",
  email = "",
  gender = "",
  private_email = "",
  password = "",
  password_confirm = "",
  company_id = "",
  voucher = "",
  use_private_email = false,
  mobile = "",
  persona_id = null,
  leave_date = null,
  rtw_date = null,
  leave_type = "",
  user_agent = window?.navigator?.userAgent ?? "unknown",
  complete = 0,
  position = "",
  social = "",
  social_id = "",
  manager_name = "",
  manager_phone = "",
  manager_email = "",
  voucherAccepted = false,
  termsAndConditions = false,
  saml_token = "",
  account_email = "",
  business_unit = "",
  company_name = "",
  preferred_email = "",
}) {
  const config = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      first_name,
      last_name,
      email,
      gender,
      private_email,
      password,
      password_confirm,
      company_id,
      mobile,
      voucher,
      use_private_email,
      persona_id,
      leave_date,
      rtw_date,
      leave_type,
      user_agent,
      complete,
      position,
      social,
      social_id,
      manager_name,
      manager_phone,
      manager_email,
      voucherAccepted,
      termsAndConditions,
      saml_token,
      account_email,
      business_unit,
      company_name,
      preferred_email,
    }),
  };

  return fetch(`${apiEndpoint}/auth/custom-register/user/${id}`, config)
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      console.error(error);
    });
}

export function platinumMemberRegister({
  id = "",
  first_name = "",
  last_name = "",
  email = "",
  gender = "",
  private_email = "",
  password = "",
  password_confirm = "",
  company_id = "",
  voucher = "",
  use_private_email = false,
  mobile = "",
  persona_id = null,
  leave_date = null,
  rtw_date = null,
  leave_type = "",
  user_agent = window?.navigator?.userAgent ?? "unknown",
  complete = 0,
  position = "",
  social = "",
  social_id = "",
  manager_name = "",
  manager_phone = "",
  manager_email = "",
  voucherAccepted = false,
  termsAndConditions = false,
  saml_token = "",
}) {
  const config = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      first_name,
      last_name,
      email,
      gender,
      private_email,
      password,
      password_confirm,
      company_id,
      mobile,
      voucher,
      use_private_email,
      persona_id,
      leave_date,
      rtw_date,
      leave_type,
      user_agent,
      complete,
      position,
      social,
      social_id,
      manager_name,
      manager_phone,
      manager_email,
      voucherAccepted,
      termsAndConditions,
      saml_token,
    }),
  };

  return fetch(
    `${apiEndpoint}/auth/platinum-member-register/user/${id}`,
    config
  )
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      console.error(error);
    });
}

function refreshToken(token) {
  console.log("refreshing token");
  return new Promise((resolve, reject) => {
    let config = {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    fetch(`${apiEndpoint}/auth/renew`, config)
      .then(checkHttpStatus)
      .then((response) => response.json())
      .then((result) => {
        if (result.error === "token_too_old") {
          window.localStorage.removeItem("gp-token");
          return (window.location.href = "/login");
        }
        localStorage.setItem("gp-token", result.token);
        window.location.reload();
        resolve(result.token);
      });
  });
}

export function checkHttpStatus(response) {
  if (response.status === 403) {
    return response;
  }
  if (response.status >= 200 && response.status < 300) {
    return response;
  } else {
    let error = new Error(response.statusText);
    error.response = response;
    console.log("error response", error.response);
    throw error;
  }
}

function parseAndPreserveDetails(response) {
  return new Promise((resolve, reject) => {
    let ok = response.ok;
    let status = response.status;
    response
      .json()
      .then((result) => resolve({ result, ok, status }))
      .catch((error) => {
        if (error?.message.includes("JSON.parse")) {
          resolve({ result: {}, ok, status });
        } else {
          reject();
        }
      });
  });
}

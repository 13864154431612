import PropTypes from "prop-types";
import styled from "styled-components/macro";

const Image = styled.img`
  border-radius: 50%;
  width: ${(props) => (props.size ? props.size + "px" : "auto")};
  height: ${(props) => (props.size ? props.size + "px" : "auto")};
  object-fit: cover;
  margin: 0;
`;

export default function UserAvatar({ children, ...props }) {
  return <Image src={children} {...props} alt="Profile Picture" />;
}

UserAvatar.propTypes = {
  children: PropTypes.any.isRequired,
  size: PropTypes.string,
};

import React from "react";
import PropTypes from "prop-types";
import { useToast } from "context/toast";
import { ToastWrapper } from "components";

export default function Toast({ ...props }) {
  const { closeToast } = useToast();

  React.useEffect(() => {
    const interval = setTimeout(() => {
      if (props.timer !== 0) {
        closeToast(props.id);
      }
    }, props.timer || 3000);
    return () => {
      clearTimeout(interval);
    };
  }, [closeToast, props]);

  return <ToastWrapper {...props} closeToast={closeToast} role="alert" />;
}

Toast.propTypes = {
  type: PropTypes.oneOf(["success", "danger", "info", "warning"]).isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  timer: PropTypes.number,
  closeToast: PropTypes.func,
};

import { useState, useEffect } from "react";
import Routes from "routes/Routes";
import { Loader, Toast, ToastsContainer } from "components";
import { AuthContext } from "context/auth";
import { ToastContext } from "context/toast";
import { LabContext } from "context/labs";
import { FeatureFlagContext } from "context/featureFlags";
import { API } from "helpers/api";

import "theme/font.css";
import { UserStoreProvider } from "utils/GlobalState";

const getUserFromToken = (token) => {
  if (token) {
    return JSON.parse(atob(token.split(".")[1]));
  }
  return null;
};

export default function App() {
  const existingToken = localStorage.getItem("gp-token") || null;
  const lastVisit = localStorage.getItem("gp-last-visit") || null;
  const [authToken, setAuthToken] = useState(existingToken);
  const [toastList, setToastList] = useState([]);
  const [selectedLab, setSelectedLab] = useState(null);
  const [features, setFeatures] = useState(null);

  useEffect(() => {
    if (authToken) {
      API(
        { endpoint: "/feature-flags/for-user/enabled", method: "GET" },
        authToken
      )
        .then((response) => setFeatures(response.result ? response.result : []))
        .catch((error) => {
          console.log("Error encountered", error);
          setFeatures([]);
        });
    } else {
      setFeatures([]);
    }
  }, [authToken]);

  useEffect(() => {
    const date = new Date().toLocaleDateString();
    if (authToken) {
      if (lastVisit !== date) {
        localStorage.setItem("gp-last-visit", date);
        API({ endpoint: "/visits/log", method: "post" }, authToken).catch(
          (error) => {
            console.log(error);
          }
        );
      }
    }
  }, [authToken, lastVisit]);

  const showToast = ({ type, title, message, timer }) => {
    const toastId = Math.floor(Math.random() * 1000 + 1);

    const newToast = {
      id: toastId,
      type: type,
      title: title,
      message: message,
      timer: timer,
    };

    setToastList([...toastList, newToast]);
  };

  const closeToast = (id) => {
    const index = toastList.findIndex((e) => e.id === id);
    toastList.splice(index, 1);

    setToastList([...toastList]);
  };

  const setToken = (data) => {
    localStorage.setItem("gp-token", data);
    setAuthToken(data);
  };

  const setLab = (lab) => {
    setSelectedLab(lab);
  };

  return (
    <AuthContext.Provider
      value={{
        authToken,
        setAuthToken: setToken,
        jwt: getUserFromToken(authToken),
      }}
    >
      <UserStoreProvider>
        <FeatureFlagContext.Provider value={{ features }}>
          <ToastContext.Provider
            value={{ showToast: showToast, closeToast: closeToast }}
          >
            {toastList ? (
              <ToastsContainer>
                {toastList.map((t) => {
                  return (
                    <Toast
                      key={t.id}
                      id={t.id}
                      type={t.type}
                      title={t.title}
                      message={t.message}
                      timer={t.timer}
                    />
                  );
                })}
              </ToastsContainer>
            ) : null}
            {features === null ? (
              <Loader />
            ) : (
              <LabContext.Provider value={{ setLab, selectedLab }}>
                <Routes />
              </LabContext.Provider>
            )}
          </ToastContext.Provider>
        </FeatureFlagContext.Provider>
      </UserStoreProvider>
    </AuthContext.Provider>
  );
}

import React from "react";
import styled from "styled-components/macro";
import PropTypes from "prop-types";

const Container = styled.button`
  border-radius: 20%/50%;
  border: none;
  width: 100px;
  height: 40px;
  margin-right: ${(props) => props.theme.spacing.lg};
  color: ${(props) => (props.selected ? "white" : props.theme.colors.heading)};
  background: ${(props) =>
    props.selected
      ? props.theme.colors.heroBlue
      : props.theme.colors.lightGrey};

  &:hover {
    cursor: pointer;
  }
`;

export default function PillButton({ children, ...props }) {
  return <Container {...props}>{children}</Container>;
}

PillButton.propTypes = {
  children: PropTypes.any.isRequired,
};

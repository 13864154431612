import React from "react";
import styled from "styled-components/macro";
import { PaginationButtons } from "components";
import { useWindowDimensions } from "hooks/windowDimensions";

const Container = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Button = styled.button`
  border: none;
  width: 120px;
  height: 40px;
  color: ${(props) =>
    props.toggle ? props.theme.colors.heroBlue : props.theme.colors.heading};
  background-color: transparent;
  background-image: ${(props) =>
    props.toggle
      ? `linear-gradient(to top, ${props.theme.colors.gradientMedGrey} 0%, ${props.theme.colors.primaryBackground} 100%);`
      : "none"};
  text-decoration: ${(props) => (props.toggle ? "underline" : null)};
  text-decoration-thickness: 2px;
  text-underline-offset: 6px;
  cursor: pointer;
  transition: 600ms cubic-bezier(0.3, 0.7, 0.4, 1);

  &:hover,
  &:focus-visible {
    box-shadow: inset 0 -100px 0 0 ${(props) => props.theme.colors.gradientMedGrey};
  }

  &:active {
    filter: brightness(110%);
  }
`;

export default function ArticlesActivitiesTab({
  toggle,
  pillToggle,
  currentPage,
  dataLimit,
  filteredPosts,
  select,
  goToPreviousPage,
  goToNextPage,
  pageLimit,
  pages,
  setCurrentPage,
}) {
  const { width } = useWindowDimensions();

  return (
    <Container>
      <div>
        <Button
          toggle={toggle}
          onClick={() => {
            pillToggle();
            setCurrentPage(1);
          }}
        >
          Articles
        </Button>

        <Button
          toggle={!toggle}
          onClick={() => {
            pillToggle();
            setCurrentPage(1);
          }}
        >
          Activities
        </Button>
      </div>

      {width > 428 ? (
        // hides the top button for mobile, the base page buttons will remain.
        toggle ? (
          <PaginationButtons
            filteredPosts={filteredPosts}
            pageLimit={pageLimit}
            dataLimit={dataLimit}
            select={select}
            goToPreviousPage={goToPreviousPage}
            goToNextPage={goToNextPage}
            currentPage={currentPage}
            pages={pages}
          />
        ) : null
      ) : null}
    </Container>
  );
}

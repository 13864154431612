import React from "react";
import { Typography, Semantic } from "components/SemanticComponents";

export default function GPPrivacy() {
  return (
    <Semantic.Article>
      <Typography.Title level="h1-body">
        Grace Papers Privacy Statement
      </Typography.Title>

      <Typography.Title level="h2">Privacy Statement</Typography.Title>
      <Typography.Paragraph level="p1">
        The Grace Papers digital product as well as related forums and
        applications (together, the “Website”) are owned and operated by Grace
        Papers Holdings Pty Ltd (ABN 68 104 232 831) of KEW Melbourne (“us”,
        “our”, “we”).
      </Typography.Paragraph>
      <Typography.Paragraph level="p1">
        The following information outlines our Privacy Policy: being the terms
        and conditions of our Website in relation to the collection, use and
        disclosure of your personal information. Personal information is defined
        as per the Privacy Act 1988 (Cth) as being information about an
        identified individual who is reasonable identifiable whether the
        information or opinion is true or not and whether the information or
        opinion is recorded in a material form or not.
      </Typography.Paragraph>
      <Typography.Paragraph level="p1">
        By using our Website or any function of our Service you agree to be
        bound by our Privacy Policy and Terms and Conditions of our Website.
        This Privacy Policy may be amended and appropriate notification will be
        given if this occurs.
      </Typography.Paragraph>
      <Typography.Title level="h3">
        <strong>Collection and Use of Personal Information</strong>
      </Typography.Title>
      <Typography.Title level="h4">Website Visitors</Typography.Title>
      <Typography.Paragraph level="p1">
        You are not required to provide us any information upon visiting our
        Website. This type of usage may collect non-identifying information from
        such as your IP address and query information. This information alone
        cannot usually be used to identify you and its obtainment is lawful.
      </Typography.Paragraph>
      <Typography.Title level="h4">
        Registration and User Profiles
      </Typography.Title>
      <Typography.Paragraph level="p1">
        In signing up and registering to be a part of our Services you will be
        required to enter certain personal information such as your name and
        email. This information will be used and stored by us.
      </Typography.Paragraph>
      <Typography.Paragraph level="p1">
        Upon payment for the Grace Papers Program you also will be required to
        disclose certain payment information. This information may be shared and
        stored by a third party payment processor such as Paypal.
      </Typography.Paragraph>
      <Typography.Title level="h4">User Posted Content</Typography.Title>
      <Typography.Paragraph level="p1">
        The Website, and other associated forums of ours, allows you to post
        content at your own discretion in accordance with our Terms and
        Conditions. This information may be collected and used at our discretion
        subject to our Privacy Policy.
      </Typography.Paragraph>
      <Typography.Title level="h4">
        Use of Personal Information
      </Typography.Title>
      <Typography.Paragraph level="p1">
        We will not sell or transfer any personally identifiable information to
        third parties unlawfully. Personal information may be used by us to;
      </Typography.Paragraph>
      <Typography.Paragraph level="p1">
        • enhance our ability to provide you with goods and services,
        <br />• contact you or respond to your queries,
        <br />• display, update and maintain website content,
        <br />• tailor your experience of the website,
        <br />• send you information about future events, regular email
        communication or newsletters,
        <br />• conduct quizzes and surveys, use survey and quiz responses, and
        compile survey and quiz reports; and
        <br />• compile data for preparation of reports for internal use by us
        and external presentation to third parties.
      </Typography.Paragraph>
      <Typography.Paragraph level="p1">
        Additionally, we may be required to give your personal information, by
        law, to law enforcement if there has been a legal violation or a
        violation of our Terms and Conditions or Privacy Policy.
      </Typography.Paragraph>
      <Typography.Title level="h3">
        <strong>Testimonials</strong>
      </Typography.Title>
      <Typography.Paragraph level="p1">
        We may, with prior, express, written consent, collect and publish
        customer testimonials on our Website or through other platforms. If a
        user would like their approved Testimonial to be removed they may
        request this by contacting us at legal@gracepapers.com.au.
      </Typography.Paragraph>
      <Typography.Title level="h4">Communications</Typography.Title>
      <Typography.Paragraph level="p1">
        Providing us with your contact information waives your rights to file
        complaints concerning unsolicited communications from us. If you desire
        to unsubscribe from certain communications please contact us at
        legal@gracepapers.com.au and we will endeavor to remove you from such
        communication platforms.
      </Typography.Paragraph>
      <Typography.Title level="h3">
        <strong>Disclosure of Information</strong>
      </Typography.Title>
      <Typography.Title level="h4">User Information</Typography.Title>
      <Typography.Paragraph level="p1">
        We generally do not disclosure information to third parties unless it is
        authorized or required by law. Your use of our Website and Service may
        result in our use of contractors and web hosts in order to ensure the
        operation, maintenance or improvement of our Website and operations.
        This therefore means your authorization to such dissemination to third
        parties is permitted.
      </Typography.Paragraph>
      <Typography.Paragraph level="p1">
        This disclosure will be on a confidential basis to agents that we use in
        the ordinary operation of our business. We will only disclose your
        information to the extent required for the limited purpose of the third
        party providing the services. We accept no liability for the actions of
        any of these third parties and you must take direct legal action against
        these third parties should they act unlawfully.
      </Typography.Paragraph>
      <Typography.Paragraph level="p1">
        Although we endeavour to protect the information we hold about you, we
        cannot guarantee our systems and stored data will be free from third
        party interception. No method of online transmission or electronic
        storage is completely secure. Therefore we do not ensure the security or
        privacy of your personal information. Further enquires can be sent to us
        at legal@gracepapers.com.au.
      </Typography.Paragraph>
      <Typography.Title level="h4">User Posted Content</Typography.Title>
      <Typography.Paragraph level="p1">
        User posted content will also be disclosed to the public and we accept
        no liability for its dissemination. Users profile access and passwords
        are the sole responsibility of the user to keep private, therefore all
        user posting are assumed by us to be legitimate.
      </Typography.Paragraph>
      <Typography.Title level="h4">
        Users belonging to an Enterprise License
      </Typography.Title>
      <Typography.Paragraph level="p1">
        Grace Papers will provide activity reporting to it’s Enterprise clients
        on an aggregate basis. Where the client requests, we will identify the
        user and progress. For expectant users, details will not be provided
        unless the user has proceeded beyond Step 3.
      </Typography.Paragraph>
      <Typography.Title level="h4">Other Data</Typography.Title>
      <Typography.Paragraph level="p1">
        We may disclose aggregated data to third parties assisting us for the
        purpose of marketing or user experience assessment. This information
        will not identify you individually.
      </Typography.Paragraph>
      <Typography.Title level="h4">Data Storage</Typography.Title>
      <Typography.Paragraph level="p1">
        User Data is stored in databases and servers mainly in Australia. Should
        the information server be located outside of Australia we will takes
        such steps as are reasonable to ensure that the overseas recipient does
        not breach the Australian Privacy Principles.
      </Typography.Paragraph>
      <Typography.Title level="h4">
        Your Ability to Access, Change and Remove Your Information
      </Typography.Title>
      <Typography.Paragraph level="p1">
        It is your responsibility to advise us of any changes to your personal
        details to ensure our records remain up to date. Majority of these
        personal detail changes are open for you to undertake on your profile.
        You will not be permitted to access all information submitted to our
        Website. If you wish to review, change or delete any information you
        have submitted, and are unable to do so yourself online, please contact
        us on legal@gracepapers.com.au and we will endeavour to respond to your
        request.
      </Typography.Paragraph>
      <Typography.Paragraph level="p1">
        If you do not wish to be present in our data collection please make
        contact with us to secure your removal from the records.
      </Typography.Paragraph>
      <Typography.Title level="h3">
        <strong>Cookies and Tracking</strong>
      </Typography.Title>
      <Typography.Paragraph level="p1">
        We make use of cookies and other technologies of tracking for
        authentication purposes and to tailor the site to user preferences.
        Users can control the use of cookies at the individual browser level.
        Some of our cookies may be accessible by third parties. The rejection of
        cookies will still enable the use of our Website however some features
        may be limited.
      </Typography.Paragraph>
      <Typography.Title level="h3">
        <strong>Advertisements</strong>
      </Typography.Title>
      <Typography.Paragraph level="p1">
        Our Website may contain authorised advertisements from third parties.
        The use of cookies and Web beacons to collect non-personally
        identifiable information about your internet activity to provide
        targeted advertising is permitted by you. If you wish to opt-out of this
        targeted advertising operation please contact us at
        legal@gracepapers.com.au.
      </Typography.Paragraph>
      <Typography.Title level="h3">
        <strong>Social Media Connectivity</strong>
      </Typography.Title>
      <Typography.Paragraph level="p1">
        Our Website links to, and uses, social media online. The social media
        platform may collect certain information from visitors and users and
        your interactions with these features are governed by the privacy policy
        of the social media company.
      </Typography.Paragraph>
      <Typography.Title level="h3">
        <strong>Forums</strong>
      </Typography.Title>
      <Typography.Paragraph level="p1">
        Our Website offers blogs and forums for discussion purposes that may be
        accessible by the public. Any information that you post or provide in
        these areas will be able to be read, collected and used by others. To
        request removal of your posted information contact us at
        legal@gracepapers.com.au and we will best endeavor to remove the
        content.
      </Typography.Paragraph>
      <Typography.Title level="h3">
        <strong>Linking Websites Privacy</strong>
      </Typography.Title>
      <Typography.Paragraph level="p1">
        Our Website may post links to external third party websites over which
        we have no control of their terms and conditions and privacy policy. We
        accept no liability for the conduct of these third parties.
      </Typography.Paragraph>
      <Typography.Title level="h3">
        <strong>Security of Information</strong>
      </Typography.Title>
      <Typography.Paragraph level="p1">
        We endeavour to secure all your personal and payment information,
        including any sensitive information in accordance with our statutory
        obligations, however, due to the nature of the internet and online
        systems we make no guarantees as to your informational security. Use of
        computer and virus protections is recommended for your own benefit.
      </Typography.Paragraph>
      <Typography.Paragraph level="p1">
        You are solely accountable for maintaining the security of your
        passwords and/or account information. When your subscription expires, we
        will continue to retain your personal information so that you may access
        it. You can manually delete this information to ensure its security.
      </Typography.Paragraph>
      <Typography.Title level="h3">
        <strong>Further Concerns</strong>
      </Typography.Title>
      <Typography.Paragraph level="p1">
        If you have any other concerns about how we handle your personal
        information or require further guidance contact us on
        legal@gracepapers.com.au.
      </Typography.Paragraph>
      <Typography.Title level="h3">
        <strong>Privacy Policy Amendments</strong>
      </Typography.Title>
      <Typography.Paragraph level="p1">
        We reserve the right to alter our Privacy Policy, subject to legal
        restrictions. The date of the policy will be listed as to inform Users
        and Visitors of the change in policy. If you do not agree to our Privacy
        Policy you must immediately cease using our Website and notify us of
        such refusal at legal@gracepapers.com.au.
      </Typography.Paragraph>
    </Semantic.Article>
  );
}

import React from "react";
import styled from "styled-components/macro";

const RadioWrapper = styled.div`
  display: flex;
  height: auto;
  flex-direction: column;

  label {
    text-align: center;
    padding-bottom: 5px;
  }
`;

const Input = styled.input.attrs({ type: "radio" })`
  width: 27px;
  height: 27px;
`;

export default function SimpleRadioButton({
  name,
  id,
  children,
  defaultChecked,
}) {
  return (
    <RadioWrapper>
      <label htmlFor={id}>{children}</label>
      <Input type="radio" name={name} id={id} defaultChecked={defaultChecked} />
    </RadioWrapper>
  );
}

import React from "react";
import { Typography, Semantic } from "components/SemanticComponents";

export default function GPTerms() {
  return (
    <Semantic.Article>
      <Typography.Title level="h1-body">Grace Papers Terms</Typography.Title>

      <Typography.Title level="h2">
        Acceptance of our terms and conditions
      </Typography.Title>
      <Typography.Paragraph level="p1">
        The Grace Papers digital product, including our website at
        www.gracepapers.com.au as well as related forums and applications
        (together, the “Website”) are owned and operated by Grace Papers
        Holdings (ABN 68 104 232 831) of KEW Melbourne (“us”, “our”, “we”).The
        following terms constitute an agreement (“Agreement”) between Grace
        Papers and you as a user and apply to the use of the Website and the
        Grace Papers Service (the “Service”).
      </Typography.Paragraph>
      <Typography.Paragraph level="p1">
        By using our Website or any function of our Service you agree to be
        bound by this Agreement and our Privacy Policy. This Agreement may be
        amended and appropriate notification will be given if this occurs. If
        you breach these terms and conditions we may immediately suspend or
        terminate your account, without refund, and take appropriate legal
        action. Actions of yours that cause concern for Grace Papers’, the
        Website’s or the Service’s reputation or ability to function will also
        be grounds for suspension or termination.
      </Typography.Paragraph>
      <Typography.Title level="h3">
        <strong>Use of the Website</strong>
      </Typography.Title>
      <h3>User Accounts</h3>
      <Typography.Paragraph level="p1">
        In order to access certain features of the Website and Services you will
        be required to become a registered user of the Website by creating a
        personal account. The information used to create and maintain this
        account must be true, accurate, current and complete.
      </Typography.Paragraph>
      <Typography.Paragraph level="p1">
        Upon registration we will assign you a password and account information
        for your use of the Website. A log in entry to your account will always
        be reason for us to presume authorized access; we take no responsibility
        for unauthorized accessing of your account. You are responsible to
        immediately notify us of any unauthorized use of your account or
        threatened breach of our Website’s security.
      </Typography.Paragraph>
      <Typography.Title level="h3">
        <strong>Our License to Grant to You</strong>
      </Typography.Title>
      <Typography.Paragraph level="p1">
        Through your use of the Website and the Grace Papers service we grant
        you a personal, non-exclusive, revocable, limited license to use our
        Service and access the Website. Resale or sharing of this license is not
        permitted. This license may be revoked if this license is used contrary
        to any conditions stipulated in these terms or in any other
        communication.
      </Typography.Paragraph>
      <Typography.Title level="h3">License Duration</Typography.Title>
      <Typography.Paragraph level="p1">
        Once your User Account has been purchased you will have access to
        program content for a period of 24 months. Thereafter, you will only be
        able to access your own profile and information. It is your
        responsibility to maintain, delete or alter this personal profile.
      </Typography.Paragraph>
      <Typography.Paragraph level="p1">
        If for any reasonable reason you need to hold and/or extend this
        timeframe please contact us at legal@gracepapers.com.au and we will
        endeavour to assist this extension.
      </Typography.Paragraph>
      <Typography.Title level="h3">
        <strong>External Services Promotion</strong>
      </Typography.Title>
      <Typography.Paragraph level="p1">
        Advertising or promotion by members or third parties on the Website
        without our prior written consent is not permitted. If in our opinion
        such unauthorised activity has taken place, suspension or termination
        the membership or the banning a third party involved may result at our
        discretion.
      </Typography.Paragraph>
      <Typography.Title level="h3">
        <strong>Security of Information</strong>
      </Typography.Title>
      <Typography.Paragraph level="p1">
        We take all reasonable steps to preserve the security of your
        information provided to us on the Website in accordance with our Privacy
        Policy and our statutory obligations. However, we cannot ensure the
        security of any information you transmit to us through online or other
        means. Therefore, all information you transmit to us is at your own
        risk.
      </Typography.Paragraph>
      <Typography.Title level="h4">Payment and GST</Typography.Title>
      <Typography.Paragraph level="p1">
        The prices of services are listed on the Website and unless otherwise
        stated are in Australian Dollars. For any purchases on the Website you
        will be required to provide us with your credit card information. This
        payment information may be shared with third party payment processors
        such as Paypal.
      </Typography.Paragraph>
      <Typography.Paragraph level="p1">
        Unless otherwise stated prices on the Website are inclusive of GST as
        per the Act <em>A New Tax System (Goods and Services Tax) Act</em>1999
        (Cth). If there is a failure to charge you GST in accordance with your
        local laws and ordinances you accept responsibility for these amounts.
      </Typography.Paragraph>
      <Typography.Paragraph level="p1">
        Please acknowledge we are not able to provide you with advice in regards
        to your tax liabilities.
      </Typography.Paragraph>
      <Typography.Title level="h4">Refunds</Typography.Title>
      <Typography.Paragraph level="p1">
        Refunds will be available at our discretion and in limited circumstances
        subject to legislation.
      </Typography.Paragraph>
      <Typography.Title level="h3">
        <strong>Career Guidance Information</strong>
      </Typography.Title>
      <Typography.Paragraph level="p1">
        Information provided on the Website is taken from research and advice
        from a variety of sources including the Australian Human Rights
        Commission, relevant legislation, our own research, professional
        experience and personal experiences. All content is for informational
        and educational purposes only and does not constitute legal advice or
        direction. Information on the Website should not substitute for legal or
        professional advice you have received.
      </Typography.Paragraph>
      <Typography.Paragraph level="p1">
        We endeavor to provide current and beneficial advice to users, however,
        before relying on any information or guidance on the Website you should
        ensure you evaluate the information’s accuracy, completeness and
        relevance to you. Further, you should seek further advice where
        appropriate; especially in relation to your own particular
        circumstances.
      </Typography.Paragraph>
      <Typography.Title level="h3">
        <strong>Quality and Content</strong>
      </Typography.Title>
      <Typography.Title level="h3">Quality of the Website</Typography.Title>
      <Typography.Paragraph level="p1">
        We strive to make the Website functioning error-free or uninterrupted,
        however, complications could result in temporary unavailability. We
        reserve the right to alter our Website at any time under certain
        conditions. This may be necessary for security, intellectual property or
        other reasons. We are not required to explain such modifications.
      </Typography.Paragraph>
      <Typography.Paragraph level="p1">
        Receipt of emails or other online communications cannot be guaranteed
        and we cannot ensure that files you download through the Website or
        related mail is free of contamination or destructive features.
      </Typography.Paragraph>
      <Typography.Paragraph level="p1">
        The delivery of video messages is dependent upon third party providers
        and although we strive to provide alternatives, cannot take
        responsibility for this.
      </Typography.Paragraph>
      <Typography.Title level="h3">
        Information Provided on the Website
      </Typography.Title>
      <Typography.Paragraph level="p1">
        All information provided by us on the Website is provided in good faith.
        We strive to ensure our information is current and accurate however, to
        the extent permitted by law, we do not make guarantees of any specific
        results from use of this Website or the information service provided
        through it.
      </Typography.Paragraph>
      <Typography.Paragraph level="p1">
        Access to any specific consultant or professional in the Website,
        including Prue Gilbert, in person, or on-line is not guaranteed.
      </Typography.Paragraph>
      <Typography.Title level="h3">
        <strong>Testimonials</strong>
      </Typography.Title>
      <Typography.Paragraph level="p1">
        Testimonials on the Website are applicable to the individuals depicted
        and may not reflect the results that you may achieve.
      </Typography.Paragraph>
      <Typography.Title level="h4">Third Party Content</Typography.Title>
      <Typography.Paragraph level="p1">
        To the extent allowed by law we do not accept responsibility for any
        loss or damage caused (including through negligence) which you may
        directly or indirectly suffer in connection with the conduct of any
        third party on this Website. This includes but is not limited to other
        members, advertisers or third party users.
      </Typography.Paragraph>
      <Typography.Paragraph level="p1">
        It remains your responsibility to evaluate the accuracy and relevance of
        any information posted on our Website. Where content displayed on the
        Website contains opinions or suggestions of third parties, including
        advertisers, we do not endorse such content. We offer no guarantees of
        the accuracy or completeness of that content, and we will not accept
        liability for loss or damage arising from your reliance upon such
        content.
      </Typography.Paragraph>
      <Typography.Paragraph level="p1">
        You may report misconduct of other users or third parties to us at
        grace-admin@gracepapers.com.au. This will be investigated and we will
        take action thought to be appropriate at our sole discretion.
      </Typography.Paragraph>
      <Typography.Title level="h3">
        <strong>Limitation of Liability</strong>
      </Typography.Title>
      <h3>Indirect and Consequential Loss</h3>
      <Typography.Paragraph level="p1">
        Subject to consumer legislation any condition or warranty which would
        otherwise be implied into these terms is excluded. To the extent
        permitted by law, in no event will our Website or services, or any of
        our respective officers, directors, employees, shareholders, affiliates,
        agents, successors or assigns be liable to you or anyone else for any
        direct, indirect, special, punitive, incidental or consequential damages
        arising out of the use, inability to use, or the results of this website
        or linked websites.
      </Typography.Paragraph>
      <Typography.Title level="h4">
        Terms Implied by Law and Sole Remedy
      </Typography.Title>
      <Typography.Paragraph level="p1">
        With the exception of Consumer Guarantees outlined in the{" "}
        <em>Competition and Consumer Act</em>&nbsp;2010 and any equivalent state
        or territory legislation we exclude:
      </Typography.Paragraph>
      <ul dir="ltr">
        <li>
          any term, condition or warranty that may otherwise be implied by
          custom, law or statute;
        </li>
        <li>
          any liability for loss caused by our negligence; and any liability for
          Consequential Loss (discussed above)
        </li>
      </ul>
      <Typography.Paragraph level="p1">
        Where legislation implies any condition or warranty not excluded by our
        terms that condition or warranty will be deemed included by our
        liability will be limited for a breach of that condition or warranty to,
        at our option to any one or more of the following:
      </Typography.Paragraph>
      <ul dir="ltr">
        <li>
          in the case of goods to replace the goods or supply equivalent goods,
          to repair the goods, the payment of the cost of replacing the goods or
          of acquiring equivalent goods or the payment of having the goods
          repaired.
        </li>
        <li>
          in the case of services to the supply of the services again or the
          payment of the costs of having the services supplied again.
        </li>
      </ul>
      <Typography.Paragraph level="p1">
        In any event of a problem with this website or its content, the sole
        remedy to you is to cease using this website.
      </Typography.Paragraph>
      <Typography.Paragraph level="p1">
        For jurisdictions that do not allow us to limit our liability and there
        is a conflict with conditions of the Website then our liability is
        limited to the smallest extent possible by law.
      </Typography.Paragraph>
      <Typography.Title level="h4">Total Liability</Typography.Title>
      <Typography.Paragraph level="p1">
        To the extent permitted by law, our total liability in respect of all
        claims in connection with this agreement will be the total sum of all
        fees paid or payable by you under this agreement up until and including
        the date the cause of action accrued.
      </Typography.Paragraph>
      <Typography.Title level="h4">
        Material and Content is not Legal Advice
      </Typography.Title>
      <Typography.Paragraph level="p1">
        The material on the Website and all associate forums of ours is designed
        to provide information of a general nature. We endeavor to provide
        guidelines, directions and steps to enhance the confidence of women
        returning to work and the strength of employee/employer relationships,
        however, this Website does not cover the entirety of the field. This
        should be considered in relying on its use.
      </Typography.Paragraph>
      <Typography.Paragraph level="p1">
        The contents of this Website do not constitute legal advice or other
        professional advice in relation to any particular matters you may have.
        For such advice you should seek legal counsel. Further, this website is
        not intended to create a solicitor-client relationship between you and
        us or any of our directors, employees or contractors.
      </Typography.Paragraph>
      <Typography.Title level="h4">Indemnity</Typography.Title>
      <Typography.Paragraph level="p1">
        You agree to defend and indemnify the Website, its officers, directors,
        employees and agents, from and against any and all claims, damages,
        obligations, losses, liabilities, costs or debt, and expenses (including
        but not limited to legal expenses) arising from your use of the Website
        and service, your violation of these Terms, and any claim of a third
        party against you or your content.
      </Typography.Paragraph>
      <Typography.Paragraph level="p1">
        This defence and indemnification will survive this Agreement and your
        use of the Website and Service.
      </Typography.Paragraph>
      <Typography.Title level="h3">
        <strong>Intellectual Property and User Content</strong>
      </Typography.Title>
      <Typography.Title level="h4">Intellectual Property</Typography.Title>
      <Typography.Paragraph level="p1">
        Website materials, logos, text, video, templates, software and general
        content is owned or licensed to us unless otherwise indicated. All
        rights are reserved in relation to this material. Without prior express
        written permission it is not permitted by the Agreement for you to
        engage in the use, copy or distribution of anything contained within
        this Website or Service.
      </Typography.Paragraph>
      <Typography.Paragraph level="p1">
        All names, logos and trademarks of third parties on this Website are the
        property of their respective owners and we do not grant any rights to
        commercial use or distribution of names, logos or trademarks without the
        express agreement of the relevant owners.
      </Typography.Paragraph>
      <Typography.Paragraph level="p1">
        By uploading, transmitting, posting or otherwise making available
        material via the Website or on any of our associated platforms you grant
        us a non-exclusive, worldwide, royalty-free, perpetual license to use,
        reproduce, edit and exploit the material in any form. Waiver of your
        moral rights as defined by the <em>Commonwealth Copyright Act</em>
        &nbsp;1968 is also condition in this Agreement.
      </Typography.Paragraph>
      <Typography.Title level="h4">User Content</Typography.Title>
      <Typography.Paragraph level="p1">
        In allowing users to post content or upload materials to the Website and
        its associated mediums, user content is available for you and others to
        view. This content is manageable by the posting user in their ability to
        delete their posts. However, we are not responsible for how others use
        your posted content and encourage you to take care before posting. Users
        should be aware that their posted content may be downloaded and we then
        may not be able to control its further distribution or publication. To
        the extent permitted by law, we are not liable to you for any loss or
        damage arising as a result or in connection with release (whether
        authorized or not) of your posted content.
      </Typography.Paragraph>
      <Typography.Paragraph level="p1">
        As part of our right to monitor and review User Content we may delete
        User content for any reason without prior notice. We will use best
        endeavors to ensure that the Website content is properly managed,
        however we do not have the obligation to monitor and review User
        Content.
      </Typography.Paragraph>
      <Typography.Paragraph level="p1">
        We will use our best endeavours to ensure users’ information in their
        profile maintains limited to their own personal access if they select
        this. We are not responsible for how others use your information or
        content if they access it and accept no such liability to this. We
        otherwise refer to and rely upon our Privacy Policy.
      </Typography.Paragraph>
      <Typography.Title level="h4">Linking</Typography.Title>
      <Typography.Paragraph level="p1">
        You may link to our website without our consent, however, you accept
        responsibility for any such linking. Alteration of the Website’s content
        is not permitted. Our request for removal of linking must be immediately
        obliged.
      </Typography.Paragraph>
      <Typography.Paragraph level="p1">
        We may link to or display the content of third parties. We do not
        endorse, approve or offer warranty to such content or websites. Your use
        of such links is not under our responsibility to cover any damage or
        loss, however arising, which results from your use of the link.
      </Typography.Paragraph>
      <Typography.Title level="h4">Termination</Typography.Title>
      <Typography.Paragraph level="p1">
        We may in our absolute discretion refuse registration or suspend or
        terminate membership at any time for any reason subject to this
        Agreement.
      </Typography.Paragraph>
      <Typography.Paragraph level="p1">
        All provisions of this Agreement, which by their nature should survive
        termination, will; including but not limited to, ownership provisions
        and indemnity provisions.
      </Typography.Paragraph>
      <Typography.Title level="h3">
        <strong>Resultant Legal Proceedings</strong>
      </Typography.Title>
      <Typography.Title level="h4">Choice of Law</Typography.Title>
      <Typography.Paragraph level="p1">
        The law of Victoria and the Commonwealth shall govern this Agreement.
        The offer and acceptance of this contract is deemed to have occurred in
        Victoria, Australia.
      </Typography.Paragraph>
      <Typography.Paragraph level="p1">
        Forum of Dispute
        <br />
        <br />
        Any dispute arising from or relating to this Agreement will be heard
        solely by a court of competent jurisdiction nearest to the state of
        Victoria, Australia.
      </Typography.Paragraph>
      <Typography.Paragraph level="p1">
        Bringing a dispute in a manner other than in accordance with this
        section will mean we may move to have it dismissed at your expense of
        legal fees, court costs and disbursements.
        <br />
        <br />
        The unsuccessful party in any dispute arising from or relating to this
        agreement will be responsible for the reimbursement of the successful
        party’s reasonable attorney’s fees, court costs and disbursements.
      </Typography.Paragraph>
      <Typography.Title level="h4">Force Majeure</Typography.Title>
      <Typography.Paragraph level="p1">
        We are not responsible to you for anything that we may otherwise be if
        it is the result of events beyond our control such as but not limited to
        acts of God, war, riots, terrorism or crime.
      </Typography.Paragraph>
      <Typography.Title level="h4">Severability</Typography.Title>
      <Typography.Paragraph level="p1">
        If a provision of our Agreement is void or unenforceable it may be
        severed without affecting the enforceability of other terms of the
        Agreement. If provisions of this agreement are deemed to conflict we
        shall have the right to elect which provision remains in force.
      </Typography.Paragraph>
      <Typography.Title level="h4">Non-Waiver</Typography.Title>
      <Typography.Paragraph level="p1">
        We reserve all rights permitted to us under this agreement and under
        applicable law. Non-enforcement of any provision by us shall not be
        constructed as a waiver of our rights to enforce that same provision
        under the same or different circumstances at any time in the future.
      </Typography.Paragraph>
      <Typography.Title level="h4">Assignment</Typography.Title>
      <Typography.Paragraph level="p1">
        Your rights and/or your obligations may not be assigned under this
        Agreement to any other party without prior written consent. We may
        assign our rights and/or obligations under this Agreement to any other
        party at our discretion.
      </Typography.Paragraph>
      <Typography.Title level="h4">Entire Agreement</Typography.Title>
      <Typography.Paragraph level="p1">
        This Agreement is deemed to be the entire Agreement between you and
        Grace Papers.
      </Typography.Paragraph>
    </Semantic.Article>
  );
}

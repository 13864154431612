import React from "react";
import styled from "styled-components/macro";

const TooltipText = styled.div``;

const TooltipBox = styled.div`
  position: absolute;
  top: calc(100% + 5px);
  visibility: hidden;
  color: transparent;
  background-color: transparent;
  width: fit-content;
  padding: ${(props) => `${props.theme.spacing.sm} ${props.theme.spacing.sm}`};
  border-radius: ${(props) => props.theme.borderRadius.sm};
  transition: visibility 0.5s, color 0.5s, background-color 0.5s, width 0.5s,
    padding 0.5s ease-in-out;
  &:before {
    content: "";
    width: 0;
    height: 0;
    left: 40px;
    top: -10px;
    position: absolute;
    border: 10px solid transparent;
    transform: rotate(135deg);
    transition: border 0.3s ease-in-out;
  }
  p {
    margin: 0;
  }
`;

const TooltipCard = styled.div`
  position: relative;
  & ${TooltipText}:focus-within + ${TooltipBox} {
    visibility: visible;
    color: #fff;
    background-color: rgba(0, 0, 0, 1);
    width: fit-content;
    padding: ${(props) =>
      `${props.theme.spacing.sm} ${props.theme.spacing.sm}`};
    z-index: 50;
    &:before {
      border-color: transparent transparent rgba(0, 0, 0, 1) rgba(0, 0, 0, 1);
    }
  }

  & ${TooltipText}:hover + ${TooltipBox} {
    visibility: visible;
    color: #fff;
    background-color: rgba(0, 0, 0, 1);
    width: fit-content;
    padding: ${(props) =>
      `${props.theme.spacing.sm} ${props.theme.spacing.sm}`};
    z-index: 50;
    &:before {
      border-color: transparent transparent rgba(0, 0, 0, 1) rgba(0, 0, 0, 1);
    }
  }
`;

export default function Tooltip({ items, children }) {
  return (
    <React.Fragment>
      <TooltipCard>
        <TooltipText>{children}</TooltipText>
        <TooltipBox>
          {items.map((item, index) => {
            return <p key={index}>{item}</p>;
          })}
        </TooltipBox>
      </TooltipCard>
    </React.Fragment>
  );
}

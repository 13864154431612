import React from "react";
import { ReactComponent as Clear } from "assets/clear.svg";
import styled from "styled-components/macro";

const StyledInput = styled.div`
  display: flex;
  align-items: center;
  max-width: inherit;
  height: 40px;
  box-sizing: border-box;
  margin-right: ${(props) => props.theme.spacing.sm};
  border: ${(props) => `1px solid ${props.theme.colors.body}`};
  border-radius: ${(props) => props.theme.borderRadius.md};
  background: ${(props) => props.theme.colors.primaryBackground};

  &:focus-within {
    border: ${(props) => `2px solid ${props.theme.colors.heroBlue}`};
  }

  @media (max-width: ${(props) => props.theme.breakPoint.tablet}) {
    button {
      margin-right: auto;
    }
  }

  @media (max-width: ${(props) => props.theme.breakPoint.mobile}) {
    margin: 0;
    width: 100%;
  }
`;

const Input = styled.input.attrs((props) => ({
  type: "text",
}))`
  min-height: 25px;
  width: 80%;
  background-color: transparent;
  border: none;
  &:focus {
    outline: none;
  }
`;

const Icon = styled.button.attrs((props) => ({
  type: "button",
}))`
  display: flex;
  background: transparent;
  border: none;
  border-radius: ${(props) => props.theme.borderRadius.md};
  width: 20%;
  justify-content: end;
`;

export default function SearchInput({
  handleInputChange,
  clearSearch,
  value,
  name,
}) {
  return (
    <StyledInput>
      <Input
        aria-label="Search content"
        onChange={handleInputChange}
        placeholder="Search"
        value={value}
        name={name}
      />
      <Icon onClick={clearSearch} onKeyPress={clearSearch}>
        <Clear
          aria-label="Clear search content"
          width="20px"
          height="20px"
          stroke="black"
        />
      </Icon>
    </StyledInput>
  );
}

import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";

const StyledContainer = styled.div`
  width: inherit;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
`;

const StyledInput = styled.input`
  background: ${(props) =>
    props.disabled === true
      ? props.theme.colors.formDisabled
      : props.theme.colors.primaryBackground};
  border-radius: ${(props) => props.theme.borderRadius.sm};
  border: ${(props) => props.theme.border.formBorder};
  display: inline-block;
  padding: ${(props) => props.theme.spacing.sm};
  text-align: center;
  caret-color: black;
  font-size: ${(props) => props.theme.fontSize.xxxl};
  letter-spacing: ${(props) => props.theme.spacing.xxs};

  &:valid {
    border: ${(props) =>
      props.validate === false && props.value !== ""
        ? `solid ${props.theme.colors.heroGreen} ${props.theme.border.inputValidateBorderThin}}`
        : null};
  }

  &:focus-within {
    outline: none;
    //=> you would think that the false statement of invalid would be the same as valid, but you'd be wrong
    &:invalid {
      border: ${(props) =>
        props.validate === false && props.value !== ""
          ? null
          : `solid ${props.theme.colors.errorRed} ${props.theme.border.inputValidateBorderThick}`};
    }
    &:valid {
      border: ${(props) =>
        props.validate === false && props.value !== ""
          ? `solid ${props.theme.colors.heroGreen} ${props.theme.border.inputValidateBorderThick}`
          : `solid ${props.theme.colors.errorRed} ${props.theme.border.inputValidateBorderThick}`};
    }
  }
`;

const ValidationLabel = styled.div`
  color: ${(props) =>
    props.validate === false && props.value !== ""
      ? props.theme.colors.likertDGreen
      : props.theme.colors.errorRed};
  border-bottom-left-radius: ${(props) => props.theme.border.formInputRadius};
  border-bottom-right-radius: ${(props) => props.theme.border.formInputRadius};
  padding-left: ${(props) => props.theme.paddingLeft.small};
`;

export default function CodeInput({
  type,
  name,
  pattern,
  onChange,
  onBlur,
  value,
  disabled,
  required,
  validate,
  beforeText,
  afterText,
  placeholder,
  title,
  onInvalid,
  maxlength,
  minlength,
  autocomplete,
}) {
  return (
    <StyledContainer>
      <StyledInput
        id={name}
        aria-label={name}
        name={name}
        type={type}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        disabled={disabled}
        required={required}
        validate={validate}
        beforeText={beforeText}
        afterText={afterText}
        placeholder={placeholder}
        title={title}
        onInvalid={onInvalid}
        valid={validate}
        maxLength={maxlength}
        minLength={minlength}
        autoComplete={autocomplete}
        role="textbox"
        pattern={pattern}
      />

      <ValidationLabel
        validate={validate}
        aria-live="polite"
        aria-label={
          validate === false && value !== ""
            ? "input field validation completed"
            : null
        }
      >
        {validate === false && value !== "" ? afterText : beforeText}
      </ValidationLabel>
    </StyledContainer>
  );
}

CodeInput.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  pattern: PropTypes.string,
  maxlength: PropTypes.string,
  minlength: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  inputValidation: PropTypes.bool,
  title: PropTypes.string,
  onInvalid: PropTypes.func,
  autocomplete: PropTypes.string,
  onpaste: PropTypes.func,
};

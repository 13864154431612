import React from "react";
import styled from "styled-components/macro";
import { LessonWrapperHeader, LessonWrapperContainer } from "components/Lesson";
import { Semantic, Typography } from "components/SemanticComponents";

const Grid = styled.div`
  display: grid;
  width: fit-content;
  height: fit-content;
  margin: ${(props) => ` 0 auto ${props.theme.spacing.xl} auto`};
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  grid-template-areas:
    "V1 V2 V3"
    "V4 V5 V6"
    "V7 V8 V9";
`;

const VisionBoard = styled.div`
  width: 160px;
  height: 160px;
  @media (max-width: ${(props) => props.theme.breakPoint.mobile}) {
    width: 70px;
    height: 70px;
  }
`;

const Selector = styled.input`
  border: none;
  width: 100%;
  height: 100%;
  cursor: pointer;

  &:hover {
    border: ${(props) => `3px solid ${props.theme.colors.heroBlue}`};
  }

  &:focus-within {
    border: ${(props) => `3px dotted ${props.theme.colors.heroBlue}`};
  }
`;

const Picture = styled.picture`
  margin-right: ${(props) => props.theme.spacing.sm};
  @media (max-width: ${(props) => props.theme.breakPoint.mobile}) {
    display: none;
  }
`;

const Source = styled.source``;

const Img = styled.img`
  width: 160px;
  height: 160px;
  @media (max-width: ${(props) => props.theme.breakPoint.mobile}) {
    width: 70px;
    height: 70px;
  }
`;

const SelectedDisplay = styled.div`
  display: flex;
  transition: opacity 0.4s;
  opacity: ${(props) => {
    if (props.animateOut) {
      return 0;
    }
    if (props.animateIn) {
      return 1;
    }
  }};
  h3 {
    margin: ${(props) => ` 0 auto ${props.theme.spacing.sm} auto`};
  }
  p {
    margin: 0;
  }
`;

const TitleSpacer = styled.div`
  display: flex;
  h2 {
    margin: ${(props) => `0 auto ${props.theme.spacing.md} auto`};
  }
`;

export default function ProfessionalVisionContainer({
  professionalVision,
  handleAnimate,
  animateIn,
  animateOut,
  visionImage,
  visionTitle,
  visionText,
}) {
  return (
    <Semantic.Article>
      <LessonWrapperHeader
        title="Professional Vision"
        attribute={professionalVision?.lesson?.pillar?.title}
        data={professionalVision?.lesson?.pillar?.id}
      />

      <LessonWrapperContainer>
        <TitleSpacer>
          <Typography.Title level="h2">Your Vision Board</Typography.Title>
        </TitleSpacer>
        <Grid>
          {professionalVision?.data?.slice(0, 9).map((vision, index) => {
            return (
              <VisionBoard key={index} style={{ gridArea: vision }}>
                <Selector
                  src={vision["V" + String(index + 1)]?.data?.image?.url}
                  alt={"Vision Number: V" + String(index + 1)}
                  type="image"
                  onClick={() => {
                    handleAnimate(vision["V" + String(index + 1)]);
                  }}
                />
              </VisionBoard>
            );
          })}
        </Grid>

        <SelectedDisplay animateOut={animateOut} animateIn={animateIn}>
          {visionImage === "" ? null : (
            <Picture>
              <Source srcset={visionImage} />
              <Img src={visionImage} alt="your vision image" />
            </Picture>
          )}

          <Semantic.Div>
            <Typography.Title level="h3">
              {visionTitle ? visionTitle : "Select an image to see your vision"}
            </Typography.Title>

            {visionText ?? (
              <Typography.Paragraph level="p2">
                {visionText}
              </Typography.Paragraph>
            )}
          </Semantic.Div>
        </SelectedDisplay>

        <Semantic.HR />

        <Typography.Title level="h4">Your reflection</Typography.Title>
        <Typography.Paragraph level="p2">
          {professionalVision !== false
            ? professionalVision?.data[9]?.["Reflection"]?.data?.text
            : null}
        </Typography.Paragraph>
      </LessonWrapperContainer>
    </Semantic.Article>
  );
}

import React, { createContext, useContext } from "react";
import { useUserObjectReducer } from "./reducers";

const StoreContext = createContext();

const { Provider } = StoreContext;

const UserStoreProvider = ({ value = {}, ...props }) => {
  const [state, dispatch] = useUserObjectReducer({
    formValues: {
      id: "",
      first_name: "",
      last_name: "",
      email: "",
      gender: "",
      private_email: "",
      password: "",
      password_confirm: "",
      mobile: "",
      company_id: "",
      ceo_message: "",
      use_private_email: false,
      voucher: "",
      voucherAccepted: false,
      termsAndConditions: false,
      persona_id: "",
      leave_type: "",
      leave_date: "",
      rtw_date: "",
      user_agent: window?.navigator?.userAgent ?? "unknown",
      complete: 0,
      position: "",
      social: "",
      social_id: "",
      manager_name: "",
      manager_phone: "",
      manager_email: "",
      requireDates: false,
      business_unit: "",
      saml_token: "",
      account_email: "",
      saml_company_id: "",
      preferred_email: "",
    },
    userObject: {},
  });

  return <Provider value={{ state, dispatch }} {...props} />;
};

const useStoreContext = () => {
  return useContext(StoreContext);
};

export { UserStoreProvider, useStoreContext };

import { AllyCard, ResourceCard } from "components";
import { Typography, Semantic } from "components/SemanticComponents";

const select = () => {
  return;
};

export default function Resources({ data }) {
  return (
    <Semantic.Div height="max-content">
      <Typography.Title level="h2">Articles</Typography.Title>

      <ResourceCard>
        {data?.map((card) => {
          return (
            <AllyCard
              data={card}
              select={select}
              link={`/blogpost/${card.slug}`}
            />
          );
        })}
      </ResourceCard>
    </Semantic.Div>
  );
}

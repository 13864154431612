import React from "react";
import {
  EmpowermentRadioButton,
  Semantic,
  Typography,
} from "components/SemanticComponents";
import styled from "styled-components/macro";

const RadioWrapper = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  justify-content: space-between;
  align-items: flex-start;

  @media (max-width: ${(props) => props.theme.breakPoint.tablet}) {
    flex-direction: column;
    align-items: center;
  }
`;

const HR = styled.hr`
  border: 1px solid ${(props) => props.theme.colors.heroBlue};
  width: 10%;
`;

const TextWrapper = styled.div`
  text-align: center;
  h2 {
    font-size: ${(props) => props.theme.fontSize.xl};
    font-weight: bold;
    line-height: ${(props) => props.theme.lineHeight.xxxl};
  }
  p {
    font-size: ${(props) => props.theme.fontSize.md};
    line-height: ${(props) => props.theme.lineHeight.xxl};
  }
`;

const InvalidRadioGroup = styled.div`
  background: ${(props) =>
    props.inValidRadio === "show invalid"
      ? props.theme.colors.toastErrorBackground
      : ""};
  color: ${(props) =>
    props.inValidRadio === "show invalid"
      ? props.theme.colors.errorRed
      : `transparent`};
`;

export default function EmpowermentRadioComponent({
  handleSelectChange,
  verifyChecked,
  responseObject,
  inValidRadio,
}) {
  return (
    <Semantic.Fieldset
      border="none"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      alignItems="center"
      width="100%"
    >
      {responseObject?.empowermentQuestion?.text ? (
        <TextWrapper>
          <Typography.Paragraph level="p">
            {responseObject?.empowermentQuestion?.text}
          </Typography.Paragraph>
        </TextWrapper>
      ) : null}
      <HR />
      {responseObject?.empowermentQuestion?.title ? (
        <TextWrapper>
          <Typography.Title level="h2">
            {responseObject?.empowermentQuestion?.title}
          </Typography.Title>
        </TextWrapper>
      ) : null}

      <RadioWrapper>
        {responseObject?.questionOptionItems?.map((option, index) => {
          return (
            <EmpowermentRadioButton
              key={option.id}
              id={option.id}
              name="radio-option"
              checked={verifyChecked(option.option_choice_value)}
              value={option.option_choice_value}
              onChange={(e) => handleSelectChange(e)}
              required={true}
              inValidRadio={inValidRadio}
            >
              {option.option_choice_name}
            </EmpowermentRadioButton>
          );
        })}
      </RadioWrapper>
      <InvalidRadioGroup inValidRadio={inValidRadio}>
        Please select one of the options!
      </InvalidRadioGroup>
    </Semantic.Fieldset>
  );
}

import React from "react";
import styled from "styled-components/macro";

const GlassStyles = styled.div`
  background: ${(props) => props.theme.colors.glassGrey};
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 10px;
  margin-bottom: ${(props) => props.theme.spacing.sm};
  padding: ${(props) => props.theme.spacing.sm};
  width: 100%;
  h1 {
    margin: 0;
  }
`;

export default function GlassBackground({ children }) {
  return <GlassStyles>{children}</GlassStyles>;
}

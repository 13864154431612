import PropTypes from "prop-types";
import styled from "styled-components/macro";

const Image = styled.img`
  max-width: 100%;
  height: auto;
  object-fit: cover;
  margin: 0;
`;

export default function PersonaImage({ children, ...props }) {
  return <Image src={children} {...props} alt="Persona Screen Cover" />;
}

PersonaImage.propTypes = {
  children: PropTypes.any.isRequired,
  size: PropTypes.string,
};

import React from "react";
import styled from "styled-components/macro";
import { theme } from "theme";
import { Loader } from "components";
import {
  RadioButton,
  Semantic,
  Typography,
} from "components/SemanticComponents";

const Container = styled.div`
  display: flex;

  @media (max-width: ${(props) => props.theme.breakPoint.mobile}) {
    flex-direction: column;
  }
`;

export default function RadioComponent({ handleSelectChange, data }) {
  return (
    <Semantic.Fieldset
      border="none"
      onChange={(e) => handleSelectChange(e)}
      background={`${theme.colors.primaryBackground}`}
    >
      {data.title ? (
        <React.Fragment>
          <Typography.Title level="h1-body">
            <legend>{data.title}</legend>
          </Typography.Title>
          <Container>
            {data.radioOptions.map((option, index) => {
              return (
                <RadioButton
                  name="radioComponent"
                  id={index}
                  colour={option.colour}
                  value={option.value}
                >
                  {option.name}
                </RadioButton>
              );
            })}
          </Container>
        </React.Fragment>
      ) : (
        <Loader />
      )}
    </Semantic.Fieldset>
  );
}

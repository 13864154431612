import React from "react";
import {
  ContentImage,
  ContentLink,
  ContentVideo,
  DragNDrop,
  Heading,
  ImagePicker,
  LongQuestion,
  Markdown,
  Quote,
  RadioSelect,
  RadioLandscape,
  ShortQuestion,
  Text,
} from "components/Lesson";
import { MultiAnswerCheckbox, RenderJSON } from "components";

export default function Item({ data, fetchAnswer, updateAnswer }) {
  const itemType = data?.item_type?.item_type;
  const componentMap = {
    dnd: DragNDrop,
    heading: Heading,
    image_picker: ImagePicker,
    image: ContentImage,
    json: RenderJSON,
    link: ContentLink,
    long_question: LongQuestion,
    markdown: Markdown,
    quote: Quote,
    radio: RadioSelect,
    radio_landscape: RadioLandscape,
    short_question: ShortQuestion,
    text: Text,
    video: ContentVideo,
    checkboxes: MultiAnswerCheckbox,
  };
  const ItemComponent = componentMap[itemType] || componentMap["json"];
  return (
    <ItemComponent
      item={data}
      fetchAnswer={fetchAnswer}
      updateAnswer={updateAnswer}
    />
  );
}

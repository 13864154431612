import React from "react";
import styled from "styled-components/macro";

const RadioWrapper = styled.div`
  margin: 0.5rem 0;
  display: flex;

  width: 100%;
  height: auto;
  justify-content: ${(props) =>
    props.layout === "portrait" ? "flex-start" : "space-between"};

  flex-direction: ${(props) =>
    props.layout === "portrait" ? "row" : "column"};
  align-items: center;

  @media (max-width: ${(props) => props.theme.breakPoint.mobile}) {
    height: 100%;
  }
`;

const Label = styled.label`
  display: flex;
  width: 90%;
  text-align: center;
  justify-content: ${(props) =>
    props.layout === "portrait" ? "flex-start" : "center"};
`;

const Input = styled.input.attrs({ type: "radio" })`
  appearance: none;
  width: 27px;
  height: 27px;
  border-radius: 50%;
  padding: 2px;
  margin-top: 0;
  margin-left: 0;
  margin-bottom: ${(props) =>
    props.layout === "portrait" ? "0" : props.theme.spacing.xxs};
  margin-right: ${(props) =>
    props.layout === "portrait" ? props.theme.spacing.xxs : "0"};
  border: ${(props) => `2px solid ${props.theme.colors.heroBlue}`};

  &:hover,
  &:focus {
    transition: ease-in-out 200ms;
    box-shadow: ${(props) => `0 0px 4px ${props.theme.colors.heroBlue}`};
  }

  &:focus {
    &::before {
      background: ${(props) => props.theme.colors.heroBlue};
    }
  }

  &::before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }

  &:checked {
    &::before {
      background: ${(props) => props.theme.colors.heroBlue};
    }
  }
  &:invalid {
    border: ${(props) =>
      (props.inValidRadio === "show invalid") === true
        ? `2px solid ${props.theme.colors.errorRed}`
        : ""};
  }
`;

export default function RadioButton({
  name,
  id,
  colour,
  children,
  layout,
  checked,
  value,
  onChange,
  required,
  inValidRadio,
}) {
  return (
    <RadioWrapper layout={layout}>
      <Input
        layout={layout}
        type="radio"
        name={name}
        id={id}
        colour={colour}
        checked={checked}
        value={value}
        onChange={onChange}
        required={required}
        inValidRadio={inValidRadio}
      />
      <Label htmlFor={id} layout={layout}>
        {children}
      </Label>
    </RadioWrapper>
  );
}

import React from "react";
import { theme } from "theme";
import styled from "styled-components/macro";

const Container = styled.div`
  display: block;
  background: ${(props) => {
    if (props.type === "success") {
      return theme.colors.toastSuccessBackground;
    } else if (props.type === "danger") {
      return theme.colors.toastErrorBackground;
    } else if (props.type === "info") {
      return theme.colors.toastInfoBackground;
    } else if (props.type === "warning") {
      return theme.colors.toastWarningBackground;
    }
  }};
  color: ${(props) => {
    if (props.type === "success") {
      return theme.colors.toastSuccessFontColour;
    } else if (props.type === "danger") {
      return theme.colors.toastErrorFontColour;
    } else if (props.type === "info") {
      return theme.colors.toastInfoFontColour;
    } else if (props.type === "warning") {
      return theme.colors.toastWarningFontColour;
    }
  }};
  border: solid 4px
    ${(props) => {
      if (props.type === "success") {
        return theme.colors.toastSuccess;
      } else if (props.type === "danger") {
        return theme.colors.errorRed;
      } else if (props.type === "info") {
        return theme.colors.heroBlue;
      } else if (props.type === "warning") {
        return theme.colors.heroYellow;
      }
    }};
  padding: ${(props) => props.theme.spacing.sm};
  width: 300px;
  border-radius: ${(props) => props.theme.spacing.sm};
  z-index: 9999;
  box-shadow: ${(props) => props.theme.boxShadow.toast};
  transition: transform 0.5s ease-in-out;
  animation: toastIn 0.7s;

  p {
    margin: 0;
    font-size: ${(props) => props.theme.fontSize.sm};
  }

  @keyframes toastIn {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(0);
    }
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${(props) => props.theme.spacing.sm};
  h1 {
    margin: 0;
    font-size: ${(props) => props.theme.fontSize.md};
    text-transform: uppercase;
  }
`;

const Button = styled.button`
  border: solid 1px
    ${(props) => {
      if (props.type === "success") {
        return theme.colors.toastSuccessFontColour;
      } else if (props.type === "danger") {
        return theme.colors.toastErrorFontColour;
      } else if (props.type === "info") {
        return theme.colors.toastInfoFontColour;
      } else if (props.type === "warning") {
        return theme.colors.toastWarningFontColour;
      }
    }};
  border-radius: 5px;
  background: none;
  color: ${(props) => {
    if (props.type === "success") {
      return theme.colors.toastSuccessFontColour;
    } else if (props.type === "danger") {
      return theme.colors.toastErrorFontColour;
    } else if (props.type === "info") {
      return theme.colors.toastInfoFontColour;
    } else if (props.type === "warning") {
      return theme.colors.toastWarningFontColour;
    }
  }};
  float: right;
`;

export default function ToastWrapper({ ...props }) {
  return (
    <Container {...props}>
      <TitleWrapper
        role="alert"
        aria-label={`action alert: type - ${props.title}, message - ${props.message}`}
      >
        <h1>{props.title}</h1>
        <Button {...props} onClick={() => props.closeToast(props.id)}>
          X
        </Button>
      </TitleWrapper>
      <p>{props.message}</p>
    </Container>
  );
}

import React, { useEffect } from "react";
import styled from "styled-components/macro";
import PropTypes from "prop-types";
import { Semantic, Typography } from "components/SemanticComponents";

const NOT_SAVED = "\u26A0 not saved";
const SAVED = "\u2713 saved";
const SAVING = "saving...";
const LOADING = "\u2026 loading, please wait";

const VisionBoardImagePickerFormStyles = styled.form`
  width: 100%;
  padding-top: ${(props) => props.theme.spacing.xs};
  align-self: start;
`;

const Status = styled.div`
  ::after {
    display: flex;
    justify-content: flex-end;
    margin-bottom: ${(props) => props.theme.spacing.xs};
    content: "${(props) => props.saveStatus}";
  }
`;

const StyledArea = styled.textarea`
  display: inline-block;
  width: 100%;
  padding: ${(props) => props.theme.spacing.xs};
  resize: none;
  font-size: ${(props) => props.theme.fontSize.md};
  margin: ${(props) => `${props.theme.spacing.xs} 0 0 0`};
  font-family: inherit;
`;

const Fieldset = styled.fieldset`
  border: none;
  max-width: 900px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  align-content: stretch;
  justify-content: center;
  gap: ${(props) => props.theme.spacing.xs};
  margin: 0 auto;

  [type="radio"] {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
  }

  [type="radio"] + img {
    width: 160px;
    height: 160px;
    cursor: pointer;
    object-fit: cover;
    padding: ${(props) => props.theme.spacing.xs};
    margin-bottom: 5px;
    margin-right: 5px;
    @media (max-width: ${(props) => props.theme.breakPoint.mobile}) {
      width: 95px;
      height: 95px;
    }
  }

  [type="radio"]:focus-within + img,
  [type="radio"]:hover + img:hover {
    outline: 3px dotted ${(props) => props.theme.colors.heroBlue};
  }

  [type="radio"]:checked + img {
    outline: 3px solid ${(props) => props.theme.colors.heroBlue};
  }
`;

const CompletionMessage = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.colour};
  padding: ${(props) => props.theme.spacing.xs};

  h4 {
    margin: ${(props) =>
      props.noHeadingMargin ? "0" : `0 0 ${props.theme.spacing.xs} 0`};
  }

  p {
    margin: 0;
  }
`;

const CompletedOptions = styled.div`
  margin: ${(props) =>
    `${props.theme.spacing.xs} 0 0 ${props.theme.spacing.xs}`};
`;

export default function ImagePicker({ item, updateAnswer, fetchAnswer }) {
  const [loaded, setLoaded] = React.useState(false);
  const [saveStatus, setSaveStatus] = React.useState(LOADING);
  const [image, setImage] = React.useState(null);
  const [text, setText] = React.useState(null);
  const [incompleteFields, setIncompleteFields] = React.useState(null);
  const { id: questionId } = item;
  const { title, images } = JSON.parse(item.data);

  const refresh = React.useCallback(() => {
    setSaveStatus(LOADING);
    fetchAnswer(questionId).then((response) => {
      setImage(response?.result?.data?.image);
      setText(response?.result?.data?.text);
      setLoaded(true);
    });
  }, [fetchAnswer, questionId]);

  React.useEffect(() => {
    refresh();
  }, [refresh]);

  useEffect(() => {
    if (
      image === undefined ||
      image === null ||
      text === undefined ||
      text === null ||
      text === ""
    ) {
      setIncompleteFields(true);
    } else {
      setIncompleteFields(false);
    }
  }, [image, text]);

  const handleImageChange = (event) => {
    const newImage = images.find(
      (img) => Number(img.id) === Number(event.target.id)
    );
    setImage(newImage);
  };

  useEffect(() => {
    if (loaded) {
      setSaveStatus(SAVING);
      const delayDebounceFn = setTimeout(() => {
        updateAnswer(questionId, { image, text })
          .then(() => {
            setSaveStatus(SAVED);
          })
          .catch((e) => {
            setSaveStatus(NOT_SAVED);
            console.log(e);
          });
      }, 500);

      return () => clearTimeout(delayDebounceFn);
    } else {
      setSaveStatus(LOADING);
    }
  }, [image, text, updateAnswer, questionId, loaded]);

  let fallbackAlt =
    "All images should contain custom written alt texts, if you are receiving this message please reach out to us with the step you are at, so we can identify the missing alt text and correct it";

  return (
    <React.Fragment>
      <Semantic.Div maxWidth="100%" margin="0 auto">
        <VisionBoardImagePickerFormStyles>
          <Semantic.Label>
            <label
              for={"vision-text-id"}
              ariaLabel="this textarea is for the images that have just been described to you"
            >
              <Typography.Title level="h3">{title}</Typography.Title>
            </label>

            <Fieldset>
              {images.map((radioImage, index) => (
                <label key={index}>
                  <input
                    id={radioImage.id}
                    type="radio"
                    name={`img-${radioImage.id}`}
                    value={radioImage.id ? radioImage.id : ""}
                    checked={Number(radioImage.id) === Number(image?.id)}
                    onChange={handleImageChange}
                  />
                  <img
                    src={radioImage?.url}
                    height="100"
                    width="100"
                    alt={
                      radioImage?.altText ? radioImage?.altText : fallbackAlt
                    }
                  />
                </label>
              ))}
            </Fieldset>
            <Status saveStatus={saveStatus}>
              <StyledArea
                name={"vision-text-id"}
                id={"vision-text-id"}
                rows={10}
                value={text ? text : ""}
                onChange={(e) => setText(e.target.value)}
                disabled={!loaded}
              />
            </Status>
          </Semantic.Label>
        </VisionBoardImagePickerFormStyles>

        {incompleteFields === true ? (
          <CompletionMessage colour="#fce7c0">
            <Typography.Title level="h4">
              To see your Vision Board on the Professional Vision page, each
              vision needs to completed.
            </Typography.Title>
            <Typography.Paragraph>
              You can save and return at any time to complete your Professional
              Vision.
            </Typography.Paragraph>

            <CompletedOptions>
              {image === undefined || image === null ? (
                <Typography.Paragraph>
                  - Image hasn't been selected
                </Typography.Paragraph>
              ) : null}

              {text === undefined || text === null || text === "" ? (
                <Typography.Paragraph>
                  - Vision field hasn't been completed.
                </Typography.Paragraph>
              ) : null}
            </CompletedOptions>
          </CompletionMessage>
        ) : (
          <CompletionMessage colour="#e7f3e7" noHeadingMargin>
            <Typography.Title level="h4">
              This vision has been completed.
            </Typography.Title>
          </CompletionMessage>
        )}
      </Semantic.Div>
    </React.Fragment>
  );
}

ImagePicker.propTypes = {
  item: PropTypes.shape({
    area: PropTypes.string,
    data: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
  }).isRequired,
  updateAnswer: PropTypes.func.isRequired,
  fetchAnswer: PropTypes.func.isRequired,
};

import React from "react";
import { Branding, ContentAlignment } from "components";
import {
  Button,
  Semantic,
  EmphasisLink,
  TextInput,
  Typography,
  RegistrationCardDiv,
} from "components/SemanticComponents";
import styled from "styled-components/macro";

const PasswordResetFormStyles = styled.form``;

export default function ForgotPasswordForm({
  password,
  confirmPassword,
  loading,
  handleSubmit,
  handlePasswordChange,
  handleConfirmPasswordChange,
}) {
  return (
    <Semantic.Section display="grid" height="100vh" placeItems="center">
      <RegistrationCardDiv>
        <Branding />
        <Typography.Title level="h2">Set your new password</Typography.Title>
        <Typography.Paragraph>
          Password must contain at least 8 characters, one uppercase, one
          lowercase, one digit and one special character
        </Typography.Paragraph>
        <PasswordResetFormStyles onSubmit={handleSubmit}>
          <TextInput
            disabled={loading}
            name="password"
            label="Password"
            value={password}
            onChange={handlePasswordChange}
            pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W).{8,128}$"
            type="password"
            autocomplete="new-password"
          />
          <TextInput
            disabled={loading}
            name="confirm-password"
            label="Confirm Password"
            value={confirmPassword}
            type="password"
            onChange={handleConfirmPasswordChange}
            autocomplete="new-password"
          />
          <Button
            block
            onClick={handleSubmit}
            disabled={password !== confirmPassword}
          >
            Set Password
          </Button>
          <ContentAlignment align="center">
            <EmphasisLink to="/login">Back to login</EmphasisLink>
          </ContentAlignment>
        </PasswordResetFormStyles>
      </RegistrationCardDiv>
    </Semantic.Section>
  );
}

import React from "react";
import styled from "styled-components/macro";
import { YouTubeEmbed, VimeoEmbed } from "components/Lesson";

const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: space-around;
`;

export default function ContentVideo({ item }) {
  const componentMap = {
    youtube: YouTubeEmbed,
    vimeo: VimeoEmbed,
  };

  const { source, video_id: videoId } = JSON.parse(item.data);

  const EmbedComponent = componentMap[source];
  return (
    <Container>
      <EmbedComponent videoId={videoId} />
    </Container>
  );
}

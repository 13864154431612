import React from "react";
import styled from "styled-components/macro";

const RadioWrapper = styled.div`
  margin: 0.5rem 0;
  display: flex;
  width: 100%;
  height: auto;
  justify-content: ${(props) =>
    props.layout === "portrait" ? "flex-start" : "space-between"};

  flex-direction: ${(props) =>
    props.layout === "portrait" ? "row" : "column"};
  align-items: center;

  @media (max-width: ${(props) => props.theme.breakPoint.mobile}) {
    height: 100%;
  }
`;

const Label = styled.label`
  display: flex;
  width: 90%;
  justify-content: ${(props) =>
    props.layout === "portrait" ? "flex-start" : "center"};
`;

const Input = styled.input.attrs({ type: "radio" })`
  appearance: none;
  width: 27px;
  height: 27px;
  border-radius: 50%;
  padding: 2px;
  margin-top: 0;
  margin-left: 0;
  margin-bottom: ${(props) =>
    props.layout === "portrait" ? "0" : props.theme.spacing.sm};
  margin-right: ${(props) =>
    props.layout === "portrait" ? props.theme.spacing.sm : "0"};
  ${(props) => {
    switch (props.colour) {
      case "red":
        return {
          border: `2px solid ${props.theme.colors.likertRed}`,
        };
      case "orange":
        return {
          border: `2px solid ${props.theme.colors.likertOrange}`,
        };
      case "yellow":
        return {
          border: `2px solid ${props.theme.colors.likertYellow}`,
        };
      case "lGreen":
        return {
          border: `2px solid ${props.theme.colors.likertLGreen}`,
        };
      case "dGreen":
        return {
          border: `2px solid ${props.theme.colors.likertDGreen}`,
        };
      default:
        return { border: `2px solid ${props.theme.colors.heroBlue}` };
    }
  }}

  &:hover,
  &:focus {
    transition: ease-in-out 200ms;
    ${(props) => {
      switch (props.colour) {
        case "red":
          return {
            boxShadow: `0 0px 4px ${props.theme.colors.likertRed}`,
          };
        case "orange":
          return {
            boxShadow: `0 0px 4px ${props.theme.colors.likertOrange}`,
          };
        case "yellow":
          return {
            boxShadow: `0 0px 4px ${props.theme.colors.likertYellow}`,
          };
        case "lGreen":
          return {
            boxShadow: `0 0px 4px ${props.theme.colors.likertLGreen}`,
          };
        case "dGreen":
          return {
            boxShadow: `0 0px 4px ${props.theme.colors.likertDGreen}`,
          };
        default:
          return {
            boxShadow: `0 0px 4px ${props.theme.colors.heroBlue}`,
          };
      }
    }}
  }

  &:focus {
    &::before {
      ${(props) => {
        switch (props.colour) {
          case "red":
            return {
              background: props.theme.colors.likertRed,
            };
          case "orange":
            return {
              background: props.theme.colors.likertOrange,
            };
          case "yellow":
            return {
              background: props.theme.colors.likertYellow,
            };
          case "lGreen":
            return {
              background: props.theme.colors.likertLGreen,
            };
          case "dGreen":
            return {
              background: props.theme.colors.likertDGreen,
            };
          default:
            return { background: props.theme.colors.heroBlue };
        }
      }}
    }
  }

  &::before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }

  &:checked {
    &::before {
      ${(props) => {
        switch (props.colour) {
          case "red":
            return {
              background: props.theme.colors.likertRed,
            };
          case "orange":
            return {
              background: props.theme.colors.likertOrange,
            };
          case "yellow":
            return {
              background: props.theme.colors.likertYellow,
            };
          case "lGreen":
            return {
              background: props.theme.colors.likertLGreen,
            };
          case "dGreen":
            return {
              background: props.theme.colors.likertDGreen,
            };
          default:
            return { background: props.theme.colors.heroBlue };
        }
      }}
    }
  }
`;

export default function RadioButton({
  name,
  id,
  colour,
  children,
  layout,
  defaultChecked,
}) {
  return (
    <RadioWrapper layout={layout}>
      <Input
        layout={layout}
        type="radio"
        name={name}
        id={id}
        colour={colour}
        defaultChecked={defaultChecked}
      />
      <Label htmlFor={id} layout={layout}>
        {children}
      </Label>
    </RadioWrapper>
  );
}

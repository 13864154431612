import { EmpowermentTip } from "components";
import { Typography, List } from "components/SemanticComponents";

const components = {
  a: "a",
  blockquote: "blockquote",
  code: "code",
  del: "del",
  h1: ({ node, ...props }) => (
    <Typography.Title level="h1-body">{props.children}</Typography.Title>
  ),
  h2: ({ node, ...props }) => (
    <Typography.Title level="h2">{props.children}</Typography.Title>
  ),
  h3: ({ node, ...props }) => (
    <Typography.Title level="h3">{props.children}</Typography.Title>
  ),
  h4: ({ node, ...props }) => (
    <Typography.Title level="h4">{props.children}</Typography.Title>
  ),
  h5: ({ node, ...props }) => (
    <Typography.Title level="h5">{props.children}</Typography.Title>
  ),
  h6: ({ node, ...props }) => (
    <Typography.Title level="h6">{props.children}</Typography.Title>
  ),
  hr: "hr",
  img: "img",
  li: "li",
  ol: ({ node, ...props }) => <List.OL>{props.children}</List.OL>,
  p: ({ node, ...props }) => {
    if (props.className === "empowerment") {
      return <EmpowermentTip>{props.children}</EmpowermentTip>;
    } else {
      return (
        <Typography.Paragraph level="p2">{props.children}</Typography.Paragraph>
      );
    }
  },
  pre: "pre",
  strong: "strong",
  table: "table",
  tbody: "tbody",
  td: "td",
  tr: "tr",
  thead: "thead",
  ul: ({ node, ...props }) => <List.UL>{props.children}</List.UL>,
};

export default components;

import React from "react";
import {
  Button,
  Semantic,
  Typography,
  RegistrationCardDiv,
} from "components/SemanticComponents";
import { Branding, CodeInput, ContentAlignment } from "components";
import styled from "styled-components/macro";

const VerifyEmailFormStyles = styled.form``;

export default function VerifyEmailForm({
  postEmailVerify,
  codeHandlers,
  stateObj,
  prevStep,
}) {
  return (
    <Semantic.Section display="grid" height="100vh" placeItems="center">
      <RegistrationCardDiv>
        <Branding />
        <Typography.Title level="h2">Verify your email.</Typography.Title>
        <Typography.Paragraph level="p1">
          An email with the verification code has been sent to
          <strong> {stateObj.email} </strong>
        </Typography.Paragraph>
        <Typography.Paragraph level="p1">
          Enter the code below and click Verify to continue.
        </Typography.Paragraph>

        <VerifyEmailFormStyles onSubmit={postEmailVerify}>
          <CodeInput
            name="code"
            type="text"
            value={stateObj.codeValue}
            pattern="^\d+$"
            maxlength="6"
            minlength="6"
            onChange={codeHandlers.handleChange}
            onBlur={codeHandlers.handleBlur}
            validate={stateObj.codeError}
            beforeText={stateObj.validationMsg}
            afterText=""
            title="Please enter a valid value"
            onInvalid={codeHandlers.invalidMsg}
            required={true}
            autocomplete="off"
          />
          <Semantic.Div marginTop="30px">
            <Button block type="submit">
              Verify
            </Button>
          </Semantic.Div>
          <ContentAlignment align="center">
            <Button
              ghostLink
              onClick={(e) => {
                prevStep();
              }}
            >
              Back to register
            </Button>
          </ContentAlignment>
        </VerifyEmailFormStyles>
      </RegistrationCardDiv>
    </Semantic.Section>
  );
}

import React from "react";

export default function VimeoEmbed({ videoId }) {
  return (
    <iframe
      src={`https://player.vimeo.com/video/${videoId}?title=0&byline=0&portrait=0`}
      width="640"
      height="360"
      frameBorder="0"
      allow="autoplay; fullscreen; picture-in-picture"
      title="Vimeo player"
      allowFullScreen
    ></iframe>
  );
}

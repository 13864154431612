import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import { Semantic } from "components/SemanticComponents";
import { theme } from "theme";

const StyledInput = styled.input`
  background: ${(props) =>
    props.disabled === true
      ? props.theme.colors.formDisabled
      : props.theme.colors.primaryBackground};
  border-radius: ${(props) => props.theme.borderRadius.sm};
  border: ${(props) => props.theme.border.formBorder};
  display: inline-block;
  height: 40px;
  padding: 1rem;
  width: 100%;
  z-index: 10;

  &:valid {
    border: ${(props) =>
      props.validate === false && props.value !== ""
        ? `solid ${props.theme.colors.heroGreen} 1px`
        : null};
  }

  &:focus-within {
    outline: none;
    //=> you would think that the false statement of invalid would be the same as valid, but you'd be wrong
    &:invalid {
      border: ${(props) =>
        props.validate === false && props.value !== ""
          ? null
          : `solid ${props.theme.colors.errorRed} 3px`};
    }
    &:valid {
      border: ${(props) =>
        props.validate === false && props.value !== ""
          ? `solid ${props.theme.colors.heroGreen} 3px`
          : `solid ${props.theme.colors.errorRed} 3px`};
    }
  }
`;

const StyledLabel = styled.label`
  margin-top: ${(props) => props.theme.spacing.md};
  color: ${(props) => props.theme.colors.heroBlue};
  display: inline-block;
  font-size: ${(props) => props.theme.fontSize.md};
  top: 18px;
  width: 100%;
  z-index: 1;
`;

export default function DateInput({
  name,
  label,
  onChange,
  value,
  tabIndex,
  required,
}) {
  return (
    <Semantic.Div width="inherit">
      <StyledLabel value={value} htmlFor={name} required={required}>
        {label}
        {required ? (
          <Semantic.Span color={`${theme.colors.errorRed}`}>
            {" "}
            (required)
          </Semantic.Span>
        ) : (
          ""
        )}
      </StyledLabel>
      <StyledInput
        id={name}
        name={name}
        type="date"
        label={label}
        onChange={onChange}
        value={value}
        tabIndex={tabIndex}
        required={required}
      />
    </Semantic.Div>
  );
}

DateInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  value: PropTypes.string,
  tabIndex: PropTypes.string,
};

import { Document, Page, Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  baseAlignment: {
    padding: 50,
  },
  brandingTitleContainer: {
    paddingBottom: 50,
    marginBottom: 20,
    borderBottom: "1px solid black",
  },
  brandingTitleText: {
    fontSize: 26,
  },
  brandingSubTitle: {
    fontSize: 20,
  },
  mainTitle: {
    fontSize: 16,
    marginBottom: 15,
  },
  mainText: {
    fontSize: 10,
    paddingLeft: 15,
    marginBottom: 50,
  },
  footer: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
  },
  footerText: {
    fontSize: 16,
    paddingLeft: 30,
  },
  footerSubText: {
    fontSize: 8,
    paddingLeft: 30,
    paddingBottom: 20,
  },
});

export default function PDFPlain({ data }) {
  return (
    <Document>
      <Page size="A4" style={styles.baseAlignment}>
        <View style={styles.brandingTitleContainer}>
          <Text style={styles.brandingTitleText}>Grace Papers</Text>
          <Text style={styles.brandingSubTitle}>Professional Vision Board</Text>
        </View>
        <View>
          {data.data.slice(0, 9).map((vision, index) => {
            let heading;
            try {
              heading = JSON.parse(
                vision["V" + String(index + 1)]?.question?.data
              );
            } catch (error) {
              console.error("Error parsing JSON:", error);
              // Handle the error gracefully, e.g., by providing a default value
              heading = { title: "Error parsing JSON" };
            }
            return (
              <View style={styles.baseAlignment} wrap={false} key={index}>
                <Text style={styles.title}>{heading.title}</Text>
                <Text style={styles.mainText}>
                  {vision["V" + String(index + 1)]?.data?.text}
                </Text>
              </View>
            );
          })}
        </View>
        <View style={styles.footer} fixed>
          <Text style={styles.footerText}>gracepapers.com.au</Text>
          <Text style={styles.footerSubText}>
            Grace Papers makes it easier to find the right resources and access
            to on demand coaching to support your parenting, care and career
            journey.
          </Text>
        </View>
      </Page>
    </Document>
  );
}

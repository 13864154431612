import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
// This component is for reusable Semantic HTML components, the goal is to try standardise our semantic usage.
// If you add a semantic component, please link a brief explainer to what it should be used and what for, and the MDN document, pass it the default props, and dont hard code styles here so it can be used throughout the app (pass individual styles as props).
// The exception here being <main> as that only has one instance in the app
// --------------------------

const StyledImage = styled.img`
  ${(props) => (props.width ? `width: ${props.width}` : null)};
  ${(props) => (props.margin ? `margin: ${props.margin}` : null)};
  ${(props) => (props.objectFit ? `object-fit: ${props.objectFit}` : null)};
`;

const StyledHR = styled.hr`
  border: 1px solid ${(props) => props.theme.colors.lightGrey};
  width: 100%;
`;

const StyledSpan = styled.span`
  ${(props) => (props.fontWeight ? `font-weight: ${props.fontWeight}` : null)};
  ${(props) => (props.color ? `color: ${props.color}` : null)};
  ${(props) => (props.width ? `width: ${props.width}` : null)};
  ${(props) => (props.display ? `display: ${props.display}` : null)};
  ${(props) => (props.whiteSpace ? `white-space: ${props.whiteSpace}` : null)};
`;

const StyledFieldset = styled.fieldset`
  ${(props) => (props.border ? `border: ${props.border}` : null)};
  ${(props) => (props.display ? `display: ${props.display}` : null)};
  ${(props) => (props.background ? `background: ${props.background}` : null)};
  ${(props) =>
    props.flexDirection ? `flex-direction: ${props.flexDirection}` : null};
  ${(props) => (props.padding ? `padding: ${props.padding}` : null)};
  ${(props) =>
    props.justifyContent ? `justify-content: ${props.justifyContent}` : null};
  ${(props) => (props.alignItems ? `align-items: ${props.alignItems}` : null)};
  ${(props) => (props.width ? `width: ${props.width}` : null)};
`;

const StyledLabel = styled.label``;

const StyledInput = styled.input``;

const StyledForm = styled.form`
  ${(props) =>
    props.autoComplete ? `autoComplete: ${props.autoComplete}` : null};
  ${(props) => (props.display ? `display: ${props.display}` : null)};
  ${(props) => (props.flexWrap ? `flex-wrap: ${props.flexWrap}` : null)};
  ${(props) => (props.width ? `width: ${props.width}` : null)};
  ${(props) => (props.paddingTop ? `padding-top: ${props.paddingTop}` : null)};
  ${(props) => (props.alignSelf ? `align-self: ${props.alignSelf}` : null)};
  ${(props) =>
    props.justifyContent ? `justify-content: ${props.justifyContent}` : null};
  ${(props) => (props.height ? `height: ${props.height}` : null)};
  ${(props) =>
    props.flexDirection ? `flex-direction: ${props.flexDirection}` : null};
  ${(props) => (props.border ? `border: ${props.border}` : null)};
`;

const StyledDiv = styled.div`
  ${(props) => (props.position ? `position: ${props.position}` : null)};
  ${(props) => (props.display ? `display: ${props.display}` : null)};
  ${(props) =>
    props.flexDirection ? `flex-direction: ${props.flexDirection}` : null};
  ${(props) => (props.background ? `background: ${props.background}` : null)};
  ${(props) => (props.margin ? `margin: ${props.margin}` : null)};
  ${(props) => (props.marginTop ? `margin-top: ${props.marginTop}` : null)};
  ${(props) => (props.width ? `width: ${props.width}` : null)};
  ${(props) => (props.maxWidth ? `max-width: ${props.maxWidth}` : null)};
  ${(props) => (props.height ? `height: ${props.height}` : null)};
  ${(props) => (props.padding ? `padding: ${props.padding}` : null)};
  ${(props) =>
    props.paddingLeft ? `padding-left: ${props.paddingLeft}` : null};
  ${(props) =>
    props.paddingRight ? `padding-right: ${props.paddingRight}` : null};
  ${(props) =>
    props.borderRadius ? `border-radius: ${props.borderRadius}` : null};
  ${(props) => (props.borderTop ? `border-top: ${props.borderTop}` : null)};
  ${(props) => (props.borderLeft ? `border-left: ${props.borderLeft}` : null)};
  ${(props) => (props.boxShadow ? `box-shadow: ${props.boxShadow}` : null)};
  ${(props) => (props.placeItems ? `place-items: ${props.placeItems}` : null)};
  ${(props) =>
    props.justifyContent ? `justify-content: ${props.justifyContent}` : null};
  ${(props) => (props.alignItems ? `align-items: ${props.alignItems}` : null)};
  ${(props) => (props.flexWrap ? `flex-wrap: ${props.flexWrap}` : null)};
  ${(props) => (props.flexGrow ? `flex-grow: ${props.flexGrow}` : null)};
  ${(props) => (props.textAlign ? `text-align: ${props.textAlign}` : null)};
  ${(props) => (props.paddingTop ? `padding-top: ${props.paddingTop}` : null)};
`;

const StyledHeader = styled.header`
  ${(props) => (props.width ? `width: ${props.width}` : null)};
  ${(props) => (props.maxWidth ? `max-width: ${props.maxWidth}` : null)};
  ${(props) => (props.margin ? `margin: ${props.margin}` : null)};
  ${(props) => (props.padding ? `padding: ${props.padding}` : null)};
  //these props are for the article headers
  ${(props) => {
    switch (props.data) {
      case 1:
        return {
          background: `${props.theme.colors.altBlue}`,
        };
      case 2:
        return {
          background: `${props.theme.colors.altPink}`,
        };
      case 3:
        return {
          background: `${props.theme.colors.altGreen}`,
        };
      case 4:
        return {
          background: `${props.theme.colors.altPurple}`,
        };
      default:
        return {
          background: `${props.theme.colors.altBlue}`,
        };
    }
  }}
`;

const StyledArticle = styled.article`
  ${(props) => (props.display ? `display: ${props.display}` : null)};
  ${(props) =>
    props.flexDirection ? `flex-direction: ${props.flexDirection}` : null};
  ${(props) => (props.background ? `background: ${props.background}` : null)};
  ${(props) =>
    props.borderRadius ? `border-radius: ${props.borderRadius}` : null};
  ${(props) => (props.margin ? `margin: ${props.margin}` : null)};
  ${(props) => (props.width ? `width: ${props.width}` : null)};
  ${(props) => (props.maxWidth ? `max-width: ${props.maxWidth}` : null)};
  ${(props) => (props.minWidth ? `min-width: ${props.minWidth}` : null)};
  ${(props) => (props.height ? `height: ${props.height}` : null)};
  ${(props) => (props.padding ? `padding: ${props.padding}` : null)};
  ${(props) => (props.boxShadow ? `box-shadow: ${props.boxShadow}` : null)};
`;

const StyledSection = styled.section`
  ${(props) => (props.display ? `display: ${props.display}` : null)};
  ${(props) =>
    props.flexDirection ? `flex-direction: ${props.flexDirection}` : null};
  ${(props) => (props.background ? `background: ${props.background}` : null)};
  ${(props) => (props.margin ? `margin: ${props.margin}` : null)};
  ${(props) => (props.width ? `width: ${props.width}` : null)};
  ${(props) => (props.maxWidth ? `max-width: ${props.maxWidth}` : null)};
  ${(props) => (props.height ? `height: ${props.height}` : null)};
  ${(props) => (props.padding ? `padding: ${props.padding}` : null)};
  ${(props) =>
    props.borderRadius ? `border-radius: ${props.borderRadius}` : null};
  ${(props) => (props.borderTop ? `border-top: ${props.borderTop}` : null)};
  ${(props) => (props.boxShadow ? `box-shadow: ${props.boxShadow}` : null)};
  ${(props) => (props.placeItems ? `place-items: ${props.placeItems}` : null)};
  ${(props) =>
    props.justifyContent ? `justify-content: ${props.justifyContent}` : null};
  ${(props) => (props.flexWrap ? `flex-wrap: ${props.flexWrap}` : null)};
  ${(props) => (props.flex ? `flex: ${props.flex}` : null)};
`;

const StyledMain = styled.main`
  ${(props) => (props.height ? `height: ${props.height}` : null)};
  ${(props) => (props.position ? `position: ${props.position}` : null)};
  ${(props) => (props.minHeight ? `min-height: ${props.minHeight}` : null)};
`;

const Image = ({ children, ...props }) => {
  return <StyledImage {...props}>{children}</StyledImage>;
};

// This is currently used as and what was previously called Divider, this can be refactored into standard HR if we need ir for whatever reason, but the existing Dividers will need the two props passed, and the styles refactored
// Technically this should be used as a break between Paragraph level elements (might be a good idea to restyle the Divider into a visual element instead)
// https://developer.mozilla.org/en-US/docs/Web/HTML/Element/hr
const HR = ({ ...props }) => {
  return <StyledHR {...props} />;
};

// the SPAN is very much like a Div, as in it doesn't inherently represent anything, and is often used in styling properties, the major difference is a div is a block element, and a span is an inline element
// https://developer.mozilla.org/en-US/docs/Web/HTML/Element/span
const Span = ({ children, ...props }) => {
  return <StyledSpan {...props}>{children}</StyledSpan>;
};

// A fieldset is used to wrap several inputs together, suck as the INPUT/LABEL components
// https://developer.mozilla.org/en-US/docs/Web/HTML/Element/fieldset
const Fieldset = ({ children, ...props }) => {
  return <StyledFieldset {...props}>{children}</StyledFieldset>;
};

// the Label represents the texts associated with an INPUT
// https://developer.mozilla.org/en-US/docs/Web/HTML/Element/label
const Label = ({ children, ...props }) => {
  return <StyledLabel {...props}>{children}</StyledLabel>;
};

const Input = ({ children, ...props }) => {
  return <StyledInput {...props} />;
};

// The document that wraps all form inputs
// https://developer.mozilla.org/en-US/docs/Web/HTML/Element/form
const Form = ({ children, ...props }) => {
  return <StyledForm {...props}>{children}</StyledForm>;
};

// I am the little black dress of Semantic HTML, you wear me a lot, but if you'd have looked further into your closet, you'd find a much more appropriate dress to wear for the event.
// https://developer.mozilla.org/en-US/docs/Web/HTML/Element/div
const Div = ({ children, ...props }) => {
  return <StyledDiv {...props}>{children}</StyledDiv>;
};

// The element represents introductory content, typically a group of introductory or navigational aids. It may contain some heading elements but also a logo, a search form, an author name, and other elements.
// https://developer.mozilla.org/en-US/docs/Web/HTML/Element/header
const Header = ({ children, ...props }) => {
  return <StyledHeader {...props}>{children}</StyledHeader>;
};

// element represents a self-contained composition in a document, page, application, or site, which is intended to be independently distributable or reusable (e.g., in syndication). Examples include: a forum post, a magazine or newspaper article, or a blog entry, a product card, a user-submitted comment, an interactive widget or gadget, or any other independent item of content.
// https://developer.mozilla.org/en-US/docs/Web/HTML/Element/article
const Article = ({ children, ...props }) => {
  return <StyledArticle {...props}>{children}</StyledArticle>;
};

// A Section should be used to wrap a main area within a page.
// for example a Widget would be a Section, a main page can have many sections, but a section usually wouldnt contain children sections, unless it was a large widget that had a self contained widget within itself
// a section should always have a heading, as it is used when there is a lack of a better semantic option
// https://developer.mozilla.org/en-US/docs/Web/HTML/Element/section
const Section = ({ children, ...props }) => {
  return <StyledSection {...props}>{children}</StyledSection>;
};

// Main should only be used once, so dont add this to any other pages/components
// https://developer.mozilla.org/en-US/docs/Web/HTML/Element/main
const Main = ({ children, ...props }) => {
  return <StyledMain {...props}>{children}</StyledMain>;
};

const Semantic = {
  Main,
  Section,
  Article,
  Header,
  Div,
  Form,
  Label,
  Input,
  Fieldset,
  Span,
  HR,
  Image,
};

Semantic.propTypes = {
  children: PropTypes.string.isRequired,
};

export default Semantic;

import React from "react";
import confetti from "canvas-confetti";
import { Typography, Button } from "components/SemanticComponents";

//! This component isn't used in the application, it is used to have a demonstration in StoryBook

function Confetti() {
  function randomInRange(min, max) {
    return Math.random() * (max - min) + min;
  }

  const handleConfetti = () => {
    confetti({
      scalar: 2,
      spread: randomInRange(150, 250),
      particleCount: randomInRange(50, 100),
      origin: { y: -0.1 },
      startVelocity: -35,
    });
  };

  const handleFireworks = () => {
    const duration = 10 * 1000;
    const animationEnd = Date.now() + duration;
    const defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 0 };
    const interval = setInterval(function () {
      const timeLeft = animationEnd - Date.now();

      if (timeLeft <= 0) {
        return clearInterval(interval);
      }

      const particleCount = 50 * (timeLeft / duration);
      // since particles fall down, start a bit higher than random
      confetti({
        ...defaults,
        particleCount,
        origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 },
      });
      confetti({
        ...defaults,
        particleCount,
        origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 },
      });
    }, 250);
  };

  const handleBoth = () => {
    const duration = 5 * 1000;
    const animationEnd = Date.now() + duration;
    const timeLeft = animationEnd - Date.now();
    const defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 0 };
    confetti({
      scalar: 2,
      spread: randomInRange(150, 250),
      particleCount: randomInRange(50, 100),
      origin: { y: -0.1 },
      startVelocity: -35,
    });

    const interval = setInterval(function () {
      if (timeLeft <= 0) {
        return clearInterval(interval);
      }

      const particleCount = 50 * (timeLeft / duration);
      // since particles fall down, start a bit higher than random
      confetti({
        ...defaults,
        particleCount,
        origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 },
      });
      confetti({
        ...defaults,
        particleCount,
        origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 },
      });
    }, 250);
  };

  return (
    <div>
      <Typography.Title level="h1">
        Welcome to the Confetti Party!
      </Typography.Title>
      <Button alternate onClick={handleConfetti}>
        Throw Confetti
      </Button>
      <Button alternate onClick={handleFireworks}>
        Kaboom Fireworks
      </Button>
      <Button alternate onClick={handleBoth}>
        Both
      </Button>
    </div>
  );
}

export default Confetti;

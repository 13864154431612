import React from "react";
import { AssignedLessons, Loader, ResourceCard } from "components";
import { useAPI } from "hooks/api";
import { useToast } from "context/toast";

export default function AssignedLessonsComponent({ editCard }) {
  const { post, statefulGet } = useAPI();
  const { showToast } = useToast();
  const [consolidatedData, setConsolidatedData] = React.useState(null);

  //=> for triggering an update when a member clicks to remove/add a lesson
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  const { status: allDataStatus, data: allData } =
    statefulGet("/v2-lessons/all");
  const { status: myCardDataStatus, data: myCardData } =
    statefulGet("/v2-lessons/sorted");

  React.useEffect(() => {
    const setActiveFlags = (all, personal) => {
      const activeLessons = personal.map((card) => card.id);
      const allActive = all.map((card) => {
        const personalIndex = activeLessons.indexOf(card.id);
        return activeLessons.includes(card.id)
          ? {
              ...card,
              active: 1,
              pivot: personal[personalIndex].pivot,
            }
          : { ...card, active: 0, pivot: null };
      });
      return allActive;
    };

    if (
      myCardDataStatus === "fetched" &&
      allDataStatus === "fetched" &&
      !consolidatedData
    ) {
      try {
        let activeCards = setActiveFlags(allData, Object.values(myCardData));
        setConsolidatedData(activeCards);
      } catch (error) {
        console.log("update error: ", error);
      }
    }
  }, [allData, allDataStatus, myCardData, myCardDataStatus, consolidatedData]);

  const select = (props) => (e) => {
    e.preventDefault();

    consolidatedData.map((item, i) => {
      let obj = {};
      if (item.id === props) {
        item.active = item.active === 1 ? 0 : 1;

        post("/v2-lessons/update", item).then((r) => {
          if (r.result.message === true) {
            //=> this forces an API call on the myCards tab, essentially loading in the background and the user wont see this unless they change tab immediately
            forceUpdate();
          } else {
            console.log("Oops something went wrong");
            showToast({
              type: "warning",
              title: "unsuccessful",
              message:
                "Oops something went wrong, and your cards have not been updated.",
              timer: 4000,
            });
          }
        });
      }
      return obj;
    });
  };

  return (
    <React.Fragment>
      {consolidatedData ? (
        <ResourceCard>
          <AssignedLessons
            data={consolidatedData}
            select={select}
            editCard={editCard}
          />
        </ResourceCard>
      ) : (
        <Loader />
      )}
    </React.Fragment>
  );
}

import React from "react";
import styled from "styled-components/macro";

const BrandingStyles = styled.span`
  h1 {
    font-family: ${(props) => props.theme.fontFamily.main};
    margin: 0;
    margin-bottom: ${(props) => props.theme.spacing.lg};
  }
  span {
    color: ${(props) => props.theme.colors.heroBlue};
  }
  font-size: x-large;
`;

export default function Branding() {
  return (
    <BrandingStyles>
      <h1>
        <span>Grace</span> Papers
      </h1>
    </BrandingStyles>
  );
}

import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { ThemeProvider } from "styled-components";
import GlobalStyles from "./theme/GlobalStyles";
import theme from "./theme/theme";
import { CookiesProvider } from "react-cookie";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_URL,
  integrations: [new BrowserTracing()],
  tracesSampleRate: parseFloat(process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE),
  release: process.env.REACT_APP_VERSION,
  environment: process.env.REACT_APP_ENVIRONMENT,
  allowUrls: [
    /https?:\/\/((platform|beta)\.)?gracepapers.com.au/,
    "http://localhost:3000",
  ],
});

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <GlobalStyles />
    <CookiesProvider>
      <App />
    </CookiesProvider>
  </ThemeProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

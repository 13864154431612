import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";

const SelectInputCheckerStyles = styled.button`
  display: block;
  border: none;
  background: transparent;
  text-decoration: underline;
  color: ${(props) => props.theme.colors.heroBlue};
  margin-left: auto;
  cursor: pointer;
  padding-top: 1%;
`;

const SelectInputMessage = styled.span`
  p {
    margin: 0 0 0 2%;
    padding-bottom: 1%;
  }
`;

export default function HelperText({
  handleSelectInputChecker,
  selectInputChecker,
  helperType,
  helperQuestion,
}) {
  return (
    <React.Fragment>
      <SelectInputCheckerStyles onClick={handleSelectInputChecker}>
        {selectInputChecker ? "Hide the helper" : helperQuestion}
      </SelectInputCheckerStyles>
      {selectInputChecker ? (
        <SelectInputMessage aria-live="polite">
          {helperType.map((item, index) => {
            return <p key={index}>{item}</p>;
          })}
        </SelectInputMessage>
      ) : null}
    </React.Fragment>
  );
}

HelperText.propTypes = {
  onClick: PropTypes.func,
};

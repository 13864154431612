import React from "react";
import styled from "styled-components/macro";
import { Typography, Semantic } from "components/SemanticComponents";

const InnerHTML = styled.div`
  h3,
  h4,
  p {
    margin: ${(props) => `0 0 ${props.theme.spacing.md} 0`};
  }
  a {
    color: ${(props) => props.theme.colors.heroBlue};
    text-decoration: underline;
    font-style: italic;
    text-decoration-color: ${(props) => props.theme.colors.heroBlue};
  }
`;

const DownloadLink = styled.a`
  color: ${(props) => props.theme.colors.primaryBackground};
  text-decoration: none;
  background-color: ${(props) => {
    if (props.isColes) {
      return props.theme.colors.colesHeroRed;
    } else {
      return props.theme.colors.heroBlue;
    }
  }};
  border-radius: 5px;
  padding: ${(props) => `${props.theme.spacing.xxs} ${props.theme.spacing.sm}`};
`;

const TitleButtonAlignment = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  h4,
  p {
    margin: 0;
  }

  @media (max-width: ${(props) => props.theme.breakPoint.mobile}) {
    flex-direction: column;
    align-items: start;
  }
`;

const PolicyWrapper = styled.div`
  width: 100%;

  hr {
    width: 75%;
    border: 0.5px solid ${(props) => props.theme.colors.lightGrey};
    margin-bottom: ${(props) => props.theme.spacing.sm};
  }
`;

export default function PoliciesComponent({ policies }) {
  // coles ID = 2123
  // looking for the coles ID in production and giving them a red button
  return (
    <PolicyWrapper>
      <Typography.Title level="h3">Company Policies</Typography.Title>
      {policies.map((policy, index) => {
        return (
          <React.Fragment key={index}>
            <TitleButtonAlignment>
              <Typography.Title level="h4">{policy.name}</Typography.Title>
              {policy.files.length > 0
                ? policy.files.map((file, index) => {
                    return (
                      <DownloadLink
                        key={index}
                        href={file.url}
                        target="_blank"
                        rel="noreferrer"
                        isColes={file.company_id === 2123 ? true : false}
                      >
                        Download File
                      </DownloadLink>
                    );
                  })
                : null}
            </TitleButtonAlignment>
            <InnerHTML
              dangerouslySetInnerHTML={{ __html: policy.description }}
            />
            <Semantic.HR />
          </React.Fragment>
        );
      })}
    </PolicyWrapper>
  );
}

export const bookmarkedPage = (data) => {
  let answer = data.first_step_slug;

  if (data.pivot.lesson_step_id !== null) {
    data.steps.forEach((step) => {
      if (step.id === data.pivot.lesson_step_id) {
        return (answer = step.slug);
      }
    });
  }

  return answer;
};

export const exploreBookmarkedPage = (data) => {
  let answer = data.first_step_slug;

  //=> this needs to break out first, this finds lessons a member has not enrolled in and defaults to the first slug (if a member doesn't enroll, the bookmark feature is not possible as the user progress table is not created till enrollment)
  if (data.pivot === null) {
    return (answer = data.first_step_slug);
  }

  if (data.pivot.lesson_step_id !== null) {
    data.steps.forEach((step) => {
      if (step.id === data.pivot.lesson_step_id) {
        return (answer = step.slug);
      }
    });
  }

  return answer;
};

import styled from "styled-components/macro";
import PropTypes from "prop-types";

const ContainerUI = styled.ul`
  display: grid;
  gap: ${(props) => props.theme.spacing.lg};
  grid-template-columns: repeat(auto-fit, minmax(10em, 16em));
  justify-content: start;
  padding: 0;
  justify-content: space-around;

  //=> for animation fade in and out
  transition: opacity 0.3s;
  opacity: ${(props) => {
    if (props.animateOut) {
      return 0;
    }
    if (props.animateIn) {
      return 1;
    }
  }};
`;

export default function ResourceCard({ children, ...props }) {
  return <ContainerUI {...props}>{children}</ContainerUI>;
}

ResourceCard.propTypes = {
  children: PropTypes.any.isRequired,
};

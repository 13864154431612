import React from "react";
import styled from "styled-components/macro";
import { useToast } from "context/toast";
import { Loader } from "components";
import { Semantic, Typography } from "components/SemanticComponents";

const StyledCheck = styled.div`
  input[type="checkbox"] {
    opacity: 0;
  }
  label::before {
    height: 16px;
    width: 16px;
    border: 1px solid #000;
    left: 0px;
    top: 3px;
  }
  label::after {
    height: 5px;
    width: 9px;
    border-left: 2px solid;
    border-bottom: 2px solid;
    transform: rotate(-45deg);
    left: 4px;
    top: 7px;
  }

  label {
    position: relative;
    display: inline-block;
    padding-left: 30px;
  }

  label::before,
  label::after {
    position: absolute;
    content: " ";
    display: inline-block;
  }

  label::before {
    top: 3px;
  }
  label::after {
    left: 4px;
    top: 7px;
  }

  input[type="checkbox"] + label::after {
    content: none;
  }

  input[type="checkbox"]:checked + label::after {
    content: "";
  }
  input[type="checkbox"]:focus + label::before {
    outline: rgb(59, 153, 252) auto 5px;
  }
`;

export default function MultiAnswerCheckbox({
  item,
  fetchAnswer,
  updateAnswer,
}) {
  const { showToast } = useToast();
  const [values, setValues] = React.useState(null);
  const [loaded, setLoaded] = React.useState(false);
  const { id: questionId } = item;
  const { options, title } = JSON.parse(item.data);

  const initialDefaultAnswer = options.map((option) => ({
    ...option,
    checked: false,
  }));

  const refresh = React.useCallback(() => {
    fetchAnswer(questionId).then((response) => {
      setValues(response?.result?.data?.values ?? initialDefaultAnswer);
      setLoaded(true);
    });
  }, [fetchAnswer, questionId, initialDefaultAnswer]);

  React.useEffect(() => {
    if (!values) refresh();
  }, [questionId, refresh, values]);

  const handleSelectChange = (e) => {
    const newValues = values.slice();
    // This gets the correct value from the composite id on the checkbox
    const checkIdNumber = e.target.id.match(/\d+$/)[0];
    newValues.find((value) => value.id === checkIdNumber).checked =
      e.target.checked;
    setValues(newValues);

    if (values) {
      updateAnswer(questionId, { values: newValues })
        .then((response) => {
          refresh();
        })
        .catch((error) => {
          console.log(error);
          showToast({
            type: "warning",
            title: "unsuccessful",
            message:
              "Oops something went wrong, and your selection has not been updated.",
            timer: 4000,
          });
        });
    }
  };
  return (
    <React.Fragment>
      {loaded ? (
        <React.Fragment>
          <Typography.Title level="h2">{title}</Typography.Title>
          <Semantic.Fieldset
            border="none"
            onChange={(e) => handleSelectChange(e)}
          >
            {values &&
              values.map((value) => {
                return (
                  <StyledCheck key={`item-${item.id}-check-${value.id}`}>
                    <Semantic.Input
                      // This id is a composite of values since there can be
                      // multiple sets of checkboxes on a page
                      id={`item-${item.id}-check-${value.id}`}
                      name={`item-${item.id}-check-${value.id}`}
                      type="checkbox"
                      defaultChecked={value.checked}
                    />
                    <Semantic.Label
                      htmlFor={`item-${item.id}-check-${value.id}`}
                    >
                      {value.value}
                    </Semantic.Label>
                  </StyledCheck>
                );
              })}
          </Semantic.Fieldset>
        </React.Fragment>
      ) : (
        <Loader />
      )}
    </React.Fragment>
  );
}

import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";

const StyledArea = styled.textarea`
  background: ${(props) =>
    props.disabled === true
      ? props.theme.colors.formDisabled
      : props.theme.colors.transparent};
  border-radius: ${(props) => props.theme.borderRadius.sm};
  border: 1px solid #9d9d9d;
  display: inline-block;
  margin-bottom: ${(props) => props.theme.spacing.md};
  padding: 1rem;
  position: relative;
  width: 100%;
`;

const StyledLabel = styled.label`
  color: ${(props) =>
    props.value !== ""
      ? props.theme.colors.heroBlue
      : props.theme.colors.inactive};
  display: inline-block;
  margin-bottom: ${(props) => props.theme.spacing.xs};
  font-size: ${(props) => (props.value !== "" ? "0.8rem" : "1rem")};
  padding-left: ${(props) => (props.value !== "" ? 0 : "1rem")};
  position: relative;
  width: 100%;
`;

export default function TextArea({
  type,
  name,
  label,
  onChange,
  value,
  disabled,
  tabIndex,
  required,
}) {
  return (
    <React.Fragment>
      <StyledLabel value={value} htmlFor={name}>
        {label}
      </StyledLabel>
      <StyledArea
        id={name}
        name={name}
        type={type}
        label={label}
        onChange={onChange}
        value={value}
        disabled={disabled}
        tabIndex={tabIndex}
        required={required}
      />
    </React.Fragment>
  );
}

TextArea.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  tabIndex: PropTypes.string,
};

import React from "react";
import styled from "styled-components/macro";
import { useCookies } from "react-cookie";

const CookieWrapper = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0;
  left: 0;
  width: 100%;
  height: fit-content;
  background: ${(props) => props.theme.colors.heading};
  color: ${(props) => props.theme.colors.primaryBackground};
  p {
    padding-left: ${(props) => props.theme.spacing.sm};
  }
  a {
    color: ${(props) => props.theme.colors.heroBlue};
  }
  button {
    width: fit-content;
    margin: ${(props) => props.theme.spacing.sm};
    padding: ${(props) => props.theme.spacing.xs};
    border-color: ${(props) => props.theme.colors.primaryBackground};
    border-style: solid;
    color: ${(props) => props.theme.colors.primaryBackground};
    background: none;
  }
`;

//=> sets cookie expiration date for two years
const current = new Date();
const expiryDate = new Date();
expiryDate.setFullYear(current.getFullYear() + 2);

export default function UseCookieBanner() {
  //=> cookies is the default cookie object, we will always set cookies under this
  const [cookies, setCookie] = useCookies(["acceptCookies"]);

  const agreeToCookies = (e) => {
    e.preventDefault();
    setCookie("acceptCookies", true, {
      //=> this path approves cookie use across all pages
      path: "/",
      expires: expiryDate,
    });
  };

  return (
    <React.Fragment>
      {cookies.acceptCookies ? null : (
        <CookieWrapper
          role="dialog"
          aria-modal="false"
          aria-describedby="cookie-box-message"
        >
          <p id="cookie-box-message">
            we use cookies to improve the experience, please our visit{" "}
            <i>
              <a href="/privacy-statement" target="_blank" rel="noreferrer">
                privacy statement
              </a>
            </i>{" "}
            if you would like to know more
          </p>
          <button onClick={agreeToCookies}>accept</button>
        </CookieWrapper>
      )}
    </React.Fragment>
  );
}

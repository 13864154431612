import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";

const StyledSelect = styled.select`
  background: ${(props) =>
    props.disabled === true
      ? props.theme.colors.formDisabled
      : props.theme.colors.primaryBackground};
  border-radius: ${(props) => props.theme.borderRadius.sm};
  border: ${(props) => props.theme.border.formBorder};
  display: inline-block;
  height: 40px;
  padding-left: 1rem;
  width: 100%;
  z-index: 10;

  &:valid {
    border: ${(props) =>
      props.validate ? `solid ${props.theme.colors.heroGreen} 1px` : null};
  }

  &:focus-within {
    outline: none;
    &:invalid {
      border: ${(props) =>
        props.validate ? null : `solid ${props.theme.colors.errorRed} 3px`};
    }
    &:valid {
      border: ${(props) =>
        props.validate ? `solid ${props.theme.colors.heroGreen} 3px` : null};
    }
  }
`;

const StyledLabel = styled.label`
  margin-top: ${(props) => props.theme.sizes.smallPadding};
  color: ${(props) => props.theme.colors.heroBlue};
  display: inline-block;
  font-size: ${(props) => props.theme.fontSize.md};
  top: 18px;
  width: 100%;
  z-index: 1;
`;

const ValidationLabel = styled.div`
  color: ${(props) =>
    props.validate ? props.theme.colors.likertDGreen : props.theme.colors.body};
  border-bottom-left-radius: ${(props) => props.theme.border.formInputRadius};
  border-bottom-right-radius: ${(props) => props.theme.border.formInputRadius};
  padding-left: 5px;
`;

const StyledRequired = styled.span`
  color: ${(props) => props.theme.colors.errorRed};
`;

export default function SelectInput({
  label,
  name,
  onChange,
  options,
  placeholder,
  value,
  tabIndex,
  validate,
  afterText,
  beforeText,
  required,
  onBlur,
  onInvalid,
}) {
  return (
    <React.Fragment>
      <StyledLabel value={value} htmlFor={name} required={required}>
        {label} {required ? <StyledRequired> (required)</StyledRequired> : ""}
      </StyledLabel>
      <StyledSelect
        id={name}
        label={label}
        name={name}
        onChange={onChange}
        placeholder={placeholder}
        value={value}
        tabIndex={tabIndex}
        required={required}
        onBlur={onBlur}
        onInvalid={onInvalid}
        valid={validate}
        validate={validate}
      >
        {options.map((opt, index) => {
          return (
            <option key={index} value={opt.value}>
              {opt.label}
            </option>
          );
        })}
      </StyledSelect>
      <ValidationLabel
        onInvalid={onInvalid}
        validate={validate}
        aria-live="polite"
        aria-label={validate ? null : "input field validation completed"}
        aria-expanded={false}
      >
        {validate ? afterText : beforeText}
      </ValidationLabel>
    </React.Fragment>
  );
}

SelectInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
};

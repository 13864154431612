import styled from "styled-components/macro";

const StyledDiv = styled.div`
  width: 100%;
  min-width: 320px;
  max-width: 800px;
  background: ${(props) => props.theme.colors.primaryBackground};
  padding: ${(props) =>
    props.padding === "0" ? `0` : props.theme.spacing.xxl};
  border-radius: ${(props) => props.theme.borderRadius.sm};
  border-top: ${(props) =>
    props.border === "green"
      ? `4px solid ${props.theme.colors.heroGreen}`
      : `4px solid ${props.theme.colors.heroBlue}`};
  box-shadow: ${(props) => props.theme.boxShadow.accountCard};

  h1 {
    margin-top: 0;
    font-size: ${(props) => props.theme.fontSize.xxl};
  }
  h2 {
    margin: ${(props) => `${props.theme.spacing.sm} 0`};
    font-size: ${(props) => props.theme.fontSize.lg};
  }
  p {
    font-size: ${(props) => props.theme.fontSize.sm};
    line-height: ${(props) => props.theme.lineHeight.md};
    margin: ${(props) => `0 0 ${props.theme.spacing.sm} 0`};
  }
`;

export default function RegistrationCardDiv({ children, ...props }) {
  return <StyledDiv {...props}>{children}</StyledDiv>;
}

import React from "react";
import styled from "styled-components/macro";

const Button = styled.button`
  border: none;
  width: 120px;
  height: 40px;
  color: ${(props) => props.theme.colors.heading};

  cursor: pointer;
  transition: 600ms cubic-bezier(0.3, 0.7, 0.4, 1);

  &:hover,
  &:focus-visible {
    box-shadow: inset 0 -100px 0 0 ${(props) => props.theme.colors.gradientMedGrey};
  }

  &:active {
    filter: brightness(110%);
  }
`;

export default function PaginationButtons({
  goToPreviousPage,
  goToNextPage,
  currentPage,
  pages,
}) {
  return (
    <div>
      {currentPage === 1 ? null : (
        <Button toggle={true} onClick={goToPreviousPage}>
          previous page
        </Button>
      )}

      {currentPage === pages || currentPage > pages ? null : (
        <Button toggle={true} onClick={goToNextPage}>
          next page
        </Button>
      )}
    </div>
  );
}
